.pf-v5-c-input-group {
  --pf-v5-c-input-group--child--ZIndex: var(--pf-v5-global--ZIndex--xs);
  --pf-v5-c-input-group__item--offset: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-input-group__item--MarginLeft: calc(var(--pf-v5-c-input-group__item--offset) * -1);
  --pf-v5-c-input-group__item--BorderWidth--base: var(--pf-v5-c-input-group__item--offset);
  --pf-v5-c-input-group__item--BorderColor--base: var(--pf-v5-global--BorderColor--300);
  --pf-v5-c-input-group__item--BorderColor--accent: var(--pf-v5-global--BorderColor--200);
  --pf-v5-c-input-group__item--BackgroundColor: transparent;
  --pf-v5-c-input-group__item--m-box--PaddingRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-input-group__item--m-box--PaddingLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-input-group__item--m-box--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-input-group__item--m-box--BorderWidth: var(--pf-v5-c-input-group__item--offset);
  --pf-v5-c-input-group__item--m-box--BorderTopColor: var(--pf-v5-c-input-group__item--BorderColor--base);
  --pf-v5-c-input-group__item--m-box--BorderRightColor: var(--pf-v5-c-input-group__item--BorderColor--base);
  --pf-v5-c-input-group__item--m-box--BorderBottomColor: var(--pf-v5-c-input-group__item--BorderColor--accent);
  --pf-v5-c-input-group__item--m-box--BorderLeftColor: var(--pf-v5-c-input-group__item--BorderColor--base);
  --pf-v5-c-input-group__item--m-plain--BackgroundColor: transparent;
  --pf-v5-c-input-group__text--FontSize: var(--pf-v5-global--FontSize--md);
  --pf-v5-c-input-group__text--Color: var(--pf-v5-global--Color--dark-200);
  --pf-v5-c-input-group__item--m-disabled__text--Color: var(--pf-v5-global--disabled-color--100);
  --pf-v5-c-input-group__item--m-disabled--BorderBottomColor: transparent;
  --pf-v5-c-input-group__item--m-disabled--BackgroundColor: var(--pf-v5-global--disabled-color--300);
  display: flex;
  width: 100%;
}

.pf-v5-c-input-group__item {
  position: relative;
  display: flex;
  min-width: var(--pf-v5-c-input-group__item--MinWidth, revert);
  max-width: var(--pf-v5-c-input-group__item--MaxWidth, revert);
  background-color: var(--pf-v5-c-input-group__item--BackgroundColor);
  border-block-start-color: var(--pf-v5-c-input-group__item--m-box--BorderTopColor);
  border-block-end-color: var(--pf-v5-c-input-group__item--m-box--BorderBottomColor);
  border-inline-start-color: var(--pf-v5-c-input-group__item--m-box--BorderLeftColor);
  border-inline-end-color: var(--pf-v5-c-input-group__item--m-box--BorderRightColor);
}
.pf-v5-c-input-group__item:where(:not(:first-child)) {
  margin-inline-start: var(--pf-v5-c-input-group__item--MarginLeft);
}
.pf-v5-c-input-group__item.pf-m-box {
  --pf-v5-c-input-group__item--BackgroundColor: var(--pf-v5-c-input-group__item--m-box--BackgroundColor);
  padding-inline-start: var(--pf-v5-c-input-group__item--m-box--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-input-group__item--m-box--PaddingRight);
  border: var(--pf-v5-c-input-group__item--m-box--BorderWidth) solid;
  border-block-start-color: var(--pf-v5-c-input-group__item--m-box--BorderTopColor);
  border-block-end-color: var(--pf-v5-c-input-group__item--m-box--BorderBottomColor);
  border-inline-start-color: var(--pf-v5-c-input-group__item--m-box--BorderLeftColor);
  border-inline-end-color: var(--pf-v5-c-input-group__item--m-box--BorderRightColor);
}
.pf-v5-c-input-group__item.pf-m-plain {
  --pf-v5-c-input-group__item--MarginLeft: 0;
  --pf-v5-c-input-group__item--BackgroundColor: var(--pf-v5-c-input-group__item--m-plain--BackgroundColor);
  border: none;
}
.pf-v5-c-input-group__item.pf-m-disabled {
  --pf-v5-c-input-group__item--BackgroundColor: var(--pf-v5-c-input-group__item--m-disabled--BackgroundColor);
  --pf-v5-c-input-group__item--m-box--BorderBottomColor: var(--pf-v5-c-input-group__item--m-disabled--BorderBottomColor);
  --pf-v5-c-input-group__text--Color: var(--pf-v5-c-input-group__item--m-disabled__text--Color);
}
.pf-v5-c-input-group__item.pf-m-fill {
  flex-grow: 1;
}

.pf-v5-c-input-group__text {
  align-self: center;
  font-size: var(--pf-v5-c-input-group__text--FontSize);
  color: var(--pf-v5-c-input-group__text--Color);
}
label.pf-v5-c-input-group__text {
  cursor: pointer;
}

:where(.pf-v5-theme-dark) .pf-v5-c-input-group {
  --pf-v5-c-input-group__item--BorderLeftWidth: var(--pf-v5-c-input-group__item--BorderWidth--base);
  --pf-v5-c-input-group__item--BorderLeftColor: var(--pf-v5-global--palette--black-700);
  --pf-v5-c-input-group__item--m-box--BackgroundColor: var(--pf-v5-global--BackgroundColor--400);
  --pf-v5-c-input-group__item--m-box--BorderTopColor: transparent;
  --pf-v5-c-input-group__item--m-box--BorderRightColor: transparent;
  --pf-v5-c-input-group__item--m-box--BorderBottomColor: var(--pf-v5-global--BorderColor--400);
  --pf-v5-c-input-group__item--m-box--BorderLeftColor: transparent;
  --pf-v5-c-input-group__item--m-disabled--BackgroundColor: var(--pf-v5-global--disabled-color--200);
  --pf-v5-c-input-group__item--m-disabled--BorderBottomColor: transparent;
  --pf-v5-c-input-group__item--m-disabled__text--Color: var(--pf-v5-global--disabled-color--300);
}
:where(.pf-v5-theme-dark) .pf-v5-c-input-group > * + * {
  --pf-v5-c-input-group__item--m-box--BorderLeftColor: var(--pf-v5-c-input-group__item--BorderLeftColor);
  border-inline-start: var(--pf-v5-c-input-group__item--BorderLeftWidth) solid var(--pf-v5-c-input-group__item--BorderLeftColor);
}