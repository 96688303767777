.pf-v5-c-description-list {
  --pf-v5-c-description-list--RowGap: var(--pf-v5-global--gutter--md);
  --pf-v5-c-description-list--ColumnGap: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-description-list--GridTemplateColumns--count: 1;
  --pf-v5-c-description-list--GridTemplateColumns--width: 1fr;
  --pf-v5-c-description-list--GridTemplateColumns--min: 0;
  --pf-v5-c-description-list--GridTemplateColumns: repeat(var(--pf-v5-c-description-list--GridTemplateColumns--count), var(--pf-v5-c-description-list--GridTemplateColumns--width));
  --pf-v5-c-description-list__group--RowGap: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-description-list__group--ColumnGap: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-description-list__group--GridTemplateColumns: auto;
  --pf-v5-c-description-list__group--GridTemplateRows: auto 1fr;
  --pf-v5-c-description-list__group--GridColumn: auto;
  --pf-v5-c-description-list--m-compact--RowGap: var(--pf-v5-global--gutter);
  --pf-v5-c-description-list--m-compact--ColumnGap: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-description-list__term--Display: inline;
  --pf-v5-c-description-list__term--sm--Display: flex;
  --pf-v5-c-description-list__term--FontWeight: var(--pf-v5-global--FontWeight--bold);
  --pf-v5-c-description-list__term--FontSize: var(--pf-v5-global--FontSize--sm);
  --pf-v5-c-description-list__term--LineHeight: var(--pf-v5-global--LineHeight--sm);
  --pf-v5-c-description-list__term-icon--MinWidth: var(--pf-v5-global--icon--FontSize--sm);
  --pf-v5-c-description-list__term-icon--MarginRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-description-list__term-icon--Color: var(--pf-v5-global--icon--Color--light);
  --pf-v5-c-description-list--m-vertical__group--GridTemplateColumns: repeat(var(--pf-v5-c-description-list--GridTemplateColumns--count));
  --pf-v5-c-description-list--m-vertical__group--GridTemplateRows: auto 1fr;
  --pf-v5-c-description-list--m-horizontal__term--width: 12ch;
  --pf-v5-c-description-list--m-horizontal__description--width: minmax(10ch, auto);
  --pf-v5-c-description-list--m-horizontal__group--GridTemplateColumns: var(--pf-v5-c-description-list__term--width) var(--pf-v5-c-description-list--m-horizontal__description--width);
  --pf-v5-c-description-list--m-horizontal__group--GridTemplateRows: auto;
  --pf-v5-c-description-list--m-1-col--GridTemplateColumns--count: 1;
  --pf-v5-c-description-list--m-2-col--GridTemplateColumns--count: 2;
  --pf-v5-c-description-list--m-3-col--GridTemplateColumns--count: 3;
  --pf-v5-c-description-list--m-auto-fit--GridTemplateColumns--min: 15.625rem;
  --pf-v5-c-description-list--m-auto-fit--GridTemplateColumns--minmax--min: var(--pf-v5-c-description-list--m-auto-fit--GridTemplateColumns--min);
  --pf-v5-c-description-list__text--m-help-text--TextDecorationColor: var(--pf-v5-global--BorderColor--200);
  --pf-v5-c-description-list__text--m-help-text--TextDecorationThickness: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-description-list__text--m-help-text--TextDecorationOffset: 0.25rem;
  --pf-v5-c-description-list__text--m-help-text--hover--TextDecorationColor: var(--pf-v5-global--Color--100);
  --pf-v5-c-description-list__text--m-help-text--focus--TextDecorationColor: var(--pf-v5-global--Color--100);
  --pf-v5-c-description-list--m-display-lg__description--FontSize: var(--pf-v5-global--FontSize--lg);
  --pf-v5-c-description-list--m-display-2xl__description--FontSize: var(--pf-v5-global--FontSize--2xl);
  display: grid;
  grid-template-columns: var(--pf-v5-c-description-list--GridTemplateColumns);
  row-gap: var(--pf-v5-c-description-list--RowGap);
  column-gap: var(--pf-v5-c-description-list--ColumnGap);
  align-items: baseline;
}
@media screen and (min-width: 576px) {
  .pf-v5-c-description-list {
    --pf-v5-c-description-list__term--Display: var(--pf-v5-c-description-list__term--sm--Display);
  }
}
.pf-v5-c-description-list[class*=pf-m-horizontal] {
  --pf-v5-c-description-list__term--width: var(--pf-v5-c-description-list--m-horizontal__term--width);
}
@media (min-width: 576px) {
  .pf-v5-c-description-list[class*=pf-m-horizontal] {
    --pf-v5-c-description-list__term--width: var(--pf-v5-c-description-list--m-horizontal__term--width-on-sm, var(--pf-v5-c-description-list--m-horizontal__term--width));
  }
}
@media (min-width: 768px) {
  .pf-v5-c-description-list[class*=pf-m-horizontal] {
    --pf-v5-c-description-list__term--width: var(--pf-v5-c-description-list--m-horizontal__term--width-on-md, var(--pf-v5-c-description-list--m-horizontal__term--width-on-sm, var(--pf-v5-c-description-list--m-horizontal__term--width)));
  }
}
@media (min-width: 992px) {
  .pf-v5-c-description-list[class*=pf-m-horizontal] {
    --pf-v5-c-description-list__term--width: var(--pf-v5-c-description-list--m-horizontal__term--width-on-lg, var(--pf-v5-c-description-list--m-horizontal__term--width-on-md, var(--pf-v5-c-description-list--m-horizontal__term--width-on-sm, var(--pf-v5-c-description-list--m-horizontal__term--width))));
  }
}
@media (min-width: 1200px) {
  .pf-v5-c-description-list[class*=pf-m-horizontal] {
    --pf-v5-c-description-list__term--width: var(--pf-v5-c-description-list--m-horizontal__term--width-on-xl, var(--pf-v5-c-description-list--m-horizontal__term--width-on-lg, var(--pf-v5-c-description-list--m-horizontal__term--width-on-md, var(--pf-v5-c-description-list--m-horizontal__term--width-on-sm, var(--pf-v5-c-description-list--m-horizontal__term--width)))));
  }
}
@media (min-width: 1450px) {
  .pf-v5-c-description-list[class*=pf-m-horizontal] {
    --pf-v5-c-description-list__term--width: var(--pf-v5-c-description-list--m-horizontal__term--width-on-2xl, var(--pf-v5-c-description-list--m-horizontal__term--width-on-xl, var(--pf-v5-c-description-list--m-horizontal__term--width-on-lg, var(--pf-v5-c-description-list--m-horizontal__term--width-on-md, var(--pf-v5-c-description-list--m-horizontal__term--width-on-sm, var(--pf-v5-c-description-list--m-horizontal__term--width))))));
  }
}
.pf-v5-c-description-list.pf-m-inline-grid {
  display: inline-grid;
}
.pf-v5-c-description-list.pf-m-auto-column-widths {
  --pf-v5-c-description-list--GridTemplateColumns--width: minmax(8ch, max-content);
}
.pf-v5-c-description-list.pf-m-auto-fit {
  grid-template-columns: repeat(auto-fit, minmax(var(--pf-v5-c-description-list--m-auto-fit--GridTemplateColumns--minmax--min), 1fr));
  --pf-v5-c-description-list--GridTemplateColumns--minmax--min: var(--pf-v5-c-description-list--GridTemplateColumns--min);
}
@media (min-width: 576px) {
  .pf-v5-c-description-list.pf-m-auto-fit {
    --pf-v5-c-description-list--GridTemplateColumns--minmax--min: var(--pf-v5-c-description-list--GridTemplateColumns--min-on-sm, var(--pf-v5-c-description-list--GridTemplateColumns--min));
  }
}
@media (min-width: 768px) {
  .pf-v5-c-description-list.pf-m-auto-fit {
    --pf-v5-c-description-list--GridTemplateColumns--minmax--min: var(--pf-v5-c-description-list--GridTemplateColumns--min-on-md, var(--pf-v5-c-description-list--GridTemplateColumns--min-on-sm, var(--pf-v5-c-description-list--GridTemplateColumns--min)));
  }
}
@media (min-width: 992px) {
  .pf-v5-c-description-list.pf-m-auto-fit {
    --pf-v5-c-description-list--GridTemplateColumns--minmax--min: var(--pf-v5-c-description-list--GridTemplateColumns--min-on-lg, var(--pf-v5-c-description-list--GridTemplateColumns--min-on-md, var(--pf-v5-c-description-list--GridTemplateColumns--min-on-sm, var(--pf-v5-c-description-list--GridTemplateColumns--min))));
  }
}
@media (min-width: 1200px) {
  .pf-v5-c-description-list.pf-m-auto-fit {
    --pf-v5-c-description-list--GridTemplateColumns--minmax--min: var(--pf-v5-c-description-list--GridTemplateColumns--min-on-xl, var(--pf-v5-c-description-list--GridTemplateColumns--min-on-lg, var(--pf-v5-c-description-list--GridTemplateColumns--min-on-md, var(--pf-v5-c-description-list--GridTemplateColumns--min-on-sm, var(--pf-v5-c-description-list--GridTemplateColumns--min)))));
  }
}
@media (min-width: 1450px) {
  .pf-v5-c-description-list.pf-m-auto-fit {
    --pf-v5-c-description-list--GridTemplateColumns--minmax--min: var(--pf-v5-c-description-list--GridTemplateColumns--min-on-2xl, var(--pf-v5-c-description-list--GridTemplateColumns--min-on-xl, var(--pf-v5-c-description-list--GridTemplateColumns--min-on-lg, var(--pf-v5-c-description-list--GridTemplateColumns--min-on-md, var(--pf-v5-c-description-list--GridTemplateColumns--min-on-sm, var(--pf-v5-c-description-list--GridTemplateColumns--min))))));
  }
}
.pf-v5-c-description-list.pf-m-compact {
  --pf-v5-c-description-list--RowGap: var(--pf-v5-c-description-list--m-compact--RowGap);
  --pf-v5-c-description-list--ColumnGap: var(--pf-v5-c-description-list--m-compact--ColumnGap);
}
.pf-v5-c-description-list.pf-m-fluid {
  --pf-v5-c-description-list--m-horizontal__term--width: fit-content(20ch);
}
.pf-v5-c-description-list.pf-m-fill-columns {
  display: block;
  column-count: var(--pf-v5-c-description-list--GridTemplateColumns--count);
  margin-block-end: calc(var(--pf-v5-c-description-list--RowGap) * -1);
}
.pf-v5-c-description-list.pf-m-fill-columns .pf-v5-c-description-list__group,
.pf-v5-c-description-list.pf-m-fill-columns > .pf-v5-c-card {
  display: inline-grid;
  width: 100%;
  margin-block-end: var(--pf-v5-c-description-list--RowGap);
  break-inside: avoid;
  page-break-inside: avoid;
  -webkit-column-break-inside: avoid;
  -moz-column-break-inside: avoid;
}
.pf-v5-c-description-list.pf-m-display-lg {
  --pf-v5-c-description-list__description--FontSize: var(--pf-v5-c-description-list--m-display-lg__description--FontSize);
}
.pf-v5-c-description-list.pf-m-display-2xl {
  --pf-v5-c-description-list__description--FontSize: var(--pf-v5-c-description-list--m-display-2xl__description--FontSize);
}
.pf-v5-c-description-list > .pf-v5-c-card {
  align-self: stretch;
  padding-block-start: var(--pf-v5-c-card--first-child--PaddingTop);
  padding-block-end: var(--pf-v5-c-card--child--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-card--child--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-card--child--PaddingRight);
}

.pf-v5-c-description-list__group,
.pf-v5-c-description-list > .pf-v5-c-card {
  display: grid;
  grid-template-rows: var(--pf-v5-c-description-list__group--GridTemplateRows);
  grid-template-columns: var(--pf-v5-c-description-list__group--GridTemplateColumns);
  grid-column: var(--pf-v5-c-description-list__group--GridColumn);
  row-gap: var(--pf-v5-c-description-list__group--RowGap);
  column-gap: var(--pf-v5-c-description-list__group--ColumnGap);
  align-items: baseline;
}

.pf-v5-c-description-list__term,
.pf-v5-c-description-list__description {
  min-width: 0;
  text-align: start;
  overflow-wrap: break-word;
}

.pf-v5-c-description-list__term {
  display: var(--pf-v5-c-description-list__term--Display);
  font-size: var(--pf-v5-c-description-list__term--FontSize);
  font-weight: var(--pf-v5-c-description-list__term--FontWeight);
  line-height: var(--pf-v5-c-description-list__term--LineHeight);
}
.pf-v5-c-description-list__term .pf-v5-c-description-list__text {
  display: inline;
}

.pf-v5-c-description-list__term-icon {
  flex-shrink: 0;
  min-width: var(--pf-v5-c-description-list__term-icon--MinWidth);
  margin-inline-end: var(--pf-v5-c-description-list__term-icon--MarginRight);
  color: var(--pf-v5-c-description-list__term-icon--Color);
}

.pf-v5-c-description-list__description {
  font-size: var(--pf-v5-c-description-list__description--FontSize, inherit);
}

.pf-v5-c-description-list__text {
  min-width: 0;
}
.pf-v5-c-description-list__text.pf-m-help-text {
  text-decoration: underline;
  text-decoration-thickness: var(--pf-v5-c-description-list__text--m-help-text--TextDecorationThickness);
  text-decoration-style: dashed;
  text-decoration-color: var(--pf-v5-c-description-list__text--m-help-text--TextDecorationColor);
  text-underline-offset: var(--pf-v5-c-description-list__text--m-help-text--TextDecorationOffset);
  cursor: pointer;
}
.pf-v5-c-description-list__text.pf-m-help-text:hover {
  --pf-v5-c-description-list__text--m-help-text--TextDecorationColor: var(--pf-v5-c-description-list__text--m-help-text--hover--TextDecorationColor);
}
.pf-v5-c-description-list__text.pf-m-help-text:focus {
  --pf-v5-c-description-list__text--m-help-text--TextDecorationColor: var(--pf-v5-c-description-list__text--m-help-text--focus--TextDecorationColor);
}

.pf-v5-c-description-list.pf-m-1-col {
  --pf-v5-c-description-list--GridTemplateColumns--count: var(--pf-v5-c-description-list--m-1-col--GridTemplateColumns--count);
}
.pf-v5-c-description-list.pf-m-2-col {
  --pf-v5-c-description-list--GridTemplateColumns--count: var(--pf-v5-c-description-list--m-2-col--GridTemplateColumns--count);
}
.pf-v5-c-description-list.pf-m-3-col {
  --pf-v5-c-description-list--GridTemplateColumns--count: var(--pf-v5-c-description-list--m-3-col--GridTemplateColumns--count);
}
.pf-v5-c-description-list.pf-m-horizontal {
  --pf-v5-c-description-list__group--GridTemplateColumns: var(--pf-v5-c-description-list--m-horizontal__group--GridTemplateColumns);
  --pf-v5-c-description-list__group--GridTemplateRows: var(--pf-v5-c-description-list--m-horizontal__group--GridTemplateRows);
}
.pf-v5-c-description-list.pf-m-vertical {
  --pf-v5-c-description-list__group--GridTemplateColumns: var(--pf-v5-c-description-list--m-vertical__group--GridTemplateColumns);
  --pf-v5-c-description-list__group--GridTemplateRows: var(--pf-v5-c-description-list--m-vertical__group--GridTemplateRows);
}
@media (min-width: 576px) {
  .pf-v5-c-description-list.pf-m-1-col-on-sm {
    --pf-v5-c-description-list--GridTemplateColumns--count: var(--pf-v5-c-description-list--m-1-col--GridTemplateColumns--count);
  }
  .pf-v5-c-description-list.pf-m-2-col-on-sm {
    --pf-v5-c-description-list--GridTemplateColumns--count: var(--pf-v5-c-description-list--m-2-col--GridTemplateColumns--count);
  }
  .pf-v5-c-description-list.pf-m-3-col-on-sm {
    --pf-v5-c-description-list--GridTemplateColumns--count: var(--pf-v5-c-description-list--m-3-col--GridTemplateColumns--count);
  }
  .pf-v5-c-description-list.pf-m-horizontal-on-sm {
    --pf-v5-c-description-list__group--GridTemplateColumns: var(--pf-v5-c-description-list--m-horizontal__group--GridTemplateColumns);
    --pf-v5-c-description-list__group--GridTemplateRows: var(--pf-v5-c-description-list--m-horizontal__group--GridTemplateRows);
  }
  .pf-v5-c-description-list.pf-m-vertical-on-sm {
    --pf-v5-c-description-list__group--GridTemplateColumns: var(--pf-v5-c-description-list--m-vertical__group--GridTemplateColumns);
    --pf-v5-c-description-list__group--GridTemplateRows: var(--pf-v5-c-description-list--m-vertical__group--GridTemplateRows);
  }
}
@media (min-width: 768px) {
  .pf-v5-c-description-list.pf-m-1-col-on-md {
    --pf-v5-c-description-list--GridTemplateColumns--count: var(--pf-v5-c-description-list--m-1-col--GridTemplateColumns--count);
  }
  .pf-v5-c-description-list.pf-m-2-col-on-md {
    --pf-v5-c-description-list--GridTemplateColumns--count: var(--pf-v5-c-description-list--m-2-col--GridTemplateColumns--count);
  }
  .pf-v5-c-description-list.pf-m-3-col-on-md {
    --pf-v5-c-description-list--GridTemplateColumns--count: var(--pf-v5-c-description-list--m-3-col--GridTemplateColumns--count);
  }
  .pf-v5-c-description-list.pf-m-horizontal-on-md {
    --pf-v5-c-description-list__group--GridTemplateColumns: var(--pf-v5-c-description-list--m-horizontal__group--GridTemplateColumns);
    --pf-v5-c-description-list__group--GridTemplateRows: var(--pf-v5-c-description-list--m-horizontal__group--GridTemplateRows);
  }
  .pf-v5-c-description-list.pf-m-vertical-on-md {
    --pf-v5-c-description-list__group--GridTemplateColumns: var(--pf-v5-c-description-list--m-vertical__group--GridTemplateColumns);
    --pf-v5-c-description-list__group--GridTemplateRows: var(--pf-v5-c-description-list--m-vertical__group--GridTemplateRows);
  }
}
@media (min-width: 992px) {
  .pf-v5-c-description-list.pf-m-1-col-on-lg {
    --pf-v5-c-description-list--GridTemplateColumns--count: var(--pf-v5-c-description-list--m-1-col--GridTemplateColumns--count);
  }
  .pf-v5-c-description-list.pf-m-2-col-on-lg {
    --pf-v5-c-description-list--GridTemplateColumns--count: var(--pf-v5-c-description-list--m-2-col--GridTemplateColumns--count);
  }
  .pf-v5-c-description-list.pf-m-3-col-on-lg {
    --pf-v5-c-description-list--GridTemplateColumns--count: var(--pf-v5-c-description-list--m-3-col--GridTemplateColumns--count);
  }
  .pf-v5-c-description-list.pf-m-horizontal-on-lg {
    --pf-v5-c-description-list__group--GridTemplateColumns: var(--pf-v5-c-description-list--m-horizontal__group--GridTemplateColumns);
    --pf-v5-c-description-list__group--GridTemplateRows: var(--pf-v5-c-description-list--m-horizontal__group--GridTemplateRows);
  }
  .pf-v5-c-description-list.pf-m-vertical-on-lg {
    --pf-v5-c-description-list__group--GridTemplateColumns: var(--pf-v5-c-description-list--m-vertical__group--GridTemplateColumns);
    --pf-v5-c-description-list__group--GridTemplateRows: var(--pf-v5-c-description-list--m-vertical__group--GridTemplateRows);
  }
}
@media (min-width: 1200px) {
  .pf-v5-c-description-list.pf-m-1-col-on-xl {
    --pf-v5-c-description-list--GridTemplateColumns--count: var(--pf-v5-c-description-list--m-1-col--GridTemplateColumns--count);
  }
  .pf-v5-c-description-list.pf-m-2-col-on-xl {
    --pf-v5-c-description-list--GridTemplateColumns--count: var(--pf-v5-c-description-list--m-2-col--GridTemplateColumns--count);
  }
  .pf-v5-c-description-list.pf-m-3-col-on-xl {
    --pf-v5-c-description-list--GridTemplateColumns--count: var(--pf-v5-c-description-list--m-3-col--GridTemplateColumns--count);
  }
  .pf-v5-c-description-list.pf-m-horizontal-on-xl {
    --pf-v5-c-description-list__group--GridTemplateColumns: var(--pf-v5-c-description-list--m-horizontal__group--GridTemplateColumns);
    --pf-v5-c-description-list__group--GridTemplateRows: var(--pf-v5-c-description-list--m-horizontal__group--GridTemplateRows);
  }
  .pf-v5-c-description-list.pf-m-vertical-on-xl {
    --pf-v5-c-description-list__group--GridTemplateColumns: var(--pf-v5-c-description-list--m-vertical__group--GridTemplateColumns);
    --pf-v5-c-description-list__group--GridTemplateRows: var(--pf-v5-c-description-list--m-vertical__group--GridTemplateRows);
  }
}
@media (min-width: 1450px) {
  .pf-v5-c-description-list.pf-m-1-col-on-2xl {
    --pf-v5-c-description-list--GridTemplateColumns--count: var(--pf-v5-c-description-list--m-1-col--GridTemplateColumns--count);
  }
  .pf-v5-c-description-list.pf-m-2-col-on-2xl {
    --pf-v5-c-description-list--GridTemplateColumns--count: var(--pf-v5-c-description-list--m-2-col--GridTemplateColumns--count);
  }
  .pf-v5-c-description-list.pf-m-3-col-on-2xl {
    --pf-v5-c-description-list--GridTemplateColumns--count: var(--pf-v5-c-description-list--m-3-col--GridTemplateColumns--count);
  }
  .pf-v5-c-description-list.pf-m-horizontal-on-2xl {
    --pf-v5-c-description-list__group--GridTemplateColumns: var(--pf-v5-c-description-list--m-horizontal__group--GridTemplateColumns);
    --pf-v5-c-description-list__group--GridTemplateRows: var(--pf-v5-c-description-list--m-horizontal__group--GridTemplateRows);
  }
  .pf-v5-c-description-list.pf-m-vertical-on-2xl {
    --pf-v5-c-description-list__group--GridTemplateColumns: var(--pf-v5-c-description-list--m-vertical__group--GridTemplateColumns);
    --pf-v5-c-description-list__group--GridTemplateRows: var(--pf-v5-c-description-list--m-vertical__group--GridTemplateRows);
  }
}