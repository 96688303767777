.pf-v5-c-page__sidebar.pf-m-light {
  --pf-v5-global--Color--100: var(--pf-v5-global--Color--dark-100);
  --pf-v5-global--Color--200: var(--pf-v5-global--Color--dark-200);
  --pf-v5-global--BorderColor--100: var(--pf-v5-global--BorderColor--dark-100);
  --pf-v5-global--primary-color--100: var(--pf-v5-global--primary-color--dark-100);
  --pf-v5-global--link--Color: var(--pf-v5-global--link--Color--dark);
  --pf-v5-global--link--Color--hover: var(--pf-v5-global--link--Color--dark--hover);
  --pf-v5-global--BackgroundColor--100: var(--pf-v5-global--BackgroundColor--light-100);
  --pf-v5-global--icon--Color--light: var(--pf-v5-global--icon--Color--light--dark);
  --pf-v5-global--icon--Color--dark: var(--pf-v5-global--icon--Color--dark--dark);
}

.pf-v5-c-page__main-section[class*=pf-m-dark-], .pf-v5-c-page__sidebar-body.pf-m-menu .pf-v5-c-context-selector, .pf-v5-c-page__sidebar, .pf-v5-c-page__header {
  --pf-v5-global--Color--100: var(--pf-v5-global--Color--light-100);
  --pf-v5-global--Color--200: var(--pf-v5-global--Color--light-200);
  --pf-v5-global--BorderColor--100: var(--pf-v5-global--BorderColor--light-100);
  --pf-v5-global--primary-color--100: var(--pf-v5-global--primary-color--light-100);
  --pf-v5-global--link--Color: var(--pf-v5-global--link--Color--light);
  --pf-v5-global--link--Color--hover: var(--pf-v5-global--link--Color--light);
  --pf-v5-global--BackgroundColor--100: var(--pf-v5-global--BackgroundColor--dark-100);
  --pf-v5-global--icon--Color--light: var(--pf-v5-global--icon--Color--light--light);
  --pf-v5-global--icon--Color--dark: var(--pf-v5-global--icon--Color--dark--light);
}
.pf-v5-c-page__main-section[class*=pf-m-dark-] .pf-v5-c-button, .pf-v5-c-page__sidebar-body.pf-m-menu .pf-v5-c-context-selector .pf-v5-c-button, .pf-v5-c-page__sidebar .pf-v5-c-button, .pf-v5-c-page__header .pf-v5-c-button {
  --pf-v5-c-button--m-primary--BackgroundColor: var(--pf-v5-global--primary-color--dark-100);
}

.pf-v5-c-page__header-tools-item, .pf-v5-c-page__header-tools-group {
  --pf-v5-hidden-visible--hidden--Display: none;
  --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--visible--Display);
  display: var(--pf-v5-hidden-visible--Display);
}
.pf-m-hidden.pf-v5-c-page__header-tools-item, .pf-m-hidden.pf-v5-c-page__header-tools-group {
  --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--hidden--Display);
}
@media screen and (min-width: 576px) {
  .pf-m-hidden-on-sm.pf-v5-c-page__header-tools-item, .pf-m-hidden-on-sm.pf-v5-c-page__header-tools-group {
    --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--hidden--Display);
  }
  .pf-m-visible-on-sm.pf-v5-c-page__header-tools-item, .pf-m-visible-on-sm.pf-v5-c-page__header-tools-group {
    --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--visible--Display);
  }
}
@media screen and (min-width: 768px) {
  .pf-m-hidden-on-md.pf-v5-c-page__header-tools-item, .pf-m-hidden-on-md.pf-v5-c-page__header-tools-group {
    --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--hidden--Display);
  }
  .pf-m-visible-on-md.pf-v5-c-page__header-tools-item, .pf-m-visible-on-md.pf-v5-c-page__header-tools-group {
    --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--visible--Display);
  }
}
@media screen and (min-width: 992px) {
  .pf-m-hidden-on-lg.pf-v5-c-page__header-tools-item, .pf-m-hidden-on-lg.pf-v5-c-page__header-tools-group {
    --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--hidden--Display);
  }
  .pf-m-visible-on-lg.pf-v5-c-page__header-tools-item, .pf-m-visible-on-lg.pf-v5-c-page__header-tools-group {
    --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--visible--Display);
  }
}
@media screen and (min-width: 1200px) {
  .pf-m-hidden-on-xl.pf-v5-c-page__header-tools-item, .pf-m-hidden-on-xl.pf-v5-c-page__header-tools-group {
    --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--hidden--Display);
  }
  .pf-m-visible-on-xl.pf-v5-c-page__header-tools-item, .pf-m-visible-on-xl.pf-v5-c-page__header-tools-group {
    --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--visible--Display);
  }
}
@media screen and (min-width: 1450px) {
  .pf-m-hidden-on-2xl.pf-v5-c-page__header-tools-item, .pf-m-hidden-on-2xl.pf-v5-c-page__header-tools-group {
    --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--hidden--Display);
  }
  .pf-m-visible-on-2xl.pf-v5-c-page__header-tools-item, .pf-m-visible-on-2xl.pf-v5-c-page__header-tools-group {
    --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--visible--Display);
  }
}

:root {
  --pf-v5-c-page--BackgroundColor: var(--pf-v5-global--BackgroundColor--light-300);
  --pf-v5-c-page--inset: var(--pf-v5-global--spacer--md);
  --pf-v5-c-page--xl--inset: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-page__header--BackgroundColor: var(--pf-v5-global--BackgroundColor--dark-100);
  --pf-v5-c-page__header--ZIndex: var(--pf-v5-global--ZIndex--md);
  --pf-v5-c-page__header--MinHeight: 4.75rem;
  --pf-v5-c-page__header-brand--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-page__header-brand--xl--PaddingRight: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-page__header-brand--xl--PaddingLeft: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-page__header-sidebar-toggle__c-button--PaddingTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-page__header-sidebar-toggle__c-button--PaddingRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-page__header-sidebar-toggle__c-button--PaddingBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-page__header-sidebar-toggle__c-button--PaddingLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-page__header-sidebar-toggle__c-button--MarginRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-page__header-sidebar-toggle__c-button--MarginLeft: calc(var(--pf-v5-c-page__header-sidebar-toggle__c-button--PaddingLeft) * -1);
  --pf-v5-c-page__header-sidebar-toggle__c-button--FontSize: var(--pf-v5-global--FontSize--2xl);
  --pf-v5-c-page__header-brand-link--c-brand--MaxHeight: 3.75rem;
  --pf-v5-c-page__header-nav--BackgroundColor: var(--pf-v5-global--BackgroundColor--dark-300);
  --pf-v5-c-page__header-nav--xl--BackgroundColor: transparent;
  --pf-v5-c-page__header-nav--xl--PaddingRight: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-page__header-nav--xl--PaddingLeft: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-page__header-tools--MarginRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-page__header-tools--xl--MarginRight: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-page__header-tools--c-avatar--MarginLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-page__header-tools-group--MarginLeft: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-page__header-tools-group--Display: flex;
  --pf-v5-c-page__header-tools-item--Display: block;
  --pf-v5-c-page__header-tools-item--c-notification-badge--hover--BackgroundColor: var(--pf-v5-global--BackgroundColor--dark-200);
  --pf-v5-c-page__header-tools--c-button--notification-badge--m-unread--after--BackgroundColor: var(--pf-v5-global--primary-color--200);
  --pf-v5-c-page__header-tools--c-button--notification-badge--m-attention--after--BackgroundColor: var(--pf-v5-global--danger-color--200);
  --pf-v5-c-page__header-tools--c-button--m-selected--notification-badge--m-unread--after--BackgroundColor: var(--pf-v5-global--primary-color--200);
  --pf-v5-c-page__header-tools--c-button--m-selected--notification-badge--m-attention--after--BackgroundColor: var(--pf-v5-global--danger-color--200);
  --pf-v5-c-page__header-tools--c-button--m-selected--before--Width: auto;
  --pf-v5-c-page__header-tools--c-button--m-selected--before--Height: auto;
  --pf-v5-c-page__header-tools--c-button--m-selected--before--BackgroundColor: var(--pf-v5-global--BackgroundColor--dark-200);
  --pf-v5-c-page__header-tools--c-button--m-selected--before--BorderRadius: var(--pf-v5-global--BorderRadius--sm);
  --pf-v5-c-page__header-tools--c-button--m-selected--c-notification-badge--m-unread--after--BorderColor: transparent;
  --pf-v5-c-page__sidebar--ZIndex: var(--pf-v5-global--ZIndex--sm);
  --pf-v5-c-page__sidebar--Width: 18.125rem;
  --pf-v5-c-page__sidebar--BackgroundColor: var(--pf-v5-global--BackgroundColor--dark-300);
  --pf-v5-c-page__sidebar--m-light--BackgroundColor: var(--pf-v5-global--BackgroundColor--light-100);
  --pf-v5-c-page__sidebar--m-light--BorderRightWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-page__sidebar--m-light--BorderRightColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-page__sidebar--BoxShadow: var(--pf-v5-global--BoxShadow--lg-right);
  --pf-v5-c-page__sidebar--Transition: var(--pf-v5-global--Transition);
  --pf-v5-c-page__sidebar--TranslateX: -100%;
  --pf-v5-c-page__sidebar--TranslateZ: 0;
  --pf-v5-c-page__sidebar--m-expanded--TranslateX: 0;
  --pf-v5-c-page__sidebar--xl--TranslateX: 0;
  --pf-v5-c-page__sidebar-body--PaddingRight: 0;
  --pf-v5-c-page__sidebar-body--PaddingLeft: 0;
  --pf-v5-c-page__sidebar-body--m-page-insets--PaddingRight: var(--pf-v5-c-page--inset);
  --pf-v5-c-page__sidebar-body--m-page-insets--PaddingLeft: var(--pf-v5-c-page--inset);
  --pf-v5-c-page__sidebar-body--m-menu--BorderTopColor: var(--pf-v5-global--BackgroundColor--dark-200);
  --pf-v5-c-page__sidebar-body--m-menu--BorderTopWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-page__sidebar-body--m-menu--c-context-selector--BorderBottomColor: var(--pf-v5-global--BackgroundColor--dark-200);
  --pf-v5-c-page__main--ZIndex: var(--pf-v5-global--ZIndex--xs);
  --pf-v5-c-page__main-section--PaddingTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-page__main-section--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-page__main-section--PaddingBottom: var(--pf-v5-global--spacer--md);
  --pf-v5-c-page__main-section--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-page__main-section--xl--PaddingTop: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-page__main-section--xl--PaddingRight: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-page__main-section--xl--PaddingBottom: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-page__main-section--xl--PaddingLeft: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-page__main-breadcrumb--main-section--PaddingTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-page__main-section--BackgroundColor: var(--pf-v5-global--BackgroundColor--light-300);
  --pf-v5-c-page__main-section--m-light--BackgroundColor: var(--pf-v5-global--BackgroundColor--light-100);
  --pf-v5-c-page__main-section--m-light-100--BackgroundColor: var(--pf-v5-global--BackgroundColor--150);
  --pf-v5-c-page__main-section--m-dark-100--BackgroundColor: var(--pf-v5-global--BackgroundColor--dark-transparent-100);
  --pf-v5-c-page__main-section--m-dark-200--BackgroundColor: var(--pf-v5-global--BackgroundColor--dark-transparent-200);
  --pf-v5-c-page__main-breadcrumb--page__main-tabs--PaddingTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-page__main-nav--page__main-tabs--PaddingTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-page--section--m-limit-width--MaxWidth: calc(125rem - var(--pf-v5-c-page__sidebar--Width));
  --pf-v5-c-page--section--m-sticky-top--ZIndex: var(--pf-v5-global--ZIndex--md);
  --pf-v5-c-page--section--m-sticky-top--BoxShadow: var(--pf-v5-global--BoxShadow--sm-bottom);
  --pf-v5-c-page--section--m-sticky-bottom--ZIndex: var(--pf-v5-global--ZIndex--md);
  --pf-v5-c-page--section--m-sticky-bottom--BoxShadow: var(--pf-v5-global--BoxShadow--sm-top);
  --pf-v5-c-page--section--m-shadow-bottom--BoxShadow: var(--pf-v5-global--BoxShadow--sm-bottom);
  --pf-v5-c-page--section--m-shadow-bottom--ZIndex: var(--pf-v5-global--ZIndex--xs);
  --pf-v5-c-page--section--m-shadow-top--BoxShadow: var(--pf-v5-global--BoxShadow--sm-top);
  --pf-v5-c-page--section--m-shadow-top--ZIndex: var(--pf-v5-global--ZIndex--xs);
  --pf-v5-c-page__main-nav--BackgroundColor: var(--pf-v5-global--BackgroundColor--light-100);
  --pf-v5-c-page__main-nav--PaddingTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-page__main-nav--PaddingRight: 0;
  --pf-v5-c-page__main-nav--PaddingLeft: 0;
  --pf-v5-c-page__main-nav--m-sticky-top--PaddingBottom: var(--pf-v5-global--spacer--md);
  --pf-v5-c-page__main-nav--xl--PaddingRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-page__main-nav--xl--PaddingLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-page__main-subnav--BackgroundColor: var(--pf-v5-global--BackgroundColor--dark-300);
  --pf-v5-c-page__main-subnav--BorderTopWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-page__main-subnav--BorderTopColor: var(--pf-v5-global--palette--black-800);
  --pf-v5-c-page__main-subnav--BorderLeftWidth: 0;
  --pf-v5-c-page__main-subnav--BorderLeftColor: var(--pf-v5-global--palette--black-800);
  --pf-v5-c-page__sidebar--main__main-subnav--BorderLeftWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-page__sidebar--m-collapsed--main__main-subnav--BorderLeftWidth: 0;
  --pf-v5-c-page__main-breadcrumb--BackgroundColor: var(--pf-v5-global--BackgroundColor--light-100);
  --pf-v5-c-page__main-breadcrumb--PaddingTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-page__main-breadcrumb--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-page__main-breadcrumb--PaddingBottom: 0;
  --pf-v5-c-page__main-breadcrumb--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-page__main-breadcrumb--m-sticky-top--PaddingBottom: var(--pf-v5-global--spacer--md);
  --pf-v5-c-page__main-breadcrumb--xl--PaddingRight: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-page__main-breadcrumb--xl--PaddingLeft: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-page__main-tabs--PaddingTop: 0;
  --pf-v5-c-page__main-tabs--PaddingRight: 0;
  --pf-v5-c-page__main-tabs--PaddingBottom: 0;
  --pf-v5-c-page__main-tabs--PaddingLeft: 0;
  --pf-v5-c-page__main-tabs--BackgroundColor: var(--pf-v5-global--BackgroundColor--light-100);
  --pf-v5-c-page__main-wizard--BackgroundColor: var(--pf-v5-global--BackgroundColor--light-100);
  --pf-v5-c-page__main-wizard--BorderTopColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-page__main-wizard--BorderTopWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-page__main-wizard--m-light-200--BackgroundColor: var(--pf-v5-global--BackgroundColor--200);
}
@media (min-width: 1200px) {
  :root {
    --pf-v5-c-page__header-brand--PaddingLeft: var(--pf-v5-c-page__header-brand--xl--PaddingLeft);
    --pf-v5-c-page--inset: var(--pf-v5-c-page--xl--inset);
  }
}
@media screen and (min-width: 1200px) {
  :root {
    --pf-v5-c-page__header-nav--BackgroundColor: var(--pf-v5-c-page__header-nav--xl--BackgroundColor);
    --pf-v5-c-page__header-nav--PaddingRight: var(--pf-v5-c-page__header-nav--xl--PaddingRight);
    --pf-v5-c-page__header-nav--PaddingLeft: var(--pf-v5-c-page__header-nav--xl--PaddingLeft);
  }
}
@media screen and (min-width: 1200px) {
  :root {
    --pf-v5-c-page__header-tools--MarginRight: var(--pf-v5-c-page__header-tools--xl--MarginRight);
  }
}
@media screen and (min-width: 1200px) {
  :root {
    --pf-v5-c-page__sidebar--TranslateX: var(--pf-v5-c-page__sidebar--xl--TranslateX);
  }
}
@media screen and (min-width: 1200px) {
  :root {
    --pf-v5-c-page__main-section--PaddingTop: var(--pf-v5-c-page__main-section--xl--PaddingTop);
    --pf-v5-c-page__main-section--PaddingRight: var(--pf-v5-c-page__main-section--xl--PaddingRight);
    --pf-v5-c-page__main-section--PaddingBottom: var(--pf-v5-c-page__main-section--xl--PaddingBottom);
    --pf-v5-c-page__main-section--PaddingLeft: var(--pf-v5-c-page__main-section--xl--PaddingLeft);
  }
}
@media screen and (min-width: 1200px) {
  :root {
    --pf-v5-c-page__main-nav--PaddingRight: var(--pf-v5-c-page__main-nav--xl--PaddingRight);
    --pf-v5-c-page__main-nav--PaddingLeft: var(--pf-v5-c-page__main-nav--xl--PaddingLeft);
  }
}
@media screen and (min-width: 1200px) {
  :root {
    --pf-v5-c-page__main-breadcrumb--PaddingRight: var(--pf-v5-c-page__main-breadcrumb--xl--PaddingRight);
    --pf-v5-c-page__main-breadcrumb--PaddingLeft: var(--pf-v5-c-page__main-breadcrumb--xl--PaddingLeft);
  }
}

.pf-v5-c-page {
  display: grid;
  grid-template-areas: "header" "main";
  grid-template-rows: max-content 1fr;
  grid-template-columns: 1fr;
  height: 100vh;
  height: 100dvh;
  max-height: 100%;
  background-color: var(--pf-v5-c-page--BackgroundColor);
}
@media (min-width: 1200px) {
  .pf-v5-c-page {
    grid-template-areas: "header header" "nav main";
    grid-template-columns: max-content 1fr;
  }
}

.pf-v5-c-page__header,
.pf-v5-c-page > .pf-v5-c-masthead {
  z-index: var(--pf-v5-c-page__header--ZIndex);
  grid-area: header;
}

.pf-v5-c-page__header {
  color: var(--pf-v5-global--Color--100);
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  min-width: 0;
  min-height: var(--pf-v5-c-page__header--MinHeight);
  background-color: var(--pf-v5-c-page__header--BackgroundColor);
}
.pf-v5-c-page__header > * {
  display: flex;
  align-items: center;
}
@media screen and (min-width: 992px) {
  .pf-v5-c-page__header {
    grid-template-columns: auto 1fr auto;
  }
}
.pf-v5-c-page__header .pf-v5-c-masthead {
  grid-column: span 2;
  align-self: stretch;
}

.pf-v5-c-page__header-brand {
  grid-column: 1/2;
  padding-inline-start: var(--pf-v5-c-page__header-brand--PaddingLeft);
}
@media (min-width: 1200px) {
  .pf-v5-c-page__header-brand {
    padding-inline-end: var(--pf-v5-c-page__header-brand--xl--PaddingRight);
  }
}

.pf-v5-c-page__header-brand-link {
  display: flex;
  flex: 1;
  align-items: center;
}
.pf-v5-c-page__header-brand-link .pf-v5-c-brand {
  max-height: var(--pf-v5-c-page__header-brand-link--c-brand--MaxHeight);
}

.pf-v5-c-page__header-brand-toggle .pf-v5-c-button {
  padding-block-start: var(--pf-v5-c-page__header-sidebar-toggle__c-button--PaddingTop);
  padding-block-end: var(--pf-v5-c-page__header-sidebar-toggle__c-button--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-page__header-sidebar-toggle__c-button--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-page__header-sidebar-toggle__c-button--PaddingRight);
  margin-inline-start: var(--pf-v5-c-page__header-sidebar-toggle__c-button--MarginLeft);
  margin-inline-end: var(--pf-v5-c-page__header-sidebar-toggle__c-button--MarginRight);
  font-size: var(--pf-v5-c-page__header-sidebar-toggle__c-button--FontSize);
  line-height: 1;
}

.pf-v5-c-page__header-nav {
  grid-row: 2/3;
  grid-column: 1/-1;
  align-self: stretch;
  min-width: 0;
  padding-inline-start: var(--pf-v5-c-page__header-nav--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-page__header-nav--PaddingRight);
  background-color: var(--pf-v5-c-page__header-nav--BackgroundColor);
}
@media screen and (min-width: 1200px) {
  .pf-v5-c-page__header-nav {
    grid-row: 1/2;
    grid-column: 2/3;
  }
}
.pf-v5-c-page__header-nav .pf-v5-c-nav {
  align-self: stretch;
}

.pf-v5-c-page__header-tools {
  grid-column: 2/3;
  margin-inline-start: auto;
  margin-inline-end: var(--pf-v5-c-page__header-tools--MarginRight);
}
.pf-v5-c-page__header-tools .pf-v5-c-avatar {
  margin-inline-start: var(--pf-v5-c-page__header-tools--c-avatar--MarginLeft);
}
@media screen and (min-width: 992px) {
  .pf-v5-c-page__header-tools {
    grid-column: 3/4;
  }
}

.pf-v5-c-page__header-tools-group {
  --pf-v5-hidden-visible--visible--Display: var(--pf-v5-c-page__header-tools-group--Display);
  align-items: center;
}
.pf-v5-c-page__header-tools-group + .pf-v5-c-page__header-tools-group {
  margin-inline-start: var(--pf-v5-c-page__header-tools-group--MarginLeft);
}

.pf-v5-c-page__header-tools-item {
  --pf-v5-hidden-visible--visible--Display: var(--pf-v5-c-page__header-tools-item--Display);
}
.pf-v5-c-page__header-tools-item .pf-v5-c-notification-badge.pf-m-read:hover {
  --pf-v5-c-notification-badge--after--BackgroundColor: var(--pf-v5-c-page__header-tools-item--c-notification-badge--hover--BackgroundColor);
}
.pf-v5-c-page__header-tools-item.pf-m-selected .pf-v5-c-button {
  background-color: var(--pf-v5-c-page__header-tools--c-button--m-selected--before--BackgroundColor);
  border-radius: var(--pf-v5-c-page__header-tools--c-button--m-selected--before--BorderRadius);
}
.pf-v5-c-page__header-tools-item.pf-m-selected .pf-v5-c-button::before {
  position: absolute;
  inset: 0;
  width: var(--pf-v5-c-page__header-tools--c-button--m-selected--before--Width);
  height: var(--pf-v5-c-page__header-tools--c-button--m-selected--before--Height);
  content: "";
}
.pf-v5-c-page__header-tools-item.pf-m-selected .pf-v5-c-button .pf-v5-c-notification-badge.pf-m-unread {
  --pf-v5-c-notification-badge--after--BackgroundColor: var(--pf-v5-c-page__header-tools--c-button--m-selected--notification-badge--m-unread--after--BackgroundColor);
}
.pf-v5-c-page__header-tools-item.pf-m-selected .pf-v5-c-button .pf-v5-c-notification-badge.pf-m-unread::after {
  border-color: var(--pf-v5-c-page__header-tools--c-button--m-selected--c-notification-badge--m-unread--after--BorderColor);
}
.pf-v5-c-page__header-tools-item.pf-m-selected .pf-v5-c-button .pf-v5-c-notification-badge.pf-m-attention {
  --pf-v5-c-notification-badge--after--BackgroundColor: var(--pf-v5-global--danger-color--200);
}
.pf-v5-c-page__header-tools-item .pf-v5-c-button:focus .pf-v5-c-notification-badge.pf-m-unread {
  --pf-v5-c-notification-badge--after--BackgroundColor: var(--pf-v5-c-page__header-tools--c-button--notification-badge--m-unread--after--BackgroundColor);
}
.pf-v5-c-page__header-tools-item .pf-v5-c-button:focus .pf-v5-c-notification-badge.pf-m-attention {
  --pf-v5-c-notification-badge--after--BackgroundColor: var(--pf-v5-c-page__header-tools--c-button--notification-badge--m-attention--after--BackgroundColor);
}

.pf-v5-c-page__sidebar {
  color: var(--pf-v5-global--Color--100);
  z-index: var(--pf-v5-c-page__sidebar--ZIndex);
  display: flex;
  flex-direction: column;
  grid-area: nav;
  grid-row-start: 2;
  grid-column-start: 1;
  width: var(--pf-v5-c-page__sidebar--Width);
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  background-color: var(--pf-v5-c-page__sidebar--BackgroundColor);
  transition: var(--pf-v5-c-page__sidebar--Transition);
  transform: translateX(var(--pf-v5-c-page__sidebar--TranslateX)) translateZ(var(--pf-v5-c-page__sidebar--TranslateZ));
}
:where(.pf-v5-m-dir-rtl, [dir=rtl]) .pf-v5-c-page__sidebar {
  transform: translateX(calc(var(--pf-v5-c-page__sidebar--TranslateX) * var(--pf-v5-global--inverse--multiplier))) translateZ(var(--pf-v5-c-page__sidebar--TranslateZ));
}

.pf-v5-c-page__sidebar.pf-m-expanded {
  --pf-v5-c-page__sidebar--TranslateX: var(--pf-v5-c-page__sidebar--m-expanded--TranslateX);
  box-shadow: var(--pf-v5-c-page__sidebar--BoxShadow);
}
@media screen and (min-width: 1200px) {
  .pf-v5-c-page__sidebar.pf-m-expanded {
    --pf-v5-c-page__sidebar--BoxShadow: none;
  }
}
.pf-v5-c-page__sidebar.pf-m-collapsed {
  max-width: 0;
  overflow: hidden;
}
.pf-v5-c-page__sidebar.pf-m-light {
  color: var(--pf-v5-global--Color--100);
  --pf-v5-c-page__sidebar--BackgroundColor: var(--pf-v5-c-page__sidebar--m-light--BackgroundColor);
  border-inline-end: var(--pf-v5-c-page__sidebar--m-light--BorderRightWidth) solid var(--pf-v5-c-page__sidebar--m-light--BorderRightColor);
}

.pf-v5-c-page__sidebar-body {
  padding-inline-start: var(--pf-v5-c-page__sidebar-body--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-page__sidebar-body--PaddingRight);
}
.pf-v5-c-page__sidebar-body:last-child {
  flex-grow: 1;
}
.pf-v5-c-page__sidebar-body.pf-m-menu {
  background-color: var(--pf-v5-global--palette--black-900);
  border-block-start: var(--pf-v5-c-page__sidebar-body--m-menu--BorderTopWidth) solid var(--pf-v5-c-page__sidebar-body--m-menu--BorderTopColor);
}
.pf-v5-c-page__sidebar-body.pf-m-menu + .pf-v5-c-page__sidebar-body.pf-m-menu {
  --pf-v5-c-page__sidebar-body--m-menu--BorderTopWidth: 0;
}
.pf-v5-c-page__sidebar-body.pf-m-menu .pf-v5-c-context-selector {
  --pf-v5-c-context-selector__toggle--BorderTopColor: transparent;
  --pf-v5-c-context-selector__toggle--BorderRightColor: transparent;
  --pf-v5-c-context-selector__toggle--BorderBottomColor: var(--pf-v5-c-page__sidebar-body--m-menu--c-context-selector--BorderBottomColor);
  --pf-v5-c-context-selector__toggle--BorderLeftColor: transparent;
  --pf-v5-c-context-selector__menu--Top: 100%;
  color: var(--pf-v5-global--Color--100);
  width: 100%;
}
.pf-v5-c-page__sidebar-body.pf-m-page-insets {
  --pf-v5-c-page__sidebar-body--PaddingRight: var(--pf-v5-c-page__sidebar-body--m-page-insets--PaddingRight);
  --pf-v5-c-page__sidebar-body--PaddingLeft: var(--pf-v5-c-page__sidebar-body--m-page-insets--PaddingLeft);
}
.pf-v5-c-page__sidebar-body.pf-m-inset-none {
  --pf-v5-c-page__sidebar-body--PaddingRight: 0;
  --pf-v5-c-page__sidebar-body--PaddingLeft: 0;
}
.pf-v5-c-page__sidebar-body.pf-m-fill {
  flex-grow: 1;
}
.pf-v5-c-page__sidebar-body.pf-m-no-fill {
  flex-grow: 0;
}

.pf-v5-c-page__main-nav.pf-m-limit-width,
.pf-v5-c-page__main-breadcrumb.pf-m-limit-width,
.pf-v5-c-page__main-tabs.pf-m-limit-width,
.pf-v5-c-page__main-section.pf-m-limit-width,
.pf-v5-c-page__main-wizard.pf-m-limit-width {
  display: flex;
  flex-direction: column;
  padding: 0;
}
.pf-v5-c-page__main-nav.pf-m-limit-width > .pf-v5-c-page__main-body,
.pf-v5-c-page__main-breadcrumb.pf-m-limit-width > .pf-v5-c-page__main-body,
.pf-v5-c-page__main-tabs.pf-m-limit-width > .pf-v5-c-page__main-body,
.pf-v5-c-page__main-section.pf-m-limit-width > .pf-v5-c-page__main-body,
.pf-v5-c-page__main-wizard.pf-m-limit-width > .pf-v5-c-page__main-body {
  flex: 1;
  max-width: var(--pf-v5-c-page--section--m-limit-width--MaxWidth);
}
.pf-v5-c-page__main-nav.pf-m-limit-width.pf-m-align-center,
.pf-v5-c-page__main-breadcrumb.pf-m-limit-width.pf-m-align-center,
.pf-v5-c-page__main-tabs.pf-m-limit-width.pf-m-align-center,
.pf-v5-c-page__main-section.pf-m-limit-width.pf-m-align-center,
.pf-v5-c-page__main-wizard.pf-m-limit-width.pf-m-align-center {
  align-items: center;
}
.pf-v5-c-page__main-nav.pf-m-limit-width.pf-m-align-center > .pf-v5-c-page__main-body,
.pf-v5-c-page__main-breadcrumb.pf-m-limit-width.pf-m-align-center > .pf-v5-c-page__main-body,
.pf-v5-c-page__main-tabs.pf-m-limit-width.pf-m-align-center > .pf-v5-c-page__main-body,
.pf-v5-c-page__main-section.pf-m-limit-width.pf-m-align-center > .pf-v5-c-page__main-body,
.pf-v5-c-page__main-wizard.pf-m-limit-width.pf-m-align-center > .pf-v5-c-page__main-body {
  width: 100%;
}

.pf-v5-c-page__main-nav,
.pf-v5-c-page__main-breadcrumb,
.pf-v5-c-page__main-tabs,
.pf-v5-c-page__main-section,
.pf-v5-c-page__main-wizard,
.pf-v5-c-page__main-group,
.pf-v5-c-page__main-subnav {
  flex-shrink: 0;
}
.pf-v5-c-page__main-nav.pf-m-overflow-scroll,
.pf-v5-c-page__main-breadcrumb.pf-m-overflow-scroll,
.pf-v5-c-page__main-tabs.pf-m-overflow-scroll,
.pf-v5-c-page__main-section.pf-m-overflow-scroll,
.pf-v5-c-page__main-wizard.pf-m-overflow-scroll,
.pf-v5-c-page__main-group.pf-m-overflow-scroll,
.pf-v5-c-page__main-subnav.pf-m-overflow-scroll {
  position: relative;
  flex-shrink: 1;
  overflow: auto;
}
.pf-v5-c-page__main-nav.pf-m-shadow-bottom,
.pf-v5-c-page__main-breadcrumb.pf-m-shadow-bottom,
.pf-v5-c-page__main-tabs.pf-m-shadow-bottom,
.pf-v5-c-page__main-section.pf-m-shadow-bottom,
.pf-v5-c-page__main-wizard.pf-m-shadow-bottom,
.pf-v5-c-page__main-group.pf-m-shadow-bottom,
.pf-v5-c-page__main-subnav.pf-m-shadow-bottom {
  z-index: var(--pf-v5-c-page--section--m-shadow-bottom--ZIndex);
  box-shadow: var(--pf-v5-c-page--section--m-shadow-bottom--BoxShadow);
}
.pf-v5-c-page__main-nav.pf-m-shadow-top,
.pf-v5-c-page__main-breadcrumb.pf-m-shadow-top,
.pf-v5-c-page__main-tabs.pf-m-shadow-top,
.pf-v5-c-page__main-section.pf-m-shadow-top,
.pf-v5-c-page__main-wizard.pf-m-shadow-top,
.pf-v5-c-page__main-group.pf-m-shadow-top,
.pf-v5-c-page__main-subnav.pf-m-shadow-top {
  z-index: var(--pf-v5-c-page--section--m-shadow-top--ZIndex);
  box-shadow: var(--pf-v5-c-page--section--m-shadow-top--BoxShadow);
}
.pf-v5-c-page__main-nav.pf-m-sticky-top,
.pf-v5-c-page__main-breadcrumb.pf-m-sticky-top,
.pf-v5-c-page__main-tabs.pf-m-sticky-top,
.pf-v5-c-page__main-section.pf-m-sticky-top,
.pf-v5-c-page__main-wizard.pf-m-sticky-top,
.pf-v5-c-page__main-group.pf-m-sticky-top,
.pf-v5-c-page__main-subnav.pf-m-sticky-top {
  position: sticky;
  inset-block-start: 0;
  z-index: var(--pf-v5-c-page--section--m-sticky-top--ZIndex);
  box-shadow: var(--pf-v5-c-page--section--m-sticky-top--BoxShadow);
}
.pf-v5-c-page__main-nav.pf-m-sticky-bottom,
.pf-v5-c-page__main-breadcrumb.pf-m-sticky-bottom,
.pf-v5-c-page__main-tabs.pf-m-sticky-bottom,
.pf-v5-c-page__main-section.pf-m-sticky-bottom,
.pf-v5-c-page__main-wizard.pf-m-sticky-bottom,
.pf-v5-c-page__main-group.pf-m-sticky-bottom,
.pf-v5-c-page__main-subnav.pf-m-sticky-bottom {
  position: sticky;
  inset-block-end: 0;
  z-index: var(--pf-v5-c-page--section--m-sticky-bottom--ZIndex);
  box-shadow: var(--pf-v5-c-page--section--m-sticky-bottom--BoxShadow);
}
@media (min-height: 0) {
  .pf-v5-c-page__main-nav.pf-m-sticky-top-on-sm-height,
  .pf-v5-c-page__main-breadcrumb.pf-m-sticky-top-on-sm-height,
  .pf-v5-c-page__main-tabs.pf-m-sticky-top-on-sm-height,
  .pf-v5-c-page__main-section.pf-m-sticky-top-on-sm-height,
  .pf-v5-c-page__main-wizard.pf-m-sticky-top-on-sm-height,
  .pf-v5-c-page__main-group.pf-m-sticky-top-on-sm-height,
  .pf-v5-c-page__main-subnav.pf-m-sticky-top-on-sm-height {
    position: sticky;
    inset-block-start: 0;
    z-index: var(--pf-v5-c-page--section--m-sticky-top--ZIndex);
    box-shadow: var(--pf-v5-c-page--section--m-sticky-top--BoxShadow);
  }
  .pf-v5-c-page__main-nav.pf-m-sticky-bottom-on-sm-height,
  .pf-v5-c-page__main-breadcrumb.pf-m-sticky-bottom-on-sm-height,
  .pf-v5-c-page__main-tabs.pf-m-sticky-bottom-on-sm-height,
  .pf-v5-c-page__main-section.pf-m-sticky-bottom-on-sm-height,
  .pf-v5-c-page__main-wizard.pf-m-sticky-bottom-on-sm-height,
  .pf-v5-c-page__main-group.pf-m-sticky-bottom-on-sm-height,
  .pf-v5-c-page__main-subnav.pf-m-sticky-bottom-on-sm-height {
    position: sticky;
    inset-block-end: 0;
    z-index: var(--pf-v5-c-page--section--m-sticky-bottom--ZIndex);
    box-shadow: var(--pf-v5-c-page--section--m-sticky-bottom--BoxShadow);
  }
}
@media (min-height: 40rem) {
  .pf-v5-c-page__main-nav.pf-m-sticky-top-on-md-height,
  .pf-v5-c-page__main-breadcrumb.pf-m-sticky-top-on-md-height,
  .pf-v5-c-page__main-tabs.pf-m-sticky-top-on-md-height,
  .pf-v5-c-page__main-section.pf-m-sticky-top-on-md-height,
  .pf-v5-c-page__main-wizard.pf-m-sticky-top-on-md-height,
  .pf-v5-c-page__main-group.pf-m-sticky-top-on-md-height,
  .pf-v5-c-page__main-subnav.pf-m-sticky-top-on-md-height {
    position: sticky;
    inset-block-start: 0;
    z-index: var(--pf-v5-c-page--section--m-sticky-top--ZIndex);
    box-shadow: var(--pf-v5-c-page--section--m-sticky-top--BoxShadow);
  }
  .pf-v5-c-page__main-nav.pf-m-sticky-bottom-on-md-height,
  .pf-v5-c-page__main-breadcrumb.pf-m-sticky-bottom-on-md-height,
  .pf-v5-c-page__main-tabs.pf-m-sticky-bottom-on-md-height,
  .pf-v5-c-page__main-section.pf-m-sticky-bottom-on-md-height,
  .pf-v5-c-page__main-wizard.pf-m-sticky-bottom-on-md-height,
  .pf-v5-c-page__main-group.pf-m-sticky-bottom-on-md-height,
  .pf-v5-c-page__main-subnav.pf-m-sticky-bottom-on-md-height {
    position: sticky;
    inset-block-end: 0;
    z-index: var(--pf-v5-c-page--section--m-sticky-bottom--ZIndex);
    box-shadow: var(--pf-v5-c-page--section--m-sticky-bottom--BoxShadow);
  }
}
@media (min-height: 48rem) {
  .pf-v5-c-page__main-nav.pf-m-sticky-top-on-lg-height,
  .pf-v5-c-page__main-breadcrumb.pf-m-sticky-top-on-lg-height,
  .pf-v5-c-page__main-tabs.pf-m-sticky-top-on-lg-height,
  .pf-v5-c-page__main-section.pf-m-sticky-top-on-lg-height,
  .pf-v5-c-page__main-wizard.pf-m-sticky-top-on-lg-height,
  .pf-v5-c-page__main-group.pf-m-sticky-top-on-lg-height,
  .pf-v5-c-page__main-subnav.pf-m-sticky-top-on-lg-height {
    position: sticky;
    inset-block-start: 0;
    z-index: var(--pf-v5-c-page--section--m-sticky-top--ZIndex);
    box-shadow: var(--pf-v5-c-page--section--m-sticky-top--BoxShadow);
  }
  .pf-v5-c-page__main-nav.pf-m-sticky-bottom-on-lg-height,
  .pf-v5-c-page__main-breadcrumb.pf-m-sticky-bottom-on-lg-height,
  .pf-v5-c-page__main-tabs.pf-m-sticky-bottom-on-lg-height,
  .pf-v5-c-page__main-section.pf-m-sticky-bottom-on-lg-height,
  .pf-v5-c-page__main-wizard.pf-m-sticky-bottom-on-lg-height,
  .pf-v5-c-page__main-group.pf-m-sticky-bottom-on-lg-height,
  .pf-v5-c-page__main-subnav.pf-m-sticky-bottom-on-lg-height {
    position: sticky;
    inset-block-end: 0;
    z-index: var(--pf-v5-c-page--section--m-sticky-bottom--ZIndex);
    box-shadow: var(--pf-v5-c-page--section--m-sticky-bottom--BoxShadow);
  }
}
@media (min-height: 60rem) {
  .pf-v5-c-page__main-nav.pf-m-sticky-top-on-xl-height,
  .pf-v5-c-page__main-breadcrumb.pf-m-sticky-top-on-xl-height,
  .pf-v5-c-page__main-tabs.pf-m-sticky-top-on-xl-height,
  .pf-v5-c-page__main-section.pf-m-sticky-top-on-xl-height,
  .pf-v5-c-page__main-wizard.pf-m-sticky-top-on-xl-height,
  .pf-v5-c-page__main-group.pf-m-sticky-top-on-xl-height,
  .pf-v5-c-page__main-subnav.pf-m-sticky-top-on-xl-height {
    position: sticky;
    inset-block-start: 0;
    z-index: var(--pf-v5-c-page--section--m-sticky-top--ZIndex);
    box-shadow: var(--pf-v5-c-page--section--m-sticky-top--BoxShadow);
  }
  .pf-v5-c-page__main-nav.pf-m-sticky-bottom-on-xl-height,
  .pf-v5-c-page__main-breadcrumb.pf-m-sticky-bottom-on-xl-height,
  .pf-v5-c-page__main-tabs.pf-m-sticky-bottom-on-xl-height,
  .pf-v5-c-page__main-section.pf-m-sticky-bottom-on-xl-height,
  .pf-v5-c-page__main-wizard.pf-m-sticky-bottom-on-xl-height,
  .pf-v5-c-page__main-group.pf-m-sticky-bottom-on-xl-height,
  .pf-v5-c-page__main-subnav.pf-m-sticky-bottom-on-xl-height {
    position: sticky;
    inset-block-end: 0;
    z-index: var(--pf-v5-c-page--section--m-sticky-bottom--ZIndex);
    box-shadow: var(--pf-v5-c-page--section--m-sticky-bottom--BoxShadow);
  }
}
@media (min-height: 80rem) {
  .pf-v5-c-page__main-nav.pf-m-sticky-top-on-2xl-height,
  .pf-v5-c-page__main-breadcrumb.pf-m-sticky-top-on-2xl-height,
  .pf-v5-c-page__main-tabs.pf-m-sticky-top-on-2xl-height,
  .pf-v5-c-page__main-section.pf-m-sticky-top-on-2xl-height,
  .pf-v5-c-page__main-wizard.pf-m-sticky-top-on-2xl-height,
  .pf-v5-c-page__main-group.pf-m-sticky-top-on-2xl-height,
  .pf-v5-c-page__main-subnav.pf-m-sticky-top-on-2xl-height {
    position: sticky;
    inset-block-start: 0;
    z-index: var(--pf-v5-c-page--section--m-sticky-top--ZIndex);
    box-shadow: var(--pf-v5-c-page--section--m-sticky-top--BoxShadow);
  }
  .pf-v5-c-page__main-nav.pf-m-sticky-bottom-on-2xl-height,
  .pf-v5-c-page__main-breadcrumb.pf-m-sticky-bottom-on-2xl-height,
  .pf-v5-c-page__main-tabs.pf-m-sticky-bottom-on-2xl-height,
  .pf-v5-c-page__main-section.pf-m-sticky-bottom-on-2xl-height,
  .pf-v5-c-page__main-wizard.pf-m-sticky-bottom-on-2xl-height,
  .pf-v5-c-page__main-group.pf-m-sticky-bottom-on-2xl-height,
  .pf-v5-c-page__main-subnav.pf-m-sticky-bottom-on-2xl-height {
    position: sticky;
    inset-block-end: 0;
    z-index: var(--pf-v5-c-page--section--m-sticky-bottom--ZIndex);
    box-shadow: var(--pf-v5-c-page--section--m-sticky-bottom--BoxShadow);
  }
}

.pf-v5-c-page__main,
.pf-v5-c-page__drawer {
  z-index: var(--pf-v5-c-page__main--ZIndex);
  grid-area: main;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.pf-v5-c-page__main:focus,
.pf-v5-c-page__drawer:focus {
  outline: 0;
}

.pf-v5-c-page__main,
.pf-v5-c-page__main-drawer,
.pf-v5-c-page__main-group {
  display: flex;
  flex-direction: column;
}

.pf-v5-c-page__main-nav {
  padding-block-start: var(--pf-v5-c-page__main-nav--PaddingTop);
  padding-inline-start: var(--pf-v5-c-page__main-nav--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-page__main-nav--PaddingRight);
  background-color: var(--pf-v5-c-page__main-nav--BackgroundColor);
}
.pf-v5-c-page__main-nav.pf-m-sticky-top, .pf-v5-c-page__main-group.pf-m-sticky-top .pf-v5-c-page__main-nav:last-child {
  padding-block-end: var(--pf-v5-c-page__main-nav--m-sticky-top--PaddingBottom);
}
@media (min-height: 0) {
  .pf-v5-c-page__main-nav.pf-m-sticky-top-on-sm-height, .pf-v5-c-page__main-group.pf-m-sticky-top-on-sm-height .pf-v5-c-page__main-nav:last-child {
    padding-block-end: var(--pf-v5-c-page__main-nav--m-sticky-top--PaddingBottom);
  }
}
@media (min-height: 40rem) {
  .pf-v5-c-page__main-nav.pf-m-sticky-top-on-md-height, .pf-v5-c-page__main-group.pf-m-sticky-top-on-md-height .pf-v5-c-page__main-nav:last-child {
    padding-block-end: var(--pf-v5-c-page__main-nav--m-sticky-top--PaddingBottom);
  }
}
@media (min-height: 48rem) {
  .pf-v5-c-page__main-nav.pf-m-sticky-top-on-lg-height, .pf-v5-c-page__main-group.pf-m-sticky-top-on-lg-height .pf-v5-c-page__main-nav:last-child {
    padding-block-end: var(--pf-v5-c-page__main-nav--m-sticky-top--PaddingBottom);
  }
}
@media (min-height: 60rem) {
  .pf-v5-c-page__main-nav.pf-m-sticky-top-on-xl-height, .pf-v5-c-page__main-group.pf-m-sticky-top-on-xl-height .pf-v5-c-page__main-nav:last-child {
    padding-block-end: var(--pf-v5-c-page__main-nav--m-sticky-top--PaddingBottom);
  }
}
@media (min-height: 80rem) {
  .pf-v5-c-page__main-nav.pf-m-sticky-top-on-2xl-height, .pf-v5-c-page__main-group.pf-m-sticky-top-on-2xl-height .pf-v5-c-page__main-nav:last-child {
    padding-block-end: var(--pf-v5-c-page__main-nav--m-sticky-top--PaddingBottom);
  }
}

.pf-v5-c-page__main-subnav {
  background-color: var(--pf-v5-c-page__main-subnav--BackgroundColor);
  border-block-start: var(--pf-v5-c-page__main-subnav--BorderTopWidth) solid var(--pf-v5-c-page__main-subnav--BorderTopColor);
  border-inline-start: var(--pf-v5-c-page__main-subnav--BorderLeftWidth) solid var(--pf-v5-c-page__main-subnav--BorderLeftColor);
}
@media screen and (min-width: 1200px) {
  .pf-v5-c-page__sidebar ~ .pf-v5-c-page__main {
    --pf-v5-c-page__main-subnav--BorderLeftWidth: var(--pf-v5-c-page__sidebar--main__main-subnav--BorderLeftWidth);
  }
}
.pf-v5-c-page__sidebar.pf-m-collapsed ~ .pf-v5-c-page__main {
  --pf-v5-c-page__main-subnav--BorderLeftWidth: var(--pf-v5-c-page__sidebar--m-collapsed--main__main-subnav--BorderLeftWidth);
}

.pf-v5-c-page__main-breadcrumb {
  padding-block-start: var(--pf-v5-c-page__main-breadcrumb--PaddingTop);
  padding-block-end: var(--pf-v5-c-page__main-breadcrumb--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-page__main-breadcrumb--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-page__main-breadcrumb--PaddingRight);
  background-color: var(--pf-v5-c-page__main-breadcrumb--BackgroundColor);
}
.pf-v5-c-page__main-breadcrumb + .pf-v5-c-page__main-section {
  --pf-v5-c-page__main-section--PaddingTop: var(--pf-v5-c-page__main-breadcrumb--main-section--PaddingTop);
}
.pf-v5-c-page__main-breadcrumb.pf-m-sticky-top, .pf-v5-c-page__main-group.pf-m-sticky-top .pf-v5-c-page__main-breadcrumb:last-child {
  --pf-v5-c-page__main-breadcrumb--PaddingBottom: var(--pf-v5-c-page__main-breadcrumb--m-sticky-top--PaddingBottom);
}
@media (min-height: 0) {
  .pf-v5-c-page__main-breadcrumb.pf-m-sticky-top-on-sm-height, .pf-v5-c-page__main-group.pf-m-sticky-top-on-sm-height .pf-v5-c-page__main-breadcrumb:last-child {
    --pf-v5-c-page__main-breadcrumb--PaddingBottom: var(--pf-v5-c-page__main-breadcrumb--m-sticky-top--PaddingBottom);
  }
}
@media (min-height: 40rem) {
  .pf-v5-c-page__main-breadcrumb.pf-m-sticky-top-on-md-height, .pf-v5-c-page__main-group.pf-m-sticky-top-on-md-height .pf-v5-c-page__main-breadcrumb:last-child {
    --pf-v5-c-page__main-breadcrumb--PaddingBottom: var(--pf-v5-c-page__main-breadcrumb--m-sticky-top--PaddingBottom);
  }
}
@media (min-height: 48rem) {
  .pf-v5-c-page__main-breadcrumb.pf-m-sticky-top-on-lg-height, .pf-v5-c-page__main-group.pf-m-sticky-top-on-lg-height .pf-v5-c-page__main-breadcrumb:last-child {
    --pf-v5-c-page__main-breadcrumb--PaddingBottom: var(--pf-v5-c-page__main-breadcrumb--m-sticky-top--PaddingBottom);
  }
}
@media (min-height: 60rem) {
  .pf-v5-c-page__main-breadcrumb.pf-m-sticky-top-on-xl-height, .pf-v5-c-page__main-group.pf-m-sticky-top-on-xl-height .pf-v5-c-page__main-breadcrumb:last-child {
    --pf-v5-c-page__main-breadcrumb--PaddingBottom: var(--pf-v5-c-page__main-breadcrumb--m-sticky-top--PaddingBottom);
  }
}
@media (min-height: 80rem) {
  .pf-v5-c-page__main-breadcrumb.pf-m-sticky-top-on-2xl-height, .pf-v5-c-page__main-group.pf-m-sticky-top-on-2xl-height .pf-v5-c-page__main-breadcrumb:last-child {
    --pf-v5-c-page__main-breadcrumb--PaddingBottom: var(--pf-v5-c-page__main-breadcrumb--m-sticky-top--PaddingBottom);
  }
}

.pf-v5-c-page__main-tabs {
  padding-block-start: var(--pf-v5-c-page__main-tabs--PaddingTop);
  padding-block-end: var(--pf-v5-c-page__main-tabs--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-page__main-tabs--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-page__main-tabs--PaddingRight);
  background-color: var(--pf-v5-c-page__main-tabs--BackgroundColor);
}
.pf-v5-c-page__main-nav + .pf-v5-c-page__main-tabs {
  --pf-v5-c-page__main-tabs--PaddingTop: var(--pf-v5-c-page__main-nav--page__main-tabs--PaddingTop);
}
.pf-v5-c-page__main-breadcrumb + .pf-v5-c-page__main-tabs {
  --pf-v5-c-page__main-tabs--PaddingTop: var(--pf-v5-c-page__main-breadcrumb--page__main-tabs--PaddingTop);
}

.pf-v5-c-page__main-section:last-child, .pf-v5-c-page__main-section:only-child, .pf-v5-c-page__main-section.pf-m-fill,
.pf-v5-c-page__main-group:last-child,
.pf-v5-c-page__main-group:only-child,
.pf-v5-c-page__main-group.pf-m-fill,
.pf-v5-c-page__main-wizard:last-child,
.pf-v5-c-page__main-wizard:only-child,
.pf-v5-c-page__main-wizard.pf-m-fill {
  flex-grow: 1;
}
.pf-v5-c-page__main-section.pf-m-no-fill,
.pf-v5-c-page__main-group.pf-m-no-fill,
.pf-v5-c-page__main-wizard.pf-m-no-fill {
  flex-grow: 0;
}

.pf-v5-c-page__main-section {
  padding-block-start: var(--pf-v5-c-page__main-section--PaddingTop);
  padding-block-end: var(--pf-v5-c-page__main-section--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-page__main-section--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-page__main-section--PaddingRight);
  background-color: var(--pf-v5-c-page__main-section--BackgroundColor);
}
.pf-v5-c-page__main-section.pf-m-light {
  --pf-v5-c-page__main-section--BackgroundColor: var(--pf-v5-c-page__main-section--m-light--BackgroundColor);
}
.pf-v5-c-page__main-section.pf-m-light-100 {
  --pf-v5-c-page__main-section--BackgroundColor: var(--pf-v5-c-page__main-section--m-light-100--BackgroundColor);
}
.pf-v5-c-page__main-section[class*=pf-m-dark-] {
  color: var(--pf-v5-global--Color--100);
}
.pf-v5-c-page__main-section.pf-m-dark-100 {
  --pf-v5-c-page__main-section--BackgroundColor: var(--pf-v5-c-page__main-section--m-dark-100--BackgroundColor);
}
.pf-v5-c-page__main-section.pf-m-dark-200 {
  --pf-v5-c-page__main-section--BackgroundColor: var(--pf-v5-c-page__main-section--m-dark-200--BackgroundColor);
}
.pf-v5-c-page__main-section.pf-m-padding {
  padding-block-start: var(--pf-v5-c-page__main-section--PaddingTop);
  padding-block-end: var(--pf-v5-c-page__main-section--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-page__main-section--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-page__main-section--PaddingRight);
}
.pf-v5-c-page__main-section.pf-m-padding.pf-m-limit-width {
  padding: 0;
}
.pf-v5-c-page__main-section.pf-m-padding.pf-m-limit-width .pf-v5-c-page__main-body {
  padding-block-start: var(--pf-v5-c-page__main-section--PaddingTop);
  padding-block-end: var(--pf-v5-c-page__main-section--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-page__main-section--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-page__main-section--PaddingRight);
}
.pf-v5-c-page__main-section.pf-m-no-padding, .pf-v5-c-page__main-section.pf-m-no-padding.pf-m-limit-width .pf-v5-c-page__main-body {
  padding: 0;
}
@media (min-width: 576px) {
  .pf-v5-c-page__main-section.pf-m-padding-on-sm {
    padding-block-start: var(--pf-v5-c-page__main-section--PaddingTop);
    padding-block-end: var(--pf-v5-c-page__main-section--PaddingBottom);
    padding-inline-start: var(--pf-v5-c-page__main-section--PaddingLeft);
    padding-inline-end: var(--pf-v5-c-page__main-section--PaddingRight);
  }
  .pf-v5-c-page__main-section.pf-m-padding-on-sm.pf-m-limit-width {
    padding: 0;
  }
  .pf-v5-c-page__main-section.pf-m-padding-on-sm.pf-m-limit-width .pf-v5-c-page__main-body {
    padding-block-start: var(--pf-v5-c-page__main-section--PaddingTop);
    padding-block-end: var(--pf-v5-c-page__main-section--PaddingBottom);
    padding-inline-start: var(--pf-v5-c-page__main-section--PaddingLeft);
    padding-inline-end: var(--pf-v5-c-page__main-section--PaddingRight);
  }
  .pf-v5-c-page__main-section.pf-m-no-padding-on-sm, .pf-v5-c-page__main-section.pf-m-no-padding-on-sm.pf-m-limit-width .pf-v5-c-page__main-body {
    padding: 0;
  }
}
@media (min-width: 768px) {
  .pf-v5-c-page__main-section.pf-m-padding-on-md {
    padding-block-start: var(--pf-v5-c-page__main-section--PaddingTop);
    padding-block-end: var(--pf-v5-c-page__main-section--PaddingBottom);
    padding-inline-start: var(--pf-v5-c-page__main-section--PaddingLeft);
    padding-inline-end: var(--pf-v5-c-page__main-section--PaddingRight);
  }
  .pf-v5-c-page__main-section.pf-m-padding-on-md.pf-m-limit-width {
    padding: 0;
  }
  .pf-v5-c-page__main-section.pf-m-padding-on-md.pf-m-limit-width .pf-v5-c-page__main-body {
    padding-block-start: var(--pf-v5-c-page__main-section--PaddingTop);
    padding-block-end: var(--pf-v5-c-page__main-section--PaddingBottom);
    padding-inline-start: var(--pf-v5-c-page__main-section--PaddingLeft);
    padding-inline-end: var(--pf-v5-c-page__main-section--PaddingRight);
  }
  .pf-v5-c-page__main-section.pf-m-no-padding-on-md, .pf-v5-c-page__main-section.pf-m-no-padding-on-md.pf-m-limit-width .pf-v5-c-page__main-body {
    padding: 0;
  }
}
@media (min-width: 992px) {
  .pf-v5-c-page__main-section.pf-m-padding-on-lg {
    padding-block-start: var(--pf-v5-c-page__main-section--PaddingTop);
    padding-block-end: var(--pf-v5-c-page__main-section--PaddingBottom);
    padding-inline-start: var(--pf-v5-c-page__main-section--PaddingLeft);
    padding-inline-end: var(--pf-v5-c-page__main-section--PaddingRight);
  }
  .pf-v5-c-page__main-section.pf-m-padding-on-lg.pf-m-limit-width {
    padding: 0;
  }
  .pf-v5-c-page__main-section.pf-m-padding-on-lg.pf-m-limit-width .pf-v5-c-page__main-body {
    padding-block-start: var(--pf-v5-c-page__main-section--PaddingTop);
    padding-block-end: var(--pf-v5-c-page__main-section--PaddingBottom);
    padding-inline-start: var(--pf-v5-c-page__main-section--PaddingLeft);
    padding-inline-end: var(--pf-v5-c-page__main-section--PaddingRight);
  }
  .pf-v5-c-page__main-section.pf-m-no-padding-on-lg, .pf-v5-c-page__main-section.pf-m-no-padding-on-lg.pf-m-limit-width .pf-v5-c-page__main-body {
    padding: 0;
  }
}
@media (min-width: 1200px) {
  .pf-v5-c-page__main-section.pf-m-padding-on-xl {
    padding-block-start: var(--pf-v5-c-page__main-section--PaddingTop);
    padding-block-end: var(--pf-v5-c-page__main-section--PaddingBottom);
    padding-inline-start: var(--pf-v5-c-page__main-section--PaddingLeft);
    padding-inline-end: var(--pf-v5-c-page__main-section--PaddingRight);
  }
  .pf-v5-c-page__main-section.pf-m-padding-on-xl.pf-m-limit-width {
    padding: 0;
  }
  .pf-v5-c-page__main-section.pf-m-padding-on-xl.pf-m-limit-width .pf-v5-c-page__main-body {
    padding-block-start: var(--pf-v5-c-page__main-section--PaddingTop);
    padding-block-end: var(--pf-v5-c-page__main-section--PaddingBottom);
    padding-inline-start: var(--pf-v5-c-page__main-section--PaddingLeft);
    padding-inline-end: var(--pf-v5-c-page__main-section--PaddingRight);
  }
  .pf-v5-c-page__main-section.pf-m-no-padding-on-xl, .pf-v5-c-page__main-section.pf-m-no-padding-on-xl.pf-m-limit-width .pf-v5-c-page__main-body {
    padding: 0;
  }
}
@media (min-width: 1450px) {
  .pf-v5-c-page__main-section.pf-m-padding-on-2xl {
    padding-block-start: var(--pf-v5-c-page__main-section--PaddingTop);
    padding-block-end: var(--pf-v5-c-page__main-section--PaddingBottom);
    padding-inline-start: var(--pf-v5-c-page__main-section--PaddingLeft);
    padding-inline-end: var(--pf-v5-c-page__main-section--PaddingRight);
  }
  .pf-v5-c-page__main-section.pf-m-padding-on-2xl.pf-m-limit-width {
    padding: 0;
  }
  .pf-v5-c-page__main-section.pf-m-padding-on-2xl.pf-m-limit-width .pf-v5-c-page__main-body {
    padding-block-start: var(--pf-v5-c-page__main-section--PaddingTop);
    padding-block-end: var(--pf-v5-c-page__main-section--PaddingBottom);
    padding-inline-start: var(--pf-v5-c-page__main-section--PaddingLeft);
    padding-inline-end: var(--pf-v5-c-page__main-section--PaddingRight);
  }
  .pf-v5-c-page__main-section.pf-m-no-padding-on-2xl, .pf-v5-c-page__main-section.pf-m-no-padding-on-2xl.pf-m-limit-width .pf-v5-c-page__main-body {
    padding: 0;
  }
}

.pf-v5-c-page__main-wizard {
  flex: 1 1;
  min-height: 0;
  background-color: var(--pf-v5-c-page__main-wizard--BackgroundColor);
  border-block-start: var(--pf-v5-c-page__main-wizard--BorderTopWidth) solid var(--pf-v5-c-page__main-wizard--BorderTopColor);
}
.pf-v5-c-page__main-wizard:first-child {
  --pf-v5-c-page__main-wizard--BorderTopWidth: 0;
}
.pf-v5-c-page__main-wizard.pf-m-light-200 {
  --pf-v5-c-page__main-wizard--BackgroundColor: var(--pf-v5-c-page__main-wizard--m-light-200--BackgroundColor);
}

.pf-v5-c-page__main-wizard .pf-v5-c-page__main-body {
  min-height: 0;
}

.pf-v5-c-page__main-group {
  flex-shrink: 0;
}

.pf-v5-c-page__main-nav .pf-v5-c-page__main-body {
  padding-block-start: var(--pf-v5-c-page__main-nav--PaddingTop);
  padding-inline-start: var(--pf-v5-c-page__main-nav--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-page__main-nav--PaddingRight);
}
.pf-v5-c-page__main-breadcrumb .pf-v5-c-page__main-body {
  padding-block-start: var(--pf-v5-c-page__main-breadcrumb--PaddingTop);
  padding-block-end: var(--pf-v5-c-page__main-breadcrumb--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-page__main-breadcrumb--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-page__main-breadcrumb--PaddingRight);
}
.pf-v5-c-page__main-section .pf-v5-c-page__main-body {
  padding-block-start: var(--pf-v5-c-page__main-section--PaddingTop);
  padding-block-end: var(--pf-v5-c-page__main-section--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-page__main-section--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-page__main-section--PaddingRight);
}
.pf-v5-c-page__main-tabs .pf-v5-c-page__main-body {
  padding-block-start: var(--pf-v5-c-page__main-tabs--PaddingTop);
  padding-block-end: var(--pf-v5-c-page__main-tabs--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-page__main-tabs--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-page__main-tabs--PaddingRight);
}

.pf-v5-c-page__drawer {
  grid-area: main;
}
.pf-v5-c-page__drawer > .pf-v5-c-drawer {
  flex: 1 0 auto;
}

:where(.pf-v5-theme-dark) .pf-v5-c-page__sidebar-body.pf-m-menu .pf-v5-c-context-selector .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__main-section[class*=pf-m-dark-] .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__header .pf-v5-c-button {
  --pf-v5-c-button--m-primary--BackgroundColor: var(--pf-v5-global--primary-color--300);
}

:where(.pf-v5-theme-dark):root {
  --pf-v5-c-page__main-section--BackgroundColor: var(--pf-v5-global--BackgroundColor--200);
  --pf-v5-c-page__header-tools--c-button--m-selected--before--BackgroundColor: var(--pf-v5-global--BackgroundColor--300);
  --pf-v5-c-page__sidebar--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-page__header--BackgroundColor: var(--pf-v5-global--palette--black-1000);
}
:where(.pf-v5-theme-dark) .pf-v5-c-page__header-tools-item.pf-m-selected .pf-v5-c-button {
  color: var(--pf-v5-global--Color--100);
}
:where(.pf-v5-theme-dark) .pf-v5-c-page__header-tools-item.pf-m-selected .pf-v5-c-button .pf-v5-c-notification-badge:hover::before {
  background-color: var(--pf-v5-c-page__header-tools--c-button--m-selected--before--BackgroundColor);
}
:where(.pf-v5-theme-dark) .pf-v5-c-page__sidebar {
  border-inline-end: var(--pf-v5-global--BorderWidth--sm) solid var(--pf-v5-global--BorderColor--100);
}
:where(.pf-v5-theme-dark) .pf-v5-c-page__main-nav.pf-m-sticky-top, :where(.pf-v5-theme-dark) .pf-v5-c-page__main-nav.pf-m-sticky-bottom {
  --pf-v5-c-page__main-nav--BackgroundColor: var(--pf-v5-global--BackgroundColor--300);
}
:where(.pf-v5-theme-dark) .pf-v5-c-page__main-breadcrumb.pf-m-sticky-top, :where(.pf-v5-theme-dark) .pf-v5-c-page__main-breadcrumb.pf-m-sticky-bottom {
  --pf-v5-c-page__main-breadcrumb--BackgroundColor: var(--pf-v5-global--BackgroundColor--300);
}
:where(.pf-v5-theme-dark) .pf-v5-c-page__main-group.pf-m-sticky-top, :where(.pf-v5-theme-dark) .pf-v5-c-page__main-group.pf-m-sticky-bottom {
  --pf-v5-c-page__main-section--m-light--BackgroundColor: var(--pf-v5-global--BackgroundColor--300);
  --pf-v5-c-page__main-section--BackgroundColor: var(--pf-v5-global--BackgroundColor--300);
  --pf-v5-c-page__main-breadcrumb--BackgroundColor: var(--pf-v5-global--BackgroundColor--300);
  --pf-v5-c-page__main-nav--BackgroundColor: var(--pf-v5-global--BackgroundColor--300);
}
:where(.pf-v5-theme-dark) .pf-v5-c-page__main-section.pf-m-sticky-top, :where(.pf-v5-theme-dark) .pf-v5-c-page__main-section.pf-m-sticky-bottom {
  --pf-v5-c-page__main-section--BackgroundColor: var(--pf-v5-global--BackgroundColor--300);
}
:where(.pf-v5-theme-dark) .pf-v5-c-page__sidebar-body.pf-m-menu .pf-v5-c-context-selector,
:where(.pf-v5-theme-dark) .pf-v5-c-page__main-section[class*=pf-m-dark-],
:where(.pf-v5-theme-dark) .pf-v5-c-page__header {
  color: var(--pf-v5-global--Color--100);
}