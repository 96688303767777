.pf-v5-c-chip-group {
  --pf-v5-global--Color--100: var(--pf-v5-global--Color--dark-100);
  --pf-v5-global--Color--200: var(--pf-v5-global--Color--dark-200);
  --pf-v5-global--BorderColor--100: var(--pf-v5-global--BorderColor--dark-100);
  --pf-v5-global--primary-color--100: var(--pf-v5-global--primary-color--dark-100);
  --pf-v5-global--link--Color: var(--pf-v5-global--link--Color--dark);
  --pf-v5-global--link--Color--hover: var(--pf-v5-global--link--Color--dark--hover);
  --pf-v5-global--BackgroundColor--100: var(--pf-v5-global--BackgroundColor--light-100);
  --pf-v5-global--icon--Color--light: var(--pf-v5-global--icon--Color--light--dark);
  --pf-v5-global--icon--Color--dark: var(--pf-v5-global--icon--Color--dark--dark);
}

.pf-v5-c-chip-group {
  color: var(--pf-v5-global--Color--100);
  --pf-v5-c-chip-group--PaddingTop: 0;
  --pf-v5-c-chip-group--PaddingRight: 0;
  --pf-v5-c-chip-group--PaddingBottom: 0;
  --pf-v5-c-chip-group--PaddingLeft: 0;
  --pf-v5-c-chip-group--RowGap: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-chip-group--ColumnGap: 0;
  --pf-v5-c-chip-group__main--RowGap: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-chip-group__main--ColumnGap: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-chip-group__list--RowGap: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-chip-group__list--ColumnGap: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-chip-group--m-category--PaddingTop: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-chip-group--m-category--PaddingRight: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-chip-group--m-category--PaddingBottom: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-chip-group--m-category--PaddingLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-chip-group--m-category--BorderRadius: var(--pf-v5-global--BorderRadius--sm);
  --pf-v5-c-chip-group--m-category--BackgroundColor: var(--pf-v5-global--BackgroundColor--200);
  --pf-v5-c-chip-group__label--FontSize: var(--pf-v5-global--FontSize--sm);
  --pf-v5-c-chip-group__label--MaxWidth: 18ch;
  --pf-v5-c-chip-group__close--MarginTop: calc(var(--pf-v5-global--spacer--xs) * -1);
  --pf-v5-c-chip-group__close--MarginBottom: calc(var(--pf-v5-global--spacer--xs) * -1);
  row-gap: var(--pf-v5-c-chip-group--RowGap);
  column-gap: var(--pf-v5-c-chip-group--ColumnGap);
  max-width: 100%;
  padding-block-start: var(--pf-v5-c-chip-group--PaddingTop);
  padding-block-end: var(--pf-v5-c-chip-group--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-chip-group--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-chip-group--PaddingRight);
}
.pf-v5-c-chip-group.pf-m-category {
  --pf-v5-c-chip-group--PaddingTop: var(--pf-v5-c-chip-group--m-category--PaddingTop);
  --pf-v5-c-chip-group--PaddingRight: var(--pf-v5-c-chip-group--m-category--PaddingRight);
  --pf-v5-c-chip-group--PaddingBottom: var(--pf-v5-c-chip-group--m-category--PaddingBottom);
  --pf-v5-c-chip-group--PaddingLeft: var(--pf-v5-c-chip-group--m-category--PaddingLeft);
  background-color: var(--pf-v5-c-chip-group--m-category--BackgroundColor);
  border-radius: var(--pf-v5-c-chip-group--m-category--BorderRadius);
}

.pf-v5-c-chip-group__main {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  row-gap: var(--pf-v5-c-chip-group__main--RowGap);
  column-gap: var(--pf-v5-c-chip-group__main--ColumnGap);
  align-items: baseline;
  min-width: 0;
}

.pf-v5-c-chip-group,
.pf-v5-c-chip-group__list {
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  min-width: 0;
}

.pf-v5-c-chip-group__list {
  row-gap: var(--pf-v5-c-chip-group__list--RowGap);
  column-gap: var(--pf-v5-c-chip-group__list--ColumnGap);
}

.pf-v5-c-chip-group__list-item {
  display: inline-flex;
  min-width: 0;
}

.pf-v5-c-chip-group__label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: var(--pf-v5-c-chip-group__label--MaxWidth);
  font-size: var(--pf-v5-c-chip-group__label--FontSize);
}

.pf-v5-c-chip-group__close {
  display: flex;
  align-self: flex-start;
  margin-block-start: var(--pf-v5-c-chip-group__close--MarginTop);
  margin-block-end: var(--pf-v5-c-chip-group__close--MarginBottom);
}