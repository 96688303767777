.pf-v5-c-popover {
  --pf-v5-c-popover--FontSize: var(--pf-v5-global--FontSize--sm);
  --pf-v5-c-popover--MinWidth: calc(var(--pf-v5-c-popover__content--PaddingLeft) + var(--pf-v5-c-popover__content--PaddingRight) + 18.75rem);
  --pf-v5-c-popover--MaxWidth: calc(var(--pf-v5-c-popover__content--PaddingLeft) + var(--pf-v5-c-popover__content--PaddingRight) + 18.75rem);
  --pf-v5-c-popover--BoxShadow: var(--pf-v5-global--BoxShadow--lg);
  --pf-v5-c-popover--m-danger__title-icon--Color: var(--pf-v5-global--danger-color--100);
  --pf-v5-c-popover--m-warning__title-icon--Color: var(--pf-v5-global--warning-color--100);
  --pf-v5-c-popover--m-success__title-icon--Color: var(--pf-v5-global--success-color--100);
  --pf-v5-c-popover--m-info__title-icon--Color: var(--pf-v5-global--info-color--100);
  --pf-v5-c-popover--m-custom__title-icon--Color: var(--pf-v5-global--custom-color--200);
  --pf-v5-c-popover--m-danger__title-text--Color: var(--pf-v5-global--danger-color--200);
  --pf-v5-c-popover--m-warning__title-text--Color: var(--pf-v5-global--warning-color--200);
  --pf-v5-c-popover--m-success__title-text--Color: var(--pf-v5-global--success-color--200);
  --pf-v5-c-popover--m-info__title-text--Color: var(--pf-v5-global--info-color--200);
  --pf-v5-c-popover--m-custom__title-text--Color: var(--pf-v5-global--custom-color--300);
  --pf-v5-c-popover__content--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-popover__content--PaddingTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-popover__content--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-popover__content--PaddingBottom: var(--pf-v5-global--spacer--md);
  --pf-v5-c-popover__content--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-popover__arrow--Width: var(--pf-v5-global--arrow--width-lg);
  --pf-v5-c-popover__arrow--Height: var(--pf-v5-global--arrow--width-lg);
  --pf-v5-c-popover__arrow--BoxShadow: var(--pf-v5-global--BoxShadow--lg);
  --pf-v5-c-popover__arrow--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-popover__arrow--m-top--TranslateX: -50%;
  --pf-v5-c-popover__arrow--m-top--TranslateY: 50%;
  --pf-v5-c-popover__arrow--m-top--Rotate: 45deg;
  --pf-v5-c-popover__arrow--m-right--TranslateX: -50%;
  --pf-v5-c-popover__arrow--m-right--TranslateY: -50%;
  --pf-v5-c-popover__arrow--m-right--Rotate: 45deg;
  --pf-v5-c-popover__arrow--m-bottom--TranslateX: -50%;
  --pf-v5-c-popover__arrow--m-bottom--TranslateY: -50%;
  --pf-v5-c-popover__arrow--m-bottom--Rotate: 45deg;
  --pf-v5-c-popover__arrow--m-left--TranslateX: 50%;
  --pf-v5-c-popover__arrow--m-left--TranslateY: -50%;
  --pf-v5-c-popover__arrow--m-left--Rotate: 45deg;
  --pf-v5-c-popover__close--Top: calc(var(--pf-v5-c-popover__content--PaddingTop) - var(--pf-v5-global--spacer--form-element));
  --pf-v5-c-popover__close--Right: calc(var(--pf-v5-c-popover__content--PaddingRight) - var(--pf-v5-global--spacer--md));
  --pf-v5-c-popover__close--sibling--PaddingRight: var(--pf-v5-global--spacer--2xl);
  --pf-v5-c-popover__header--MarginBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-popover__title-text--LineHeight: var(--pf-v5-global--LineHeight--md);
  --pf-v5-c-popover__title-text--FontFamily: var(--pf-v5-global--FontFamily--heading);
  --pf-v5-c-popover__title-text--FontSize: var(--pf-v5-global--FontSize--md);
  --pf-v5-c-popover__title-icon--MarginRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-popover__title-icon--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-popover__title-icon--FontSize: var(--pf-v5-global--FontSize--md);
  --pf-v5-c-popover__footer--MarginTop: var(--pf-v5-global--spacer--md);
  position: relative;
  min-width: var(--pf-v5-c-popover--MinWidth);
  max-width: var(--pf-v5-c-popover--MaxWidth);
  font-size: var(--pf-v5-c-popover--FontSize);
  box-shadow: var(--pf-v5-c-popover--BoxShadow);
}
.pf-v5-c-popover.pf-m-no-padding {
  --pf-v5-c-popover__content--PaddingTop: 0px;
  --pf-v5-c-popover__content--PaddingRight: 0px;
  --pf-v5-c-popover__content--PaddingBottom: 0px;
  --pf-v5-c-popover__content--PaddingLeft: 0px;
}
.pf-v5-c-popover.pf-m-width-auto {
  --pf-v5-c-popover--MinWidth: auto;
  --pf-v5-c-popover--MaxWidth: none;
}
.pf-v5-c-popover:is(.pf-m-top,
.pf-m-top-left,
.pf-m-top-right) {
  --pf-v5-c-popover__arrow--Bottom: var(--pf-v5-c-popover--m-top--Bottom, 0);
  --pf-v5-c-popover__arrow--Left: var(--pf-v5-c-popover--m-top--Left, 50%);
  --pf-v5-c-popover__arrow--TranslateX: var(--pf-v5-c-popover__arrow--m-top--TranslateX);
  --pf-v5-c-popover__arrow--TranslateY: var(--pf-v5-c-popover__arrow--m-top--TranslateY);
  --pf-v5-c-popover__arrow--Rotate: var(--pf-v5-c-popover__arrow--m-top--Rotate);
}
.pf-v5-c-popover:is(.pf-m-bottom,
.pf-m-bottom-left,
.pf-m-bottom-right) {
  --pf-v5-c-popover__arrow--Top: var(--pf-v5-c-popover--m-bottom--Top, 0);
  --pf-v5-c-popover__arrow--Left: var(--pf-v5-c-popover--m-bottom--Left, 50%);
  --pf-v5-c-popover__arrow--TranslateX: var(--pf-v5-c-popover__arrow--m-bottom--TranslateX);
  --pf-v5-c-popover__arrow--TranslateY: var(--pf-v5-c-popover__arrow--m-bottom--TranslateY);
  --pf-v5-c-popover__arrow--Rotate: var(--pf-v5-c-popover__arrow--m-bottom--Rotate);
}
.pf-v5-c-popover:is(.pf-m-left,
.pf-m-left-top,
.pf-m-left-bottom) {
  --pf-v5-c-popover__arrow--Top: var(--pf-v5-c-popover--m-left--Top, 50%);
  --pf-v5-c-popover__arrow--Right: var(--pf-v5-c-popover--m-left--Right, 0);
  --pf-v5-c-popover__arrow--TranslateX: var(--pf-v5-c-popover__arrow--m-left--TranslateX);
  --pf-v5-c-popover__arrow--TranslateY: var(--pf-v5-c-popover__arrow--m-left--TranslateY);
  --pf-v5-c-popover__arrow--Rotate: var(--pf-v5-c-popover__arrow--m-left--Rotate);
}
.pf-v5-c-popover:is(.pf-m-right,
.pf-m-right-top,
.pf-m-right-bottom) {
  --pf-v5-c-popover__arrow--Top: var(--pf-v5-c-popover--m-right--Top, 50%);
  --pf-v5-c-popover__arrow--Left: var(--pf-v5-c-popover--m-right--Left, 0);
  --pf-v5-c-popover__arrow--TranslateX: var(--pf-v5-c-popover__arrow--m-right--TranslateX);
  --pf-v5-c-popover__arrow--TranslateY: var(--pf-v5-c-popover__arrow--m-right--TranslateY);
  --pf-v5-c-popover__arrow--Rotate: var(--pf-v5-c-popover__arrow--m-right--Rotate);
}
.pf-v5-c-popover:is(.pf-m-left-top,
.pf-m-right-top) {
  --pf-v5-c-popover__arrow--Top: 0;
  --pf-v5-c-popover__arrow--TranslateY: var(--pf-v5-c-popover__arrow--m-top--TranslateY);
}
.pf-v5-c-popover:is(.pf-m-left-bottom,
.pf-m-right-bottom) {
  --pf-v5-c-popover__arrow--Top: auto;
  --pf-v5-c-popover__arrow--Bottom: 0;
}
.pf-v5-c-popover:is(.pf-m-top-left,
.pf-m-bottom-left) {
  --pf-v5-c-popover__arrow--Left: 0;
  --pf-v5-c-popover__arrow--TranslateX: var(--pf-v5-c-popover__arrow--m-left--TranslateX);
}
.pf-v5-c-popover:is(.pf-m-top-right,
.pf-m-bottom-right) {
  --pf-v5-c-popover__arrow--Right: 0;
  --pf-v5-c-popover__arrow--Left: auto;
}
.pf-v5-c-popover.pf-m-danger {
  --pf-v5-c-popover__title-icon--Color: var(--pf-v5-c-popover--m-danger__title-icon--Color);
  --pf-v5-c-popover__title-text--Color: var(--pf-v5-c-popover--m-danger__title-text--Color);
}
.pf-v5-c-popover.pf-m-warning {
  --pf-v5-c-popover__title-icon--Color: var(--pf-v5-c-popover--m-warning__title-icon--Color);
  --pf-v5-c-popover__title-text--Color: var(--pf-v5-c-popover--m-warning__title-text--Color);
}
.pf-v5-c-popover.pf-m-success {
  --pf-v5-c-popover__title-icon--Color: var(--pf-v5-c-popover--m-success__title-icon--Color);
  --pf-v5-c-popover__title-text--Color: var(--pf-v5-c-popover--m-success__title-text--Color);
}
.pf-v5-c-popover.pf-m-custom {
  --pf-v5-c-popover__title-icon--Color: var(--pf-v5-c-popover--m-custom__title-icon--Color);
  --pf-v5-c-popover__title-text--Color: var(--pf-v5-c-popover--m-custom__title-text--Color);
}
.pf-v5-c-popover.pf-m-info {
  --pf-v5-c-popover__title-icon--Color: var(--pf-v5-c-popover--m-info__title-icon--Color);
  --pf-v5-c-popover__title-text--Color: var(--pf-v5-c-popover--m-info__title-text--Color);
}

.pf-v5-c-popover__content {
  position: relative;
  padding-block-start: var(--pf-v5-c-popover__content--PaddingTop);
  padding-block-end: var(--pf-v5-c-popover__content--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-popover__content--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-popover__content--PaddingRight);
  background-color: var(--pf-v5-c-popover__content--BackgroundColor);
}

.pf-v5-c-popover__close {
  position: absolute;
  inset-block-start: var(--pf-v5-c-popover__close--Top);
  inset-inline-end: var(--pf-v5-c-popover__close--Right);
}
.pf-v5-c-popover__close + * {
  padding-inline-end: var(--pf-v5-c-popover__close--sibling--PaddingRight);
}

.pf-v5-c-popover__arrow {
  position: absolute;
  top: var(--pf-v5-c-popover__arrow--Top, auto);
  right: var(--pf-v5-c-popover__arrow--Right, auto);
  bottom: var(--pf-v5-c-popover__arrow--Bottom, auto);
  left: var(--pf-v5-c-popover__arrow--Left, auto);
  width: var(--pf-v5-c-popover__arrow--Width);
  height: var(--pf-v5-c-popover__arrow--Height);
  pointer-events: none;
  background-color: var(--pf-v5-c-popover__arrow--BackgroundColor);
  box-shadow: var(--pf-v5-c-popover__arrow--BoxShadow);
  transform: translateX(var(--pf-v5-c-popover__arrow--TranslateX, 0)) translateY(var(--pf-v5-c-popover__arrow--TranslateY, 0)) rotate(var(--pf-v5-c-popover__arrow--Rotate, 0));
}

.pf-v5-c-popover__header {
  margin-block-end: var(--pf-v5-c-popover__header--MarginBottom);
}

.pf-v5-c-popover__title {
  display: flex;
  flex: 0 0 auto;
}

.pf-v5-c-popover__title-icon {
  margin-inline-end: var(--pf-v5-c-popover__title-icon--MarginRight);
  font-size: var(--pf-v5-c-popover__title-icon--FontSize);
  color: var(--pf-v5-c-popover__title-icon--Color);
}

.pf-v5-c-popover__title-text {
  font-family: var(--pf-v5-c-popover__title-text--FontFamily);
  font-size: var(--pf-v5-c-popover__title-text--FontSize);
  line-height: var(--pf-v5-c-popover__title-text--LineHeight);
  color: var(--pf-v5-c-popover__title-text--Color, inherit);
}

.pf-v5-c-popover__body {
  word-wrap: break-word;
}

.pf-v5-c-popover__footer {
  margin-block-start: var(--pf-v5-c-popover__footer--MarginTop);
}

:where(.pf-v5-theme-dark) .pf-v5-c-popover {
  --pf-v5-c-popover__content--BackgroundColor: var(--pf-v5-global--BackgroundColor--300);
  --pf-v5-c-popover__arrow--BackgroundColor: var(--pf-v5-global--BackgroundColor--300);
  --pf-v5-c-popover--m-custom__title-text--Color: var(--pf-v5-global--custom-color--200);
  --pf-v5-c-popover--m-info__title-text--Color: var(--pf-v5-global--info-color--100);
  --pf-v5-c-popover--m-success__title-text--Color: var(--pf-v5-global--success-color--100);
}