.pf-v5-t-light {
  --pf-v5-global--Color--100: var(--pf-v5-global--Color--dark-100);
  --pf-v5-global--Color--200: var(--pf-v5-global--Color--dark-200);
  --pf-v5-global--BorderColor--100: var(--pf-v5-global--BorderColor--dark-100);
  --pf-v5-global--primary-color--100: var(--pf-v5-global--primary-color--dark-100);
  --pf-v5-global--link--Color: var(--pf-v5-global--link--Color--dark);
  --pf-v5-global--link--Color--hover: var(--pf-v5-global--link--Color--dark--hover);
  --pf-v5-global--BackgroundColor--100: var(--pf-v5-global--BackgroundColor--light-100);
  --pf-v5-global--icon--Color--light: var(--pf-v5-global--icon--Color--light--dark);
  --pf-v5-global--icon--Color--dark: var(--pf-v5-global--icon--Color--dark--dark);
}

.pf-v5-t-dark {
  --pf-v5-global--Color--100: var(--pf-v5-global--Color--light-100);
  --pf-v5-global--Color--200: var(--pf-v5-global--Color--light-200);
  --pf-v5-global--BorderColor--100: var(--pf-v5-global--BorderColor--light-100);
  --pf-v5-global--primary-color--100: var(--pf-v5-global--primary-color--light-100);
  --pf-v5-global--link--Color: var(--pf-v5-global--link--Color--light);
  --pf-v5-global--link--Color--hover: var(--pf-v5-global--link--Color--light);
  --pf-v5-global--BackgroundColor--100: var(--pf-v5-global--BackgroundColor--dark-100);
  --pf-v5-global--icon--Color--light: var(--pf-v5-global--icon--Color--light--light);
  --pf-v5-global--icon--Color--dark: var(--pf-v5-global--icon--Color--dark--light);
}

.pf-v5-t-dark .pf-v5-c-button {
  --pf-v5-c-button--m-primary--BackgroundColor: var(--pf-v5-global--primary-color--dark-100);
}

:where(:root) {
  --pf-v5-global--palette--black-100: #fafafa;
  --pf-v5-global--palette--black-150: #f5f5f5;
  --pf-v5-global--palette--black-200: #f0f0f0;
  --pf-v5-global--palette--black-300: #d2d2d2;
  --pf-v5-global--palette--black-400: #b8bbbe;
  --pf-v5-global--palette--black-500: #8a8d90;
  --pf-v5-global--palette--black-600: #6a6e73;
  --pf-v5-global--palette--black-700: #4f5255;
  --pf-v5-global--palette--black-800: #3c3f42;
  --pf-v5-global--palette--black-850: #212427;
  --pf-v5-global--palette--black-900: #151515;
  --pf-v5-global--palette--black-1000: #030303;
  --pf-v5-global--palette--blue-50: #e7f1fa;
  --pf-v5-global--palette--blue-100: #bee1f4;
  --pf-v5-global--palette--blue-200: #73bcf7;
  --pf-v5-global--palette--blue-300: #2b9af3;
  --pf-v5-global--palette--blue-400: #06c;
  --pf-v5-global--palette--blue-500: #004080;
  --pf-v5-global--palette--blue-600: #002952;
  --pf-v5-global--palette--blue-700: #001223;
  --pf-v5-global--palette--cyan-50: #f2f9f9;
  --pf-v5-global--palette--cyan-100: #a2d9d9;
  --pf-v5-global--palette--cyan-200: #73c5c5;
  --pf-v5-global--palette--cyan-300: #009596;
  --pf-v5-global--palette--cyan-400: #005f60;
  --pf-v5-global--palette--cyan-500: #003737;
  --pf-v5-global--palette--cyan-600: #002323;
  --pf-v5-global--palette--cyan-700: #000f0f;
  --pf-v5-global--palette--gold-50: #fdf7e7;
  --pf-v5-global--palette--gold-100: #f9e0a2;
  --pf-v5-global--palette--gold-200: #f6d173;
  --pf-v5-global--palette--gold-300: #f4c145;
  --pf-v5-global--palette--gold-400: #f0ab00;
  --pf-v5-global--palette--gold-500: #c58c00;
  --pf-v5-global--palette--gold-600: #795600;
  --pf-v5-global--palette--gold-700: #3d2c00;
  --pf-v5-global--palette--green-50: #f3faf2;
  --pf-v5-global--palette--green-100: #bde5b8;
  --pf-v5-global--palette--green-200: #95d58e;
  --pf-v5-global--palette--green-300: #6ec664;
  --pf-v5-global--palette--green-400: #5ba352;
  --pf-v5-global--palette--green-500: #3e8635;
  --pf-v5-global--palette--green-600: #1e4f18;
  --pf-v5-global--palette--green-700: #0f280d;
  --pf-v5-global--palette--light-blue-100: #beedf9;
  --pf-v5-global--palette--light-blue-200: #7cdbf3;
  --pf-v5-global--palette--light-blue-300: #35caed;
  --pf-v5-global--palette--light-blue-400: #00b9e4;
  --pf-v5-global--palette--light-blue-500: #008bad;
  --pf-v5-global--palette--light-blue-600: #005c73;
  --pf-v5-global--palette--light-blue-700: #002d39;
  --pf-v5-global--palette--light-green-100: #e4f5bc;
  --pf-v5-global--palette--light-green-200: #c8eb79;
  --pf-v5-global--palette--light-green-300: #ace12e;
  --pf-v5-global--palette--light-green-400: #92d400;
  --pf-v5-global--palette--light-green-500: #6ca100;
  --pf-v5-global--palette--light-green-600: #486b00;
  --pf-v5-global--palette--light-green-700: #253600;
  --pf-v5-global--palette--orange-50: #fff6ec;
  --pf-v5-global--palette--orange-100: #f4b678;
  --pf-v5-global--palette--orange-200: #ef9234;
  --pf-v5-global--palette--orange-300: #ec7a08;
  --pf-v5-global--palette--orange-400: #c46100;
  --pf-v5-global--palette--orange-500: #8f4700;
  --pf-v5-global--palette--orange-600: #773d00;
  --pf-v5-global--palette--orange-700: #3b1f00;
  --pf-v5-global--palette--purple-50: #f2f0fc;
  --pf-v5-global--palette--purple-100: #cbc1ff;
  --pf-v5-global--palette--purple-200: #b2a3ff;
  --pf-v5-global--palette--purple-300: #a18fff;
  --pf-v5-global--palette--purple-400: #8476d1;
  --pf-v5-global--palette--purple-500: #6753ac;
  --pf-v5-global--palette--purple-600: #40199a;
  --pf-v5-global--palette--purple-700: #1f0066;
  --pf-v5-global--palette--red-50: #faeae8;
  --pf-v5-global--palette--red-100: #c9190b;
  --pf-v5-global--palette--red-200: #a30000;
  --pf-v5-global--palette--red-300: #7d1007;
  --pf-v5-global--palette--red-400: #470000;
  --pf-v5-global--palette--red-500: #2c0000;
  --pf-v5-global--palette--white: #fff;
  --pf-v5-global--BackgroundColor--100: #fff;
  --pf-v5-global--BackgroundColor--150: #fafafa;
  --pf-v5-global--BackgroundColor--200: #f0f0f0;
  --pf-v5-global--BackgroundColor--light-100: #fff;
  --pf-v5-global--BackgroundColor--light-200: #fafafa;
  --pf-v5-global--BackgroundColor--light-300: #f0f0f0;
  --pf-v5-global--BackgroundColor--dark-100: #151515;
  --pf-v5-global--BackgroundColor--dark-200: #3c3f42;
  --pf-v5-global--BackgroundColor--dark-300: #212427;
  --pf-v5-global--BackgroundColor--dark-400: #4f5255;
  --pf-v5-global--BackgroundColor--dark-transparent-100: rgba(3, 3, 3, 0.62);
  --pf-v5-global--BackgroundColor--dark-transparent-200: rgba(3, 3, 3, 0.32);
  --pf-v5-global--Color--100: #151515;
  --pf-v5-global--Color--200: #6a6e73;
  --pf-v5-global--Color--300: #3c3f42;
  --pf-v5-global--Color--400: #8a8d90;
  --pf-v5-global--Color--light-100: #fff;
  --pf-v5-global--Color--light-200: #f0f0f0;
  --pf-v5-global--Color--light-300: #d2d2d2;
  --pf-v5-global--Color--dark-100: #151515;
  --pf-v5-global--Color--dark-200: #6a6e73;
  --pf-v5-global--active-color--100: #06c;
  --pf-v5-global--active-color--200: #bee1f4;
  --pf-v5-global--active-color--300: #2b9af3;
  --pf-v5-global--active-color--400: #73bcf7;
  --pf-v5-global--disabled-color--100: #6a6e73;
  --pf-v5-global--disabled-color--200: #d2d2d2;
  --pf-v5-global--disabled-color--300: #f0f0f0;
  --pf-v5-global--primary-color--100: #06c;
  --pf-v5-global--primary-color--200: #004080;
  --pf-v5-global--primary-color--light-100: #73bcf7;
  --pf-v5-global--primary-color--dark-100: #06c;
  --pf-v5-global--secondary-color--100: #6a6e73;
  --pf-v5-global--custom-color--100: #73c5c5;
  --pf-v5-global--custom-color--200: #009596;
  --pf-v5-global--custom-color--300: #003737;
  --pf-v5-global--success-color--100: #3e8635;
  --pf-v5-global--success-color--200: #1e4f18;
  --pf-v5-global--info-color--100: #2b9af3;
  --pf-v5-global--info-color--200: #002952;
  --pf-v5-global--warning-color--100: #f0ab00;
  --pf-v5-global--warning-color--200: #795600;
  --pf-v5-global--danger-color--100: #c9190b;
  --pf-v5-global--danger-color--200: #a30000;
  --pf-v5-global--danger-color--300: #470000;
  --pf-v5-global--BoxShadow--sm: 0 0.0625rem 0.125rem 0 rgba(3, 3, 3, 0.12), 0 0 0.125rem 0 rgba(3, 3, 3, 0.06);
  --pf-v5-global--BoxShadow--sm-top: 0 -0.125rem 0.25rem -0.0625rem rgba(3, 3, 3, 0.16);
  --pf-v5-global--BoxShadow--sm-right: 0.125rem 0 0.25rem -0.0625rem rgba(3, 3, 3, 0.16);
  --pf-v5-global--BoxShadow--sm-bottom: 0 0.125rem 0.25rem -0.0625rem rgba(3, 3, 3, 0.16);
  --pf-v5-global--BoxShadow--sm-left: -0.125rem 0 0.25rem -0.0625rem rgba(3, 3, 3, 0.16);
  --pf-v5-global--BoxShadow--md: 0 0.25rem 0.5rem 0rem rgba(3, 3, 3, 0.12), 0 0 0.25rem 0 rgba(3, 3, 3, 0.06);
  --pf-v5-global--BoxShadow--md-top: 0 -0.5rem 0.5rem -0.375rem rgba(3, 3, 3, 0.18);
  --pf-v5-global--BoxShadow--md-right: 0.5rem 0 0.5rem -0.375rem rgba(3, 3, 3, 0.18);
  --pf-v5-global--BoxShadow--md-bottom: 0 0.5rem 0.5rem -0.375rem rgba(3, 3, 3, 0.18);
  --pf-v5-global--BoxShadow--md-left: -0.5rem 0 0.5rem -0.375rem rgba(3, 3, 3, 0.18);
  --pf-v5-global--BoxShadow--lg: 0 0.5rem 1rem 0 rgba(3, 3, 3, 0.16), 0 0 0.375rem 0 rgba(3, 3, 3, 0.08);
  --pf-v5-global--BoxShadow--lg-top: 0 -0.75rem 0.75rem -0.5rem rgba(3, 3, 3, 0.18);
  --pf-v5-global--BoxShadow--lg-right: 0.75rem 0 0.75rem -0.5rem rgba(3, 3, 3, 0.18);
  --pf-v5-global--BoxShadow--lg-bottom: 0 0.75rem 0.75rem -0.5rem rgba(3, 3, 3, 0.18);
  --pf-v5-global--BoxShadow--lg-left: -0.75rem 0 0.75rem -0.5rem rgba(3, 3, 3, 0.18);
  --pf-v5-global--BoxShadow--xl: 0 1rem 2rem 0 rgba(3, 3, 3, 0.16), 0 0 0.5rem 0 rgba(3, 3, 3, 0.1);
  --pf-v5-global--BoxShadow--xl-top: 0 -1rem 1rem -0.5rem rgba(3, 3, 3, 0.2);
  --pf-v5-global--BoxShadow--xl-right: 1rem 0 1rem -0.5rem rgba(3, 3, 3, 0.2);
  --pf-v5-global--BoxShadow--xl-bottom: 0 1rem 1rem -0.5rem rgba(3, 3, 3, 0.2);
  --pf-v5-global--BoxShadow--xl-left: -1rem 0 1rem -0.5rem rgba(3, 3, 3, 0.2);
  --pf-v5-global--BoxShadow--inset: inset 0 0 0.625rem 0 rgba(3, 3, 3, 0.25);
  --pf-v5-global--font-path: "./assets/fonts";
  --pf-v5-global--fonticon-path: "./assets/pficon";
  --pf-v5-global--spacer--xs: 0.25rem;
  --pf-v5-global--spacer--sm: 0.5rem;
  --pf-v5-global--spacer--md: 1rem;
  --pf-v5-global--spacer--lg: 1.5rem;
  --pf-v5-global--spacer--xl: 2rem;
  --pf-v5-global--spacer--2xl: 3rem;
  --pf-v5-global--spacer--3xl: 4rem;
  --pf-v5-global--spacer--4xl: 5rem;
  --pf-v5-global--spacer--form-element: 0.375rem;
  --pf-v5-global--gutter: 1rem;
  --pf-v5-global--gutter--md: 1.5rem;
  --pf-v5-global--ZIndex--xs: 100;
  --pf-v5-global--ZIndex--sm: 200;
  --pf-v5-global--ZIndex--md: 300;
  --pf-v5-global--ZIndex--lg: 400;
  --pf-v5-global--ZIndex--xl: 500;
  --pf-v5-global--ZIndex--2xl: 600;
  --pf-v5-global--breakpoint--xs: 0;
  --pf-v5-global--breakpoint--sm: 576px;
  --pf-v5-global--breakpoint--md: 768px;
  --pf-v5-global--breakpoint--lg: 992px;
  --pf-v5-global--breakpoint--xl: 1200px;
  --pf-v5-global--breakpoint--2xl: 1450px;
  --pf-v5-global--height-breakpoint--sm: 0;
  --pf-v5-global--height-breakpoint--md: 40rem;
  --pf-v5-global--height-breakpoint--lg: 48rem;
  --pf-v5-global--height-breakpoint--xl: 60rem;
  --pf-v5-global--height-breakpoint--2xl: 80rem;
  --pf-v5-global--link--Color: #06c;
  --pf-v5-global--link--Color--hover: #004080;
  --pf-v5-global--link--Color--light: #2b9af3;
  --pf-v5-global--link--Color--light--hover: #73bcf7;
  --pf-v5-global--link--Color--dark: #06c;
  --pf-v5-global--link--Color--dark--hover: #004080;
  --pf-v5-global--link--Color--visited: #40199a;
  --pf-v5-global--link--TextDecoration: none;
  --pf-v5-global--link--TextDecoration--hover: underline;
  --pf-v5-global--BorderWidth--sm: 1px;
  --pf-v5-global--BorderWidth--md: 2px;
  --pf-v5-global--BorderWidth--lg: 3px;
  --pf-v5-global--BorderWidth--xl: 4px;
  --pf-v5-global--BorderColor--100: #d2d2d2;
  --pf-v5-global--BorderColor--200: #8a8d90;
  --pf-v5-global--BorderColor--300: #f0f0f0;
  --pf-v5-global--BorderColor--dark-100: #d2d2d2;
  --pf-v5-global--BorderColor--light-100: #b8bbbe;
  --pf-v5-global--BorderRadius--sm: 3px;
  --pf-v5-global--BorderRadius--lg: 30em;
  --pf-v5-global--icon--Color--light: #6a6e73;
  --pf-v5-global--icon--Color--dark: #151515;
  --pf-v5-global--icon--Color--light--light: #f0f0f0;
  --pf-v5-global--icon--Color--dark--light: #fff;
  --pf-v5-global--icon--Color--light--dark: #6a6e73;
  --pf-v5-global--icon--Color--dark--dark: #151515;
  --pf-v5-global--icon--FontSize--sm: 0.75rem;
  --pf-v5-global--icon--FontSize--md: 1rem;
  --pf-v5-global--icon--FontSize--lg: 1.5rem;
  --pf-v5-global--icon--FontSize--xl: 3.375rem;
  --pf-v5-global--FontFamily--text: "RedHatText", helvetica, arial, sans-serif;
  --pf-v5-global--FontFamily--heading: "RedHatDisplay", helvetica, arial, sans-serif;
  --pf-v5-global--FontFamily--monospace: "RedHatMono", "Liberation Mono", consolas, "SFMono-Regular", menlo, monaco, "Courier New", monospace;
  --pf-v5-global--FontFamily--text--vf: "RedHatTextVF", "RedHatText", helvetica, arial, sans-serif;
  --pf-v5-global--FontFamily--heading--vf: "RedHatDisplayVF", "RedHatDisplay", helvetica, arial, sans-serif;
  --pf-v5-global--FontFamily--monospace--vf: "RedHatMonoVF", "RedHatMono", "Liberation Mono", consolas, "SFMono-Regular", menlo, monaco, "Courier New", monospace;
  --pf-v5-global--FontSize--4xl: 2.25rem;
  --pf-v5-global--FontSize--3xl: 1.75rem;
  --pf-v5-global--FontSize--2xl: 1.5rem;
  --pf-v5-global--FontSize--xl: 1.25rem;
  --pf-v5-global--FontSize--lg: 1.125rem;
  --pf-v5-global--FontSize--md: 1rem;
  --pf-v5-global--FontSize--sm: 0.875rem;
  --pf-v5-global--FontSize--xs: 0.75rem;
  --pf-v5-global--FontWeight--normal: 400;
  --pf-v5-global--FontWeight--bold: 700;
  --pf-v5-global--LineHeight--sm: 1.3;
  --pf-v5-global--LineHeight--md: 1.5;
  --pf-v5-global--ListStyle: disc outside;
  --pf-v5-global--Transition: all 250ms cubic-bezier(0.42, 0, 0.58, 1);
  --pf-v5-global--TimingFunction: cubic-bezier(0.645, 0.045, 0.355, 1);
  --pf-v5-global--TransitionDuration: 250ms;
  --pf-v5-global--arrow--width: 0.9375rem;
  --pf-v5-global--arrow--width-lg: 1.5625rem;
  --pf-v5-global--target-size--MinWidth: 44px;
  --pf-v5-global--target-size--MinHeight: 44px;
  --pf-v5-global--inverse--multiplier: 1;
}

:where(.pf-v5-m-dir-rtl, [dir=rtl]) {
  --pf-v5-global--inverse--multiplier: -1;
}

:where(.pf-v5-theme-dark) {
  --pf-v5-global--palette--black-50: #e0e0e0;
  --pf-v5-global--palette--black-100: #c6c7c8;
  --pf-v5-global--palette--black-200: #aaabac;
  --pf-v5-global--palette--black-300: #868789;
  --pf-v5-global--palette--black-400: #57585a;
  --pf-v5-global--palette--black-500: #444548;
  --pf-v5-global--palette--black-600: #36373a;
  --pf-v5-global--palette--black-700: #26292d;
  --pf-v5-global--palette--black-800: #1b1d21;
  --pf-v5-global--palette--black-900: #0f1214;
  --pf-v5-global--palette--red-9999: #fe5142;
  --pf-v5-global--palette--red-8888: #ff7468;
  --pf-v5-global--palette--blue-300: #1fa7f8;
  --pf-v5-global--BackgroundColor--100: #1b1d21;
  --pf-v5-global--BackgroundColor--150: #212427;
  --pf-v5-global--BackgroundColor--200: #0f1214;
  --pf-v5-global--BackgroundColor--300: #26292d;
  --pf-v5-global--BackgroundColor--400: #36373a;
  --pf-v5-global--BorderColor--100: #444548;
  --pf-v5-global--BorderColor--200: #444548;
  --pf-v5-global--BorderColor--300: #57585a;
  --pf-v5-global--BorderColor--400: #aaabac;
  --pf-v5-global--Color--100: #e0e0e0;
  --pf-v5-global--Color--200: #aaabac;
  --pf-v5-global--active-color--100: #1fa7f8;
  --pf-v5-global--primary-color--100: #1fa7f8;
  --pf-v5-global--primary-color--300: #06c;
  --pf-v5-global--primary-color--400: #fff;
  --pf-v5-global--custom-color--200: #73c5c5;
  --pf-v5-global--success-color--100: #5ba352;
  --pf-v5-global--warning-color--100: #f0ab00;
  --pf-v5-global--warning-color--200: #f4c145;
  --pf-v5-global--danger-color--100: #fe5142;
  --pf-v5-global--danger-color--200: #ff7468;
  --pf-v5-global--link--Color: #1fa7f8;
  --pf-v5-global--link--Color--hover: #73bcf7;
  --pf-v5-global--link--Color--visited: #a18fff;
  --pf-v5-global--disabled-color--100: #868789;
  --pf-v5-global--disabled-color--200: #444548;
  --pf-v5-global--disabled-color--300: #aaabac;
  --pf-v5-global--icon--Color--light: #aaabac;
  --pf-v5-global--icon--Color--dark: #e0e0e0;
  --pf-v5-global--Color--dark-100: #e0e0e0;
  --pf-v5-global--Color--dark-200: #aaabac;
  --pf-v5-global--Color--light-100: #e0e0e0;
  --pf-v5-global--Color--light-200: #aaabac;
  --pf-v5-global--Color--light-300: #3c3f42;
  --pf-v5-global--BorderColor--dark-100: #444548;
  --pf-v5-global--BorderColor--light-100: #444548;
  --pf-v5-global--primary-color--light-100: #1fa7f8;
  --pf-v5-global--primary-color--dark-100: #1fa7f8;
  --pf-v5-global--link--Color--light: #1fa7f8;
  --pf-v5-global--link--Color--light--hover: #73bcf7;
  --pf-v5-global--link--Color--dark: #1fa7f8;
  --pf-v5-global--link--Color--dark--hover: #73bcf7;
  --pf-v5-global--icon--Color--light--light: #aaabac;
  --pf-v5-global--icon--Color--dark--light: #e0e0e0;
  --pf-v5-global--icon--Color--light--dark: #aaabac;
  --pf-v5-global--icon--Color--dark--dark: #e0e0e0;
  --pf-v5-global--BackgroundColor--light-100: #1b1d21;
  --pf-v5-global--BackgroundColor--light-200: #0f1214;
  --pf-v5-global--BackgroundColor--light-300: #26292d;
  --pf-v5-global--BackgroundColor--dark-100: #1b1d21;
  --pf-v5-global--BackgroundColor--dark-200: #0f1214;
  --pf-v5-global--BackgroundColor--dark-300: #26292d;
  --pf-v5-global--BackgroundColor--dark-400: #36373a;
  --pf-v5-global--BoxShadow--sm: 0 0.0625rem 0.125rem 0 rgba(3, 3, 3, 0.48), 0 0 0.125rem 0 rgba(3, 3, 3, 0.24);
  --pf-v5-global--BoxShadow--sm-top: 0 -0.125rem 0.25rem -0.0625rem rgba(3, 3, 3, 0.64);
  --pf-v5-global--BoxShadow--sm-right: 0.125rem 0 0.25rem -0.0625rem rgba(3, 3, 3, 0.64);
  --pf-v5-global--BoxShadow--sm-bottom: 0 0.125rem 0.25rem -0.0625rem rgba(3, 3, 3, 0.64);
  --pf-v5-global--BoxShadow--sm-left: -0.125rem 0 0.25rem -0.0625rem rgba(3, 3, 3, 0.64);
  --pf-v5-global--BoxShadow--md: 0 0.25rem 0.5rem 0rem rgba(3, 3, 3, 0.48), 0 0 0.25rem 0 rgba(3, 3, 3, 0.24);
  --pf-v5-global--BoxShadow--md-top: 0 -0.5rem 0.5rem -0.375rem rgba(3, 3, 3, 0.72);
  --pf-v5-global--BoxShadow--md-right: 0.5rem 0 0.5rem -0.375rem rgba(3, 3, 3, 0.72);
  --pf-v5-global--BoxShadow--md-bottom: 0 0.5rem 0.5rem -0.375rem rgba(3, 3, 3, 0.72);
  --pf-v5-global--BoxShadow--md-left: -0.5rem 0 0.5rem -0.375rem rgba(3, 3, 3, 0.72);
  --pf-v5-global--BoxShadow--lg: 0 0.5rem 1rem 0 rgba(3, 3, 3, 0.64), 0 0 0.375rem 0 rgba(3, 3, 3, 0.32);
  --pf-v5-global--BoxShadow--lg-top: 0 -0.75rem 0.75rem -0.5rem rgba(3, 3, 3, 0.72);
  --pf-v5-global--BoxShadow--lg-right: 0.75rem 0 0.75rem -0.5rem rgba(3, 3, 3, 0.72);
  --pf-v5-global--BoxShadow--lg-bottom: 0 0.75rem 0.75rem -0.5rem rgba(3, 3, 3, 0.72);
  --pf-v5-global--BoxShadow--lg-left: -0.75rem 0 0.75rem -0.5rem rgba(3, 3, 3, 0.72);
  --pf-v5-global--BoxShadow--xl: 0 1rem 2rem 0 rgba(3, 3, 3, 0.64), 0 0 0.5rem 0 rgba(3, 3, 3, 0.4);
  --pf-v5-global--BoxShadow--xl-top: 0 -1rem 1rem -0.5rem rgba(3, 3, 3, 0.8);
  --pf-v5-global--BoxShadow--xl-right: 1rem 0 1rem -0.5rem rgba(3, 3, 3, 0.8);
  --pf-v5-global--BoxShadow--xl-bottom: 0 1rem 1rem -0.5rem rgba(3, 3, 3, 0.8);
  --pf-v5-global--BoxShadow--xl-left: -1rem 0 1rem -0.5rem rgba(3, 3, 3, 0.8);
  --pf-v5-global--BoxShadow--inset: inset 0 0 0.625rem 0 #030303;
}

@font-face {
  font-family: RedHatDisplay;
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/RedHatDisplay/RedHatDisplay-Medium.woff2") format("woff2");
  font-display: fallback;
}

@font-face {
  font-family: RedHatDisplay;
  font-style: italic;
  font-weight: 400;
  src: url("./assets/fonts/RedHatDisplay/RedHatDisplay-MediumItalic.woff2") format("woff2");
  font-display: fallback;
}

@font-face {
  font-family: RedHatDisplay;
  font-style: normal;
  font-weight: 700;
  src: url("./assets/fonts/RedHatDisplay/RedHatDisplay-Bold.woff2") format("woff2");
  font-display: fallback;
}

@font-face {
  font-family: RedHatDisplay;
  font-style: italic;
  font-weight: 700;
  src: url("./assets/fonts/RedHatDisplay/RedHatDisplay-BoldItalic.woff2") format("woff2");
  font-display: fallback;
}

@font-face {
  font-family: RedHatText;
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/RedHatText/RedHatText-Regular.woff2") format("woff2");
  font-display: fallback;
}

@font-face {
  font-family: RedHatText;
  font-style: italic;
  font-weight: 400;
  src: url("./assets/fonts/RedHatText/RedHatText-Italic.woff2") format("woff2");
  font-display: fallback;
}

@font-face {
  font-family: RedHatText;
  font-style: normal;
  font-weight: 700;
  src: url("./assets/fonts/RedHatText/RedHatText-Medium.woff2") format("woff2");
  font-display: fallback;
}

@font-face {
  font-family: RedHatText;
  font-style: italic;
  font-weight: 700;
  src: url("./assets/fonts/RedHatText/RedHatText-MediumItalic.woff2") format("woff2");
  font-display: fallback;
}

@font-face {
  font-family: RedHatMono;
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/RedHatMono/RedHatMono-Regular.woff2") format("woff2");
  font-display: fallback;
}

@font-face {
  font-family: RedHatMono;
  font-style: italic;
  font-weight: 400;
  src: url("./assets/fonts/RedHatMono/RedHatMono-Italic.woff2") format("woff2");
  font-display: fallback;
}

@font-face {
  font-family: RedHatDisplayVF;
  font-style: normal;
  font-weight: 400 700;
  src: url("./assets/fonts/RedHatDisplay/RedHatDisplayVF.woff2") format("woff2-variations");
  font-display: fallback;
}

@font-face {
  font-family: RedHatDisplayVF;
  font-style: italic;
  font-weight: 400 700;
  src: url("./assets/fonts/RedHatDisplay/RedHatDisplayVF-Italic.woff2") format("woff2-variations");
  font-display: fallback;
}

@font-face {
  font-family: RedHatTextVF;
  font-style: normal;
  font-weight: 400 500;
  src: url("./assets/fonts/RedHatText/RedHatTextVF.woff2") format("woff2-variations");
  font-display: fallback;
}

@font-face {
  font-family: RedHatTextVF;
  font-style: italic;
  font-weight: 400 500;
  src: url("./assets/fonts/RedHatText/RedHatTextVF-Italic.woff2") format("woff2-variations");
  font-display: fallback;
}

@font-face {
  font-family: RedHatMonoVF;
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/RedHatMono/RedHatMonoVF.woff2") format("woff2-variations");
  font-display: fallback;
}

@font-face {
  font-family: RedHatMonoVF;
  font-style: italic;
  font-weight: 400;
  src: url("./assets/fonts/RedHatMono/RedHatMonoVF-Italic.woff2") format("woff2-variations");
  font-display: fallback;
}

.pf-v5-screen-reader {
  position: fixed;
  inset-block-start: 0;
  inset-inline-start: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.pf-v5-screen-reader.pf-m-full-size {
  width: 100%;
  height: 100%;
}

.pf-v5-screen-reader.pf-m-absolute {
  position: absolute;
}

.pf-v5-m-tabular-nums {
  font-variant-numeric: tabular-nums;
}

.pf-v5-m-vf-font {
  --pf-v5-global--FontFamily--text: var(--pf-v5-global--FontFamily--text--vf);
  --pf-v5-global--FontFamily--heading: var(--pf-v5-global--FontFamily--heading--vf);
  --pf-v5-global--FontFamily--monospace: var(--pf-v5-global--FontFamily--monospace--vf);
}

.pf-v5-m-dir-rtl {
  --pf-v5-global--inverse--multiplier: -1;
  direction: rtl;
}

.pf-v5-m-dir-ltr {
  --pf-v5-global--inverse--multiplier: 1;
  direction: ltr;
}

:where(.pf-v5-m-dir-rtl, [dir=rtl]) .pf-v5-m-mirror-inline-rtl {
  scale: -1 1;
}

:where(html,
body,
p,
ol,
ul,
li,
dl,
dt,
dd,
blockquote,
figure,
fieldset,
legend,
textarea,
pre,
iframe,
hr,
h1,
h2,
h3,
h4,
h5,
h6) {
  padding: 0;
  margin: 0;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

:where(html,
body) {
  height: 100%;
}

:where(body) {
  font-family: var(--pf-v5-global--FontFamily--text);
  font-size: var(--pf-v5-global--FontSize--md);
  font-weight: var(--pf-v5-global--FontWeight--normal);
  line-height: var(--pf-v5-global--LineHeight--md);
}

:where(h1,
h2,
h3,
h4,
h5,
h6) {
  font-size: 100%;
  font-weight: var(--pf-v5-global--FontWeight--normal);
}

:where(ul) {
  list-style: none;
}

:where(button,
input,
optgroup,
select,
textarea) {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: var(--pf-v5-global--LineHeight--md);
  color: var(--pf-v5-global--Color--100);
}

:where(img,
embed,
iframe,
object,
audio,
video) {
  max-width: 100%;
  height: auto;
}

:where(iframe) {
  border: 0;
}

:where(table) {
  border-spacing: 0;
  border-collapse: collapse;
}

:where(td,
th) {
  padding: 0;
  text-align: start;
}

:where(code,
pre) {
  font-family: var(--pf-v5-global--FontFamily--monospace);
}

:where(a) {
  font-weight: var(--pf-v5-global--link--FontWeight);
  color: var(--pf-v5-global--link--Color);
  text-decoration: var(--pf-v5-global--link--TextDecoration);
}

:where(a:hover) {
  --pf-v5-global--link--Color: var(--pf-v5-global--link--Color--hover);
  --pf-v5-global--link--TextDecoration: var(--pf-v5-global--link--TextDecoration--hover);
}

:where(a,
button) {
  cursor: pointer;
}

:where(.pf-v5-theme-dark) {
  color-scheme: dark;
}

.pf-v5-t-dark.pf-m-transparent {
  background-color: transparent;
}

.pf-v5-t-dark.pf-m-transparent-100 {
  background-color: rgba(3, 3, 3, 0.42);
}

.pf-v5-t-dark.pf-m-transparent-200 {
  background-color: rgba(3, 3, 3, 0.6);
}

.pf-v5-t-dark.pf-m-opaque-100 {
  background-color: #1b1d21;
}

.pf-v5-t-dark.pf-m-opaque-200 {
  background-color: #0f1214;
}

.pf-v5-t-light.pf-m-transparent {
  background-color: transparent;
}

.pf-v5-t-light.pf-m-opaque-100 {
  background-color: #fff;
}

.pf-v5-t-light.pf-m-opaque-200 {
  background-color: #c6c7c8;
}

.pf-v5-t-light.pf-m-opaque-300 {
  background-color: #aaabac;
}

@font-face {
  font-family: "pf-v5-pficon";
  src: url("./assets/pficon/pf-v5-pficon.woff2") format("woff2");
}

.pf-v5-pficon-zone:before,
.pf-v5-pficon-warning-triangle:before,
.pf-v5-pficon-volume:before,
.pf-v5-pficon-virtual-machine:before,
.pf-v5-pficon-users:before,
.pf-v5-pficon-user:before,
.pf-v5-pficon-unplugged:before,
.pf-v5-pficon-unlocked:before,
.pf-v5-pficon-unknown:before,
.pf-v5-pficon-trend-up:before,
.pf-v5-pficon-trend-down:before,
.pf-v5-pficon-treeview:before,
.pf-v5-pficon-topology:before,
.pf-v5-pficon-thumb-tack:before,
.pf-v5-pficon-tenant:before,
.pf-v5-pficon-task:before,
.pf-v5-pficon-storage-domain:before,
.pf-v5-pficon-spinner2:before,
.pf-v5-pficon-spinner:before,
.pf-v5-pficon-severity-undefined:before,
.pf-v5-pficon-severity-none:before,
.pf-v5-pficon-severity-moderate:before,
.pf-v5-pficon-severity-minor:before,
.pf-v5-pficon-severity-important:before,
.pf-v5-pficon-severity-critical:before,
.pf-v5-pficon-services:before,
.pf-v5-pficon-service:before,
.pf-v5-pficon-service-catalog:before,
.pf-v5-pficon-server:before,
.pf-v5-pficon-server-group:before,
.pf-v5-pficon-security:before,
.pf-v5-pficon-screen:before,
.pf-v5-pficon-save:before,
.pf-v5-pficon-running:before,
.pf-v5-pficon-resources-full:before,
.pf-v5-pficon-resources-empty:before,
.pf-v5-pficon-resources-almost-full:before,
.pf-v5-pficon-resources-almost-empty:before,
.pf-v5-pficon-resource-pool:before,
.pf-v5-pficon-repository:before,
.pf-v5-pficon-replicator:before,
.pf-v5-pficon-remove2:before,
.pf-v5-pficon-registry:before,
.pf-v5-pficon-regions:before,
.pf-v5-pficon-rebooting:before,
.pf-v5-pficon-rebalance:before,
.pf-v5-pficon-project:before,
.pf-v5-pficon-process-automation:before,
.pf-v5-pficon-private:before,
.pf-v5-pficon-print:before,
.pf-v5-pficon-port:before,
.pf-v5-pficon-plugged:before,
.pf-v5-pficon-pficon-vcenter:before,
.pf-v5-pficon-pficon-template:before,
.pf-v5-pficon-pficon-sort-common-desc:before,
.pf-v5-pficon-pficon-sort-common-asc:before,
.pf-v5-pficon-pficon-satellite:before,
.pf-v5-pficon-pficon-network-range:before,
.pf-v5-pficon-pficon-history:before,
.pf-v5-pficon-pficon-dragdrop:before,
.pf-v5-pficon-pending:before,
.pf-v5-pficon-paused:before,
.pf-v5-pficon-panel-open:before,
.pf-v5-pficon-panel-close:before,
.pf-v5-pficon-package:before,
.pf-v5-pficon-os-image:before,
.pf-v5-pficon-orders:before,
.pf-v5-pficon-optimize:before,
.pf-v5-pficon-openstack:before,
.pf-v5-pficon-openshift:before,
.pf-v5-pficon-open-drawer-right:before,
.pf-v5-pficon-on:before,
.pf-v5-pficon-on-running:before,
.pf-v5-pficon-ok:before,
.pf-v5-pficon-off:before,
.pf-v5-pficon-not-started:before,
.pf-v5-pficon-new-process:before,
.pf-v5-pficon-network:before,
.pf-v5-pficon-namespaces:before,
.pf-v5-pficon-multicluster:before,
.pf-v5-pficon-monitoring:before,
.pf-v5-pficon-module:before,
.pf-v5-pficon-migration:before,
.pf-v5-pficon-middleware:before,
.pf-v5-pficon-messages:before,
.pf-v5-pficon-memory:before,
.pf-v5-pficon-maintenance:before,
.pf-v5-pficon-locked:before,
.pf-v5-pficon-key:before,
.pf-v5-pficon-integration:before,
.pf-v5-pficon-infrastructure:before,
.pf-v5-pficon-info:before,
.pf-v5-pficon-in-progress:before,
.pf-v5-pficon-import:before,
.pf-v5-pficon-home:before,
.pf-v5-pficon-history:before,
.pf-v5-pficon-help:before,
.pf-v5-pficon-globe-route:before,
.pf-v5-pficon-folder-open:before,
.pf-v5-pficon-folder-close:before,
.pf-v5-pficon-flavor:before,
.pf-v5-pficon-filter:before,
.pf-v5-pficon-export:before,
.pf-v5-pficon-error-circle-o:before,
.pf-v5-pficon-equalizer:before,
.pf-v5-pficon-enterprise:before,
.pf-v5-pficon-enhancement:before,
.pf-v5-pficon-edit:before,
.pf-v5-pficon-domain:before,
.pf-v5-pficon-disconnected:before,
.pf-v5-pficon-degraded:before,
.pf-v5-pficon-data-source:before,
.pf-v5-pficon-data-sink:before,
.pf-v5-pficon-data-processor:before,
.pf-v5-pficon-critical-risk:before,
.pf-v5-pficon-cpu:before,
.pf-v5-pficon-container-node:before,
.pf-v5-pficon-connected:before,
.pf-v5-pficon-cluster:before,
.pf-v5-pficon-cloud-tenant:before,
.pf-v5-pficon-cloud-security:before,
.pf-v5-pficon-close:before,
.pf-v5-pficon-chat:before,
.pf-v5-pficon-catalog:before,
.pf-v5-pficon-bundle:before,
.pf-v5-pficon-builder-image:before,
.pf-v5-pficon-build:before,
.pf-v5-pficon-blueprint:before,
.pf-v5-pficon-bell:before,
.pf-v5-pficon-automation:before,
.pf-v5-pficon-attention-bell:before,
.pf-v5-pficon-asleep:before,
.pf-v5-pficon-arrow:before,
.pf-v5-pficon-applications:before,
.pf-v5-pficon-ansible-tower:before,
.pf-v5-pficon-add-circle-o:before {
  font-family: "pf-v5-pficon";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
}

.pf-v5-pficon-add-circle-o:before {
  content: "\e61b";
}

.pf-v5-pficon-ansible-tower:before {
  content: "\e950";
}

.pf-v5-pficon-applications:before {
  content: "\e936";
}

.pf-v5-pficon-arrow:before {
  content: "\e929";
}

.pf-v5-pficon-asleep:before {
  content: "\e92e";
}

.pf-v5-pficon-attention-bell:before {
  content: "\e951";
}

.pf-v5-pficon-automation:before {
  content: "\e937";
}

.pf-v5-pficon-bell:before {
  content: "\e952";
}

.pf-v5-pficon-blueprint:before {
  content: "\e915";
}

.pf-v5-pficon-build:before {
  content: "\e902";
}

.pf-v5-pficon-builder-image:before {
  content: "\e800";
}

.pf-v5-pficon-bundle:before {
  content: "\e918";
}

.pf-v5-pficon-catalog:before {
  content: "\e953";
}

.pf-v5-pficon-chat:before {
  content: "\e954";
}

.pf-v5-pficon-close:before {
  content: "\e60b";
}

.pf-v5-pficon-cloud-security:before {
  content: "\e903";
}

.pf-v5-pficon-cloud-tenant:before {
  content: "\e904";
}

.pf-v5-pficon-cluster:before {
  content: "\e620";
}

.pf-v5-pficon-connected:before {
  content: "\e938";
}

.pf-v5-pficon-container-node:before {
  content: "\e621";
}

.pf-v5-pficon-cpu:before {
  content: "\e927";
}

.pf-v5-pficon-critical-risk:before {
  content: "\e976";
}

.pf-v5-pficon-data-processor:before {
  content: "\e97b";
}

.pf-v5-pficon-data-sink:before {
  content: "\e978";
}

.pf-v5-pficon-data-source:before {
  content: "\e979";
}

.pf-v5-pficon-degraded:before {
  content: "\e91b";
}

.pf-v5-pficon-disconnected:before {
  content: "\e955";
}

.pf-v5-pficon-domain:before {
  content: "\e919";
}

.pf-v5-pficon-edit:before {
  content: "\e60a";
}

.pf-v5-pficon-enhancement:before {
  content: "\e93a";
}

.pf-v5-pficon-enterprise:before {
  content: "\e906";
}

.pf-v5-pficon-equalizer:before {
  content: "\e956";
}

.pf-v5-pficon-error-circle-o:before {
  content: "\e926";
}

.pf-v5-pficon-export:before {
  content: "\e616";
}

.pf-v5-pficon-filter:before {
  content: "\e943";
}

.pf-v5-pficon-flavor:before {
  content: "\e957";
}

.pf-v5-pficon-folder-close:before {
  content: "\e607";
}

.pf-v5-pficon-folder-open:before {
  content: "\e606";
}

.pf-v5-pficon-globe-route:before {
  content: "\e958";
}

.pf-v5-pficon-help:before {
  content: "\e605";
}

.pf-v5-pficon-history:before {
  content: "\e617";
}

.pf-v5-pficon-home:before {
  content: "\e618";
}

.pf-v5-pficon-import:before {
  content: "\e615";
}

.pf-v5-pficon-in-progress:before {
  content: "\e933";
}

.pf-v5-pficon-info:before {
  content: "\e92b";
}

.pf-v5-pficon-infrastructure:before {
  content: "\e93d";
}

.pf-v5-pficon-integration:before {
  content: "\e948";
}

.pf-v5-pficon-key:before {
  content: "\e924";
}

.pf-v5-pficon-locked:before {
  content: "\e923";
}

.pf-v5-pficon-maintenance:before {
  content: "\e932";
}

.pf-v5-pficon-memory:before {
  content: "\e908";
}

.pf-v5-pficon-messages:before {
  content: "\e603";
}

.pf-v5-pficon-middleware:before {
  content: "\e917";
}

.pf-v5-pficon-migration:before {
  content: "\e931";
}

.pf-v5-pficon-module:before {
  content: "\e959";
}

.pf-v5-pficon-monitoring:before {
  content: "\e95a";
}

.pf-v5-pficon-multicluster:before {
  content: "\e97c";
}

.pf-v5-pficon-namespaces:before {
  content: "\e95b";
}

.pf-v5-pficon-network:before {
  content: "\e909";
}

.pf-v5-pficon-new-process:before {
  content: "\e95c";
}

.pf-v5-pficon-not-started:before {
  content: "\e95d";
}

.pf-v5-pficon-off:before {
  content: "\e92d";
}

.pf-v5-pficon-ok:before {
  content: "\e602";
}

.pf-v5-pficon-on-running:before {
  content: "\e925";
}

.pf-v5-pficon-on:before {
  content: "\e92c";
}

.pf-v5-pficon-open-drawer-right:before {
  content: "\e977";
}

.pf-v5-pficon-openshift:before {
  content: "\e95e";
}

.pf-v5-pficon-openstack:before {
  content: "\e95f";
}

.pf-v5-pficon-optimize:before {
  content: "\e93e";
}

.pf-v5-pficon-orders:before {
  content: "\e93f";
}

.pf-v5-pficon-os-image:before {
  content: "\e960";
}

.pf-v5-pficon-package:before {
  content: "\e961";
}

.pf-v5-pficon-panel-close:before {
  content: "\e962";
}

.pf-v5-pficon-panel-open:before {
  content: "\e963";
}

.pf-v5-pficon-paused:before {
  content: "\e92f";
}

.pf-v5-pficon-pending:before {
  content: "\e964";
}

.pf-v5-pficon-pficon-dragdrop:before {
  content: "\e965";
}

.pf-v5-pficon-pficon-history:before {
  content: "\e966";
}

.pf-v5-pficon-pficon-network-range:before {
  content: "\e967";
}

.pf-v5-pficon-pficon-satellite:before {
  content: "\e968";
}

.pf-v5-pficon-pficon-sort-common-asc:before {
  content: "\e94e";
}

.pf-v5-pficon-pficon-sort-common-desc:before {
  content: "\e94f";
}

.pf-v5-pficon-pficon-template:before {
  content: "\e94c";
}

.pf-v5-pficon-pficon-vcenter:before {
  content: "\e969";
}

.pf-v5-pficon-plugged:before {
  content: "\e96a";
}

.pf-v5-pficon-port:before {
  content: "\e96b";
}

.pf-v5-pficon-print:before {
  content: "\e612";
}

.pf-v5-pficon-private:before {
  content: "\e914";
}

.pf-v5-pficon-process-automation:before {
  content: "\e949";
}

.pf-v5-pficon-project:before {
  content: "\e96c";
}

.pf-v5-pficon-rebalance:before {
  content: "\e91c";
}

.pf-v5-pficon-rebooting:before {
  content: "\e96d";
}

.pf-v5-pficon-regions:before {
  content: "\e90a";
}

.pf-v5-pficon-registry:before {
  content: "\e623";
}

.pf-v5-pficon-remove2:before {
  content: "\e96e";
}

.pf-v5-pficon-replicator:before {
  content: "\e624";
}

.pf-v5-pficon-repository:before {
  content: "\e90b";
}

.pf-v5-pficon-resource-pool:before {
  content: "\e90c";
}

.pf-v5-pficon-resources-almost-empty:before {
  content: "\e91d";
}

.pf-v5-pficon-resources-almost-full:before {
  content: "\e912";
}

.pf-v5-pficon-resources-empty:before {
  content: "\e96f";
}

.pf-v5-pficon-resources-full:before {
  content: "\e913";
}

.pf-v5-pficon-running:before {
  content: "\e970";
}

.pf-v5-pficon-save:before {
  content: "\e601";
}

.pf-v5-pficon-screen:before {
  content: "\e971";
}

.pf-v5-pficon-security:before {
  content: "\e946";
}

.pf-v5-pficon-server-group:before {
  content: "\e91a";
}

.pf-v5-pficon-server:before {
  content: "\e90d";
}

.pf-v5-pficon-service-catalog:before {
  content: "\e972";
}

.pf-v5-pficon-service:before {
  content: "\e61e";
}

.pf-v5-pficon-services:before {
  content: "\e947";
}

.pf-v5-pficon-severity-critical:before {
  content: "\e97e";
}

.pf-v5-pficon-severity-important:before {
  content: "\e97f";
}

.pf-v5-pficon-severity-minor:before {
  content: "\e980";
}

.pf-v5-pficon-severity-moderate:before {
  content: "\e981";
}

.pf-v5-pficon-severity-none:before {
  content: "\e982";
}

.pf-v5-pficon-severity-undefined:before {
  content: "\e983";
}

.pf-v5-pficon-spinner:before {
  content: "\e973";
}

.pf-v5-pficon-spinner2:before {
  content: "\e613";
}

.pf-v5-pficon-storage-domain:before {
  content: "\e90e";
}

.pf-v5-pficon-task:before {
  content: "\e974";
}

.pf-v5-pficon-tenant:before {
  content: "\e916";
}

.pf-v5-pficon-thumb-tack:before {
  content: "\e920";
}

.pf-v5-pficon-topology:before {
  content: "\e608";
}

.pf-v5-pficon-treeview:before {
  content: "\e97d";
}

.pf-v5-pficon-trend-down:before {
  content: "\e900";
}

.pf-v5-pficon-trend-up:before {
  content: "\e901";
}

.pf-v5-pficon-unknown:before {
  content: "\e935";
}

.pf-v5-pficon-unlocked:before {
  content: "\e922";
}

.pf-v5-pficon-unplugged:before {
  content: "\e942";
}

.pf-v5-pficon-user:before {
  content: "\e91e";
}

.pf-v5-pficon-users:before {
  content: "\e91f";
}

.pf-v5-pficon-virtual-machine:before {
  content: "\e90f";
}

.pf-v5-pficon-volume:before {
  content: "\e910";
}

.pf-v5-pficon-warning-triangle:before {
  content: "\e975";
}

.pf-v5-pficon-zone:before {
  content: "\e911";
}

.pf-v5-svg {
  width: 1em;
  height: 1em;
  vertical-align: -0.125em;
}