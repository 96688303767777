.pf-v5-c-menu-toggle {
  --pf-v5-c-menu-toggle--BorderRadius: 0;
  --pf-v5-c-menu-toggle--PaddingTop: var(--pf-v5-global--spacer--form-element);
  --pf-v5-c-menu-toggle--PaddingRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-menu-toggle--PaddingBottom: var(--pf-v5-global--spacer--form-element);
  --pf-v5-c-menu-toggle--PaddingLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-menu-toggle--FontSize: var(--pf-v5-global--FontSize--md);
  --pf-v5-c-menu-toggle--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-menu-toggle--LineHeight: var(--pf-v5-global--LineHeight--md);
  --pf-v5-c-menu-toggle--BackgroundColor: transparent;
  --pf-v5-c-menu-toggle--before--BorderTopWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-menu-toggle--before--BorderRightWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-menu-toggle--before--BorderBottomWidth: 0;
  --pf-v5-c-menu-toggle--before--BorderLeftWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-menu-toggle--before--BorderTopColor: var(--pf-v5-global--BorderColor--300);
  --pf-v5-c-menu-toggle--before--BorderRightColor: var(--pf-v5-global--BorderColor--300);
  --pf-v5-c-menu-toggle--before--BorderBottomColor: transparent;
  --pf-v5-c-menu-toggle--before--BorderLeftColor: var(--pf-v5-global--BorderColor--300);
  --pf-v5-c-menu-toggle--after--BorderBottomWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-menu-toggle--after--BorderBottomColor: var(--pf-v5-global--BorderColor--200);
  --pf-v5-c-menu-toggle--hover--BackgroundColor: transparent;
  --pf-v5-c-menu-toggle--hover--after--BorderBottomWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-menu-toggle--hover--after--BorderBottomColor: var(--pf-v5-global--active-color--100);
  --pf-v5-c-menu-toggle--focus--BackgroundColor: transparent;
  --pf-v5-c-menu-toggle--focus--after--BorderBottomWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-menu-toggle--focus--after--BorderBottomColor: var(--pf-v5-global--active-color--100);
  --pf-v5-c-menu-toggle--active--BackgroundColor: transparent;
  --pf-v5-c-menu-toggle--active--after--BorderBottomWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-menu-toggle--active--after--BorderBottomColor: var(--pf-v5-global--active-color--100);
  --pf-v5-c-menu-toggle--m-expanded--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-menu-toggle--m-expanded--BackgroundColor: transparent;
  --pf-v5-c-menu-toggle--m-expanded--after--BorderBottomWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-menu-toggle--m-expanded--after--BorderBottomColor: var(--pf-v5-global--active-color--100);
  --pf-v5-c-menu-toggle--disabled--Color: var(--pf-v5-global--disabled-color--100);
  --pf-v5-c-menu-toggle--disabled--BackgroundColor: var(--pf-v5-global--disabled-color--300);
  --pf-v5-c-menu-toggle--m-primary--Color: var(--pf-v5-global--Color--light-100);
  --pf-v5-c-menu-toggle--m-primary--BorderRadius: var(--pf-v5-global--BorderRadius--sm);
  --pf-v5-c-menu-toggle--m-primary--BackgroundColor: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-menu-toggle--m-primary--hover--BackgroundColor: var(--pf-v5-global--primary-color--200);
  --pf-v5-c-menu-toggle--m-primary--focus--BackgroundColor: var(--pf-v5-global--primary-color--200);
  --pf-v5-c-menu-toggle--m-primary--active--BackgroundColor: var(--pf-v5-global--primary-color--200);
  --pf-v5-c-menu-toggle--m-primary--m-expanded--BackgroundColor: var(--pf-v5-global--primary-color--200);
  --pf-v5-c-menu-toggle--m-primary--m-expanded--Color: var(--pf-v5-global--Color--light-100);
  --pf-v5-c-menu-toggle--m-secondary--Color: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-menu-toggle--m-secondary--BorderRadius: var(--pf-v5-global--BorderRadius--sm);
  --pf-v5-c-menu-toggle--m-secondary--BackgroundColor: transparent;
  --pf-v5-c-menu-toggle--m-secondary--hover--BackgroundColor: transparent;
  --pf-v5-c-menu-toggle--m-secondary--focus--BackgroundColor: transparent;
  --pf-v5-c-menu-toggle--m-secondary--active--BackgroundColor: transparent;
  --pf-v5-c-menu-toggle--m-secondary--before--BorderWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-menu-toggle--m-secondary--hover--before--BorderWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-menu-toggle--m-secondary--focus--before--BorderWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-menu-toggle--m-secondary--active--before--BorderWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-menu-toggle--m-secondary--before--BorderColor: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-menu-toggle--m-secondary--hover--before--BorderColor: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-menu-toggle--m-secondary--focus--before--BorderColor: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-menu-toggle--m-secondary--active--before--BorderColor: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-menu-toggle--m-secondary--m-expanded--BackgroundColor: transparent;
  --pf-v5-c-menu-toggle--m-secondary--m-expanded--Color: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-menu-toggle--m-expanded__toggle--m-secondary--before--BorderColor: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-menu-toggle--m-expanded__toggle--m-secondary--before--BorderWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-menu-toggle--m-plain--Color: var(--pf-v5-global--Color--200);
  --pf-v5-c-menu-toggle--m-plain--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-menu-toggle--m-plain--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-menu-toggle--m-plain--hover--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-menu-toggle--m-plain--focus--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-menu-toggle--m-plain--active--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-menu-toggle--m-plain--disabled--Color: var(--pf-v5-global--disabled-color--200);
  --pf-v5-c-menu-toggle--m-plain--m-expanded--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-menu-toggle__icon--MarginRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-menu-toggle__count--MarginLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-menu-toggle__controls--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-menu-toggle__controls--MarginLeft: auto;
  --pf-v5-c-menu-toggle__controls--MarginRight: 0;
  --pf-v5-c-menu-toggle__toggle-icon--MarginRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-menu-toggle--m-plain__toggle-icon--Color: var(--pf-v5-global--Color--200);
  --pf-v5-c-menu-toggle--m-plain--hover__toggle-icon--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-menu-toggle--m-plain--active__toggle-icon--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-menu-toggle--m-plain--focus__toggle-icon--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-menu-toggle--m-plain--m-expanded__toggle-icon--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-menu-toggle--m-full-height--PaddingRight: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-menu-toggle--m-full-height--PaddingLeft: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-menu-toggle--m-full-height__toggle--before--BorderTopWidth: 0;
  --pf-v5-c-menu-toggle--m-full-height__toggle--after--BorderBottomWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-menu-toggle--m-full-height--m-expanded--after--BorderBottomWidth: var(--pf-v5-global--BorderWidth--xl);
  --pf-v5-c-menu-toggle--m-full-height--hover--after--BorderBottomWidth: var(--pf-v5-global--BorderWidth--xl);
  --pf-v5-c-menu-toggle--m-full-height--focus--after--BorderBottomWidth: var(--pf-v5-global--BorderWidth--xl);
  --pf-v5-c-menu-toggle--m-full-height--active--after--BorderBottomWidth: var(--pf-v5-global--BorderWidth--xl);
  --pf-v5-c-menu-toggle--m-split-button--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-menu-toggle--m-split-button--child--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-menu-toggle--m-split-button--child--disabled--Color: var(--pf-v5-global--disabled-color--100);
  --pf-v5-c-menu-toggle--m-split-button--child--disabled--BackgroundColor: var(--pf-v5-global--disabled-color--300);
  --pf-v5-c-menu-toggle--m-split-button--first-child--PaddingRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-menu-toggle--m-split-button--last-child--PaddingLeft: 0;
  --pf-v5-c-menu-toggle--m-split-button--m-action--child--BorderLeftWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-menu-toggle--m-split-button--m-action--child--BorderLeftColor: var(--pf-v5-global--BorderColor--300);
  --pf-v5-c-menu-toggle--m-split-button--m-action--child--after--Left: 0;
  --pf-v5-c-menu-toggle--m-split-button--m-action--child--after--BorderBottomWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-menu-toggle--m-split-button--m-action--child--after--BorderBottomColor: var(--pf-v5-global--BorderColor--200);
  --pf-v5-c-menu-toggle--m-split-button--m-action--child--hover--after--BorderBottomWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-menu-toggle--m-split-button--m-action--child--hover--after--BorderBottomColor: var(--pf-v5-global--active-color--100);
  --pf-v5-c-menu-toggle--m-split-button--m-action--child--focus--after--BorderBottomWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-menu-toggle--m-split-button--m-action--child--focus--after--BorderBottomColor: var(--pf-v5-global--active-color--100);
  --pf-v5-c-menu-toggle--m-split-button--m-action--child--active--after--BorderBottomWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-menu-toggle--m-split-button--m-action--child--active--after--BorderBottomColor: var(--pf-v5-global--active-color--100);
  --pf-v5-c-menu-toggle--m-split-button--m-action--child--BorderRadius: var(--pf-v5-global--BorderRadius--sm);
  --pf-v5-c-menu-toggle--m-split-button--m-action--m-primary--child--BackgroundColor: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-menu-toggle--m-split-button--m-action--m-primary--child--hover--BackgroundColor: var(--pf-v5-global--primary-color--200);
  --pf-v5-c-menu-toggle--m-split-button--m-action--m-primary--child--focus--BackgroundColor: var(--pf-v5-global--primary-color--200);
  --pf-v5-c-menu-toggle--m-split-button--m-action--m-primary--child--active--BackgroundColor: var(--pf-v5-global--primary-color--200);
  --pf-v5-c-menu-toggle--m-split-button--m-action--m-primary--child--BorderLeftColor: var(--pf-v5-global--primary-color--200);
  --pf-v5-c-menu-toggle--m-split-button--m-action--m-primary--m-expanded--child--BackgroundColor: var(--pf-v5-global--primary-color--200);
  --pf-v5-c-menu-toggle--m-split-button--m-action--m-secondary--child--BorderLeftColor: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-menu-toggle__button--BackgroundColor: transparent;
  --pf-v5-c-menu-toggle__button--AlignSelf: baseline;
  --pf-v5-c-menu-toggle__button--PaddingLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-menu-toggle__button--PaddingRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-menu-toggle__button__controls--MarginRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-menu-toggle__button__controls--MarginLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-menu-toggle__button--m-text--PaddingInlineStart: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-menu-toggle--m-typeahead__controls--MarginRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-menu-toggle--m-typeahead__controls--MarginLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-menu-toggle--m-typeahead--c-text-input-group__utilities--c-button--PaddingRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-menu-toggle--m-typeahead__button--AlignSelf: center;
  --pf-v5-c-menu-toggle__status-icon--MarginInlineEnd: var(--pf-v5-global--spacer--md);
  --pf-v5-c-menu-toggle--m-success--after--BorderBottomWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-menu-toggle--m-success--after--BorderBottomColor: var(--pf-v5-global--success-color--100);
  --pf-v5-c-menu-toggle--m-success__status-icon--Color: var(--pf-v5-global--success-color--100);
  --pf-v5-c-menu-toggle--m-warning--after--BorderBottomWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-menu-toggle--m-warning--after--BorderBottomColor: var(--pf-v5-global--warning-color--100);
  --pf-v5-c-menu-toggle--m-warning__status-icon--Color: var(--pf-v5-global--warning-color--100);
  --pf-v5-c-menu-toggle--m-danger--after--BorderBottomWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-menu-toggle--m-danger--after--BorderBottomColor: var(--pf-v5-global--danger-color--100);
  --pf-v5-c-menu-toggle--m-danger__status-icon--Color: var(--pf-v5-global--danger-color--100);
  position: relative;
  display: inline-flex;
  align-items: center;
  max-width: 100%;
  padding-block-start: var(--pf-v5-c-menu-toggle--PaddingTop);
  padding-block-end: var(--pf-v5-c-menu-toggle--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-menu-toggle--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-menu-toggle--PaddingRight);
  font-size: var(--pf-v5-c-menu-toggle--FontSize);
  line-height: var(--pf-v5-c-menu-toggle--LineHeight);
  color: var(--pf-v5-c-menu-toggle--Color);
  cursor: pointer;
  background-color: var(--pf-v5-c-menu-toggle--BackgroundColor);
  border: 0;
  border-radius: var(--pf-v5-c-menu-toggle--BorderRadius);
}
.pf-v5-c-menu-toggle::before, .pf-v5-c-menu-toggle::after {
  position: absolute;
  inset-block-start: 0;
  inset-block-end: 0;
  inset-inline-start: 0;
  inset-inline-end: 0;
  pointer-events: none;
  content: "";
}
.pf-v5-c-menu-toggle::before {
  border-style: solid;
  border-block-start-color: var(--pf-v5-c-menu-toggle--before--BorderTopColor);
  border-block-start-width: var(--pf-v5-c-menu-toggle--before--BorderTopWidth);
  border-block-end-color: var(--pf-v5-c-menu-toggle--before--BorderBottomColor);
  border-block-end-width: var(--pf-v5-c-menu-toggle--before--BorderBottomWidth);
  border-inline-start-color: var(--pf-v5-c-menu-toggle--before--BorderLeftColor);
  border-inline-start-width: var(--pf-v5-c-menu-toggle--before--BorderLeftWidth);
  border-inline-end-color: var(--pf-v5-c-menu-toggle--before--BorderRightColor);
  border-inline-end-width: var(--pf-v5-c-menu-toggle--before--BorderRightWidth);
}
.pf-v5-c-menu-toggle::after {
  border-block-end: var(--pf-v5-c-menu-toggle--after--BorderBottomWidth) solid var(--pf-v5-c-menu-toggle--after--BorderBottomColor);
}
.pf-v5-c-menu-toggle.pf-m-primary {
  --pf-v5-c-menu-toggle--BorderRadius: var(--pf-v5-c-menu-toggle--m-primary--BorderRadius);
  --pf-v5-c-menu-toggle--Color: var(--pf-v5-c-menu-toggle--m-primary--Color);
  --pf-v5-c-menu-toggle--BackgroundColor: var(--pf-v5-c-menu-toggle--m-primary--BackgroundColor);
  --pf-v5-c-menu-toggle--hover--BackgroundColor: var(--pf-v5-c-menu-toggle--m-primary--hover--BackgroundColor);
  --pf-v5-c-menu-toggle--focus--BackgroundColor: var(--pf-v5-c-menu-toggle--m-primary--focus--BackgroundColor);
  --pf-v5-c-menu-toggle--active--BackgroundColor: var(--pf-v5-c-menu-toggle--m-primary--active--BackgroundColor);
  --pf-v5-c-menu-toggle--m-expanded--Color: var(--pf-v5-c-menu-toggle--m-primary--m-expanded--Color);
  --pf-v5-c-menu-toggle--m-expanded--BackgroundColor: var(--pf-v5-c-menu-toggle--m-primary--m-expanded--BackgroundColor);
}
.pf-v5-c-menu-toggle.pf-m-primary, .pf-v5-c-menu-toggle.pf-m-primary::before {
  border-radius: var(--pf-v5-c-menu-toggle--m-primary--BorderRadius);
}
.pf-v5-c-menu-toggle.pf-m-secondary {
  --pf-v5-c-menu-toggle--Color: var(--pf-v5-c-menu-toggle--m-secondary--Color);
  --pf-v5-c-menu-toggle--BackgroundColor: var(--pf-v5-c-menu-toggle--m-secondary--BackgroundColor);
  --pf-v5-c-menu-toggle--hover--BackgroundColor: var(--pf-v5-c-menu-toggle--m-secondary--hover--BackgroundColor);
  --pf-v5-c-menu-toggle--focus--BackgroundColor: var(--pf-v5-c-menu-toggle--m-secondary--focus--BackgroundColor);
  --pf-v5-c-menu-toggle--active--BackgroundColor: var(--pf-v5-c-menu-toggle--m-secondary--active--BackgroundColor);
  --pf-v5-c-menu-toggle--m-expanded--BackgroundColor: var(--pf-v5-c-menu-toggle--m-secondary--m-expanded--BackgroundColor);
  --pf-v5-c-menu-toggle--m-expanded--Color: var(--pf-v5-c-menu-toggle--m-secondary--m-expanded--Color);
}
.pf-v5-c-menu-toggle.pf-m-secondary, .pf-v5-c-menu-toggle.pf-m-secondary::before {
  border-radius: var(--pf-v5-c-menu-toggle--m-secondary--BorderRadius);
}
.pf-v5-c-menu-toggle.pf-m-secondary::before {
  border-color: var(--pf-v5-c-menu-toggle--m-secondary--before--BorderColor);
  border-width: var(--pf-v5-c-menu-toggle--m-secondary--before--BorderWidth);
}
.pf-v5-c-menu-toggle.pf-m-secondary::after {
  border: 0;
}
.pf-v5-c-menu-toggle.pf-m-secondary:hover {
  --pf-v5-c-menu-toggle--m-secondary--before--BorderColor: var(--pf-v5-c-menu-toggle--m-secondary--hover--before--BorderColor);
  --pf-v5-c-menu-toggle--m-secondary--before--BorderWidth: var(--pf-v5-c-menu-toggle--m-secondary--hover--before--BorderWidth);
}
.pf-v5-c-menu-toggle.pf-m-secondary:focus {
  --pf-v5-c-menu-toggle--m-secondary--before--BorderColor: var(--pf-v5-c-menu-toggle--m-secondary--focus--before--BorderColor);
  --pf-v5-c-menu-toggle--m-secondary--before--BorderWidth: var(--pf-v5-c-menu-toggle--m-secondary--focus--before--BorderWidth);
}
.pf-v5-c-menu-toggle.pf-m-secondary:active {
  --pf-v5-c-menu-toggle--m-secondary--before--BorderColor: var(--pf-v5-c-menu-toggle--m-secondary--active--before--BorderColor);
  --pf-v5-c-menu-toggle--m-secondary--before--BorderWidth: var(--pf-v5-c-menu-toggle--m-secondary--active--before--BorderWidth);
}
.pf-v5-c-menu-toggle.pf-m-secondary.pf-m-expanded {
  --pf-v5-c-menu-toggle--m-secondary--before--BorderColor: var(--pf-v5-c-menu-toggle--m-expanded__toggle--m-secondary--before--BorderColor);
  --pf-v5-c-menu-toggle--m-secondary--before--BorderWidth: var(--pf-v5-c-menu-toggle--m-expanded__toggle--m-secondary--before--BorderWidth);
}
.pf-v5-c-menu-toggle.pf-m-plain {
  --pf-v5-c-menu-toggle__toggle-icon--Color: var(--pf-v5-c-menu-toggle--m-plain__toggle-icon--Color);
}
.pf-v5-c-menu-toggle.pf-m-plain:not(.pf-m-text) {
  --pf-v5-c-menu-toggle--PaddingRight: var(--pf-v5-c-menu-toggle--m-plain--PaddingRight);
  --pf-v5-c-menu-toggle--PaddingLeft: var(--pf-v5-c-menu-toggle--m-plain--PaddingLeft);
  --pf-v5-c-menu-toggle--disabled--BackgroundColor: transparent;
  display: inline-block;
  color: var(--pf-v5-c-menu-toggle--m-plain--Color);
}
.pf-v5-c-menu-toggle.pf-m-full-height {
  --pf-v5-c-menu-toggle--PaddingRight: var(--pf-v5-c-menu-toggle--m-full-height--PaddingRight);
  --pf-v5-c-menu-toggle--PaddingLeft: var(--pf-v5-c-menu-toggle--m-full-height--PaddingLeft);
  --pf-v5-c-menu-toggle--before--BorderTopWidth: var(--pf-v5-c-menu-toggle--m-full-height__toggle--before--BorderTopWidth);
  --pf-v5-c-menu-toggle--after--BorderBottomWidth: var(--pf-v5-c-menu-toggle--m-full-height__toggle--after--BorderBottomWidth);
  --pf-v5-c-menu-toggle--m-expanded--after--BorderBottomWidth: var(--pf-v5-c-menu-toggle--m-full-height--m-expanded--after--BorderBottomWidth);
  --pf-v5-c-menu-toggle--hover--after--BorderBottomWidth: var(--pf-v5-c-menu-toggle--m-full-height--hover--after--BorderBottomWidth);
  --pf-v5-c-menu-toggle--focus--after--BorderBottomWidth: var(--pf-v5-c-menu-toggle--m-full-height--focus--after--BorderBottomWidth);
  --pf-v5-c-menu-toggle--active--after--BorderBottomWidth: var(--pf-v5-c-menu-toggle--m-full-height--active--after--BorderBottomWidth);
  align-items: center;
  height: 100%;
}
.pf-v5-c-menu-toggle:hover {
  --pf-v5-c-menu-toggle--BackgroundColor: var(--pf-v5-c-menu-toggle--hover--BackgroundColor);
  --pf-v5-c-menu-toggle--after--BorderBottomWidth: var(--pf-v5-c-menu-toggle--hover--after--BorderBottomWidth);
  --pf-v5-c-menu-toggle--after--BorderBottomColor: var(--pf-v5-c-menu-toggle--hover--after--BorderBottomColor);
  --pf-v5-c-menu-toggle--m-plain__toggle-icon--Color: var(--pf-v5-c-menu-toggle--m-plain--hover__toggle-icon--Color);
  --pf-v5-c-menu-toggle--m-plain--Color: var(--pf-v5-c-menu-toggle--m-plain--hover--Color);
}
.pf-v5-c-menu-toggle:focus, .pf-v5-c-menu-toggle:focus-within {
  --pf-v5-c-menu-toggle--BackgroundColor: var(--pf-v5-c-menu-toggle--focus--BackgroundColor);
  --pf-v5-c-menu-toggle--after--BorderBottomWidth: var(--pf-v5-c-menu-toggle--focus--after--BorderBottomWidth);
  --pf-v5-c-menu-toggle--after--BorderBottomColor: var(--pf-v5-c-menu-toggle--focus--after--BorderBottomColor);
  --pf-v5-c-menu-toggle--m-plain__toggle-icon--Color: var(--pf-v5-c-menu-toggle--m-plain--focus__toggle-icon--Color);
  --pf-v5-c-menu-toggle--m-plain--Color: var(--pf-v5-c-menu-toggle--m-plain--focus--Color);
}
.pf-v5-c-menu-toggle:active {
  --pf-v5-c-menu-toggle--BackgroundColor: var(--pf-v5-c-menu-toggle--active--BackgroundColor);
  --pf-v5-c-menu-toggle--after--BorderBottomWidth: var(--pf-v5-c-menu-toggle--active--after--BorderBottomWidth);
  --pf-v5-c-menu-toggle--after--BorderBottomColor: var(--pf-v5-c-menu-toggle--active--after--BorderBottomColor);
  --pf-v5-c-menu-toggle--m-plain__toggle-icon--Color: var(--pf-v5-c-menu-toggle--m-plain--active__toggle-icon--Color);
  --pf-v5-c-menu-toggle--m-plain--Color: var(--pf-v5-c-menu-toggle--m-plain--active--Color);
}
.pf-v5-c-menu-toggle.pf-m-expanded {
  --pf-v5-c-menu-toggle--Color: var(--pf-v5-c-menu-toggle--m-expanded--Color);
  --pf-v5-c-menu-toggle--BackgroundColor: var(--pf-v5-c-menu-toggle--m-expanded--BackgroundColor);
  --pf-v5-c-menu-toggle--after--BorderBottomWidth: var(--pf-v5-c-menu-toggle--m-expanded--after--BorderBottomWidth);
  --pf-v5-c-menu-toggle--after--BorderBottomColor: var(--pf-v5-c-menu-toggle--m-expanded--after--BorderBottomColor);
  --pf-v5-c-menu-toggle--m-plain__toggle-icon--Color: var(--pf-v5-c-menu-toggle--m-plain--m-expanded__toggle-icon--Color);
  --pf-v5-c-menu-toggle--m-plain--Color: var(--pf-v5-c-menu-toggle--m-plain--m-expanded--Color);
}
.pf-v5-c-menu-toggle:disabled, .pf-v5-c-menu-toggle.pf-m-disabled {
  --pf-v5-c-menu-toggle--Color: var(--pf-v5-c-menu-toggle--disabled--Color);
  --pf-v5-c-menu-toggle--BackgroundColor: var(--pf-v5-c-menu-toggle--disabled--BackgroundColor);
  --pf-v5-c-menu-toggle--m-plain--Color: var(--pf-v5-c-menu-toggle--m-plain--disabled--Color);
  pointer-events: none;
}
.pf-v5-c-menu-toggle.pf-m-primary::before, .pf-v5-c-menu-toggle.pf-m-primary::after, .pf-v5-c-menu-toggle.pf-m-plain::before, .pf-v5-c-menu-toggle.pf-m-plain::after, .pf-v5-c-menu-toggle:disabled::before, .pf-v5-c-menu-toggle:disabled::after {
  border: 0;
}
.pf-v5-c-menu-toggle.pf-m-typeahead {
  --pf-v5-c-menu-toggle__button__controls--MarginRight: var(--pf-v5-c-menu-toggle--m-typeahead__controls--MarginRight);
  --pf-v5-c-menu-toggle__button__controls--MarginLeft: var(--pf-v5-c-menu-toggle--m-typeahead__controls--MarginLeft);
  --pf-v5-c-menu-toggle__button--AlignSelf: var(--pf-v5-c-menu-toggle--m-typeahead__button--AlignSelf);
  align-items: stretch;
  padding: 0;
}
.pf-v5-c-menu-toggle.pf-m-typeahead .pf-v5-c-text-input-group {
  --pf-v5-c-text-input-group__utilities--c-button--PaddingRight: var(--pf-v5-c-menu-toggle--m-typeahead--c-text-input-group__utilities--c-button--PaddingRight);
  --pf-v5-c-text-input-group__utilities--MarginRight: 0;
  flex: 1;
}
.pf-v5-c-menu-toggle.pf-m-split-button {
  padding: 0;
}
.pf-v5-c-menu-toggle.pf-m-split-button > * {
  position: relative;
  padding-block-start: var(--pf-v5-c-menu-toggle--PaddingTop);
  padding-block-end: var(--pf-v5-c-menu-toggle--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-menu-toggle--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-menu-toggle--PaddingRight);
}
.pf-v5-c-menu-toggle.pf-m-split-button > *:first-child {
  padding-inline-end: var(--pf-v5-c-menu-toggle--m-split-button--first-child--PaddingRight);
}
.pf-v5-c-menu-toggle.pf-m-split-button:where(:not(.pf-m-action)) > :last-child {
  padding-inline-start: var(--pf-v5-c-menu-toggle--m-split-button--last-child--PaddingLeft);
}
.pf-v5-c-menu-toggle.pf-m-split-button > .pf-v5-c-check {
  --pf-v5-c-menu-toggle--PaddingRight: 0;
  --pf-v5-c-check__label--Color: currentcolor;
  --pf-v5-c-check__label--disabled--Color: currentcolor;
  align-items: center;
  align-self: stretch;
}
.pf-v5-c-menu-toggle.pf-m-split-button > .pf-v5-c-check .pf-v5-c-check__input {
  --pf-v5-c-check__input--TranslateY: 0;
  align-self: center;
}
.pf-v5-c-menu-toggle.pf-m-split-button.pf-m-action:where(:not(.pf-m-primary, .pf-m-secondary, .pf-m-disabled)) > *::after {
  position: absolute;
  inset-block-start: 0;
  inset-block-end: 0;
  inset-inline-start: var(--pf-v5-c-menu-toggle--m-split-button--m-action--child--after--Left);
  inset-inline-end: 0;
  pointer-events: none;
  content: "";
  border-block-end: var(--pf-v5-c-menu-toggle--m-split-button--m-action--child--after--BorderBottomWidth) solid var(--pf-v5-c-menu-toggle--m-split-button--m-action--child--after--BorderBottomColor);
}
.pf-v5-c-menu-toggle.pf-m-split-button.pf-m-action:where(:not(.pf-m-primary, .pf-m-secondary, .pf-m-disabled)) > *:hover {
  --pf-v5-c-menu-toggle--m-split-button--m-action--child--after--BorderBottomWidth: var(--pf-v5-c-menu-toggle--m-split-button--m-action--child--hover--after--BorderBottomWidth);
  --pf-v5-c-menu-toggle--m-split-button--m-action--child--after--BorderBottomColor: var(--pf-v5-c-menu-toggle--m-split-button--m-action--child--hover--after--BorderBottomColor);
}
.pf-v5-c-menu-toggle.pf-m-split-button.pf-m-action:where(:not(.pf-m-primary, .pf-m-secondary, .pf-m-disabled)) > *:focus {
  --pf-v5-c-menu-toggle--m-split-button--m-action--child--after--BorderBottomWidth: var(--pf-v5-c-menu-toggle--m-split-button--m-action--child--focus--after--BorderBottomWidth);
  --pf-v5-c-menu-toggle--m-split-button--m-action--child--after--BorderBottomColor: var(--pf-v5-c-menu-toggle--m-split-button--m-action--child--focus--after--BorderBottomColor);
}
.pf-v5-c-menu-toggle.pf-m-split-button.pf-m-action:where(:not(.pf-m-primary, .pf-m-secondary, .pf-m-disabled)) > *:active, .pf-v5-c-menu-toggle.pf-m-split-button.pf-m-action:where(:not(.pf-m-primary, .pf-m-secondary, .pf-m-disabled)) > *.pf-m-active {
  --pf-v5-c-menu-toggle--m-split-button--m-action--child--after--BorderBottomWidth: var(--pf-v5-c-menu-toggle--m-split-button--m-action--child--active--after--BorderBottomWidth);
  --pf-v5-c-menu-toggle--m-split-button--m-action--child--after--BorderBottomColor: var(--pf-v5-c-menu-toggle--m-split-button--m-action--child--active--after--BorderBottomColor);
}
.pf-v5-c-menu-toggle.pf-m-split-button.pf-m-action.pf-m-primary {
  --pf-v5-c-menu-toggle--m-split-button--m-action--child--BorderLeftColor: var(--pf-v5-c-menu-toggle--m-split-button--m-action--m-primary--child--BorderLeftColor);
  --pf-v5-c-menu-toggle--m-split-button--m-action--child--after--BorderBottomWidth: 0;
}
.pf-v5-c-menu-toggle.pf-m-split-button.pf-m-action.pf-m-primary > :where(:not(.pf-m-disabled):not([disabled])) {
  background-color: var(--pf-v5-c-menu-toggle--m-split-button--m-action--m-primary--child--BackgroundColor);
}
.pf-v5-c-menu-toggle.pf-m-split-button.pf-m-action.pf-m-primary > :where(:not(.pf-m-disabled):not([disabled])):hover {
  --pf-v5-c-menu-toggle--m-split-button--m-action--m-primary--child--BackgroundColor: var(--pf-v5-c-menu-toggle--m-split-button--m-action--m-primary--child--hover--BackgroundColor);
}
.pf-v5-c-menu-toggle.pf-m-split-button.pf-m-action.pf-m-primary > :where(:not(.pf-m-disabled):not([disabled])):focus {
  --pf-v5-c-menu-toggle--m-split-button--m-action--m-primary--child--BackgroundColor: var(--pf-v5-c-menu-toggle--m-split-button--m-action--m-primary--child--focus--BackgroundColor);
}
.pf-v5-c-menu-toggle.pf-m-split-button.pf-m-action.pf-m-primary > :where(:not(.pf-m-disabled):not([disabled])):active, .pf-v5-c-menu-toggle.pf-m-split-button.pf-m-action.pf-m-primary > :where(:not(.pf-m-disabled):not([disabled])).pf-m-active {
  --pf-v5-c-menu-toggle--m-split-button--m-action--m-primary--child--BackgroundColor: var(--pf-v5-c-menu-toggle--m-split-button--m-action--m-primary--child--active--BackgroundColor);
}
.pf-v5-c-menu-toggle.pf-m-split-button.pf-m-action.pf-m-primary.pf-m-expanded {
  --pf-v5-c-menu-toggle--m-split-button--m-action--m-primary--child--BackgroundColor: var(--pf-v5-c-menu-toggle--m-split-button--m-action--m-primary--m-expanded--child--BackgroundColor);
}
.pf-v5-c-menu-toggle.pf-m-split-button.pf-m-action.pf-m-secondary {
  --pf-v5-c-menu-toggle--m-split-button--m-action--child--BorderLeftColor: var(--pf-v5-c-menu-toggle--m-split-button--m-action--m-secondary--child--BorderLeftColor);
}
.pf-v5-c-menu-toggle.pf-m-split-button.pf-m-action.pf-m-primary, .pf-v5-c-menu-toggle.pf-m-split-button.pf-m-action.pf-m-secondary {
  --pf-v5-c-menu-toggle--m-split-button--m-action--child--after--BorderBottomWidth: 0;
}
.pf-v5-c-menu-toggle.pf-m-split-button.pf-m-action.pf-m-primary > :first-child, .pf-v5-c-menu-toggle.pf-m-split-button.pf-m-action.pf-m-secondary > :first-child {
  border-start-start-radius: var(--pf-v5-c-menu-toggle--m-split-button--m-action--child--BorderRadius);
  border-end-start-radius: var(--pf-v5-c-menu-toggle--m-split-button--m-action--child--BorderRadius);
}
.pf-v5-c-menu-toggle.pf-m-split-button.pf-m-action.pf-m-primary > :last-child, .pf-v5-c-menu-toggle.pf-m-split-button.pf-m-action.pf-m-secondary > :last-child {
  border-start-end-radius: var(--pf-v5-c-menu-toggle--m-split-button--m-action--child--BorderRadius);
  border-end-end-radius: var(--pf-v5-c-menu-toggle--m-split-button--m-action--child--BorderRadius);
}
.pf-v5-c-menu-toggle.pf-m-split-button.pf-m-action > :not(:first-child) {
  --pf-v5-c-menu-toggle--m-split-button--m-action--child--after--Left: calc(var(--pf-v5-c-menu-toggle--m-split-button--m-action--child--BorderLeftWidth) * -1);
  border-inline-start: var(--pf-v5-c-menu-toggle--m-split-button--m-action--child--BorderLeftWidth) solid var(--pf-v5-c-menu-toggle--m-split-button--m-action--child--BorderLeftColor);
}
.pf-v5-c-menu-toggle.pf-m-split-button.pf-m-action:not(.pf-m-expanded) {
  --pf-v5-c-menu-toggle--after--BorderBottomColor: transparent;
}
.pf-v5-c-menu-toggle.pf-m-split-button.pf-m-disabled, .pf-v5-c-menu-toggle.pf-m-split-button:disabled {
  --pf-v5-c-menu-toggle--m-split-button--m-action--child--BorderLeftColor: transparent;
}
.pf-v5-c-menu-toggle.pf-m-split-button.pf-m-disabled::before, .pf-v5-c-menu-toggle.pf-m-split-button.pf-m-disabled::after, .pf-v5-c-menu-toggle.pf-m-split-button:disabled::before, .pf-v5-c-menu-toggle.pf-m-split-button:disabled::after {
  content: none;
}
.pf-v5-c-menu-toggle.pf-m-split-button > .pf-m-disabled,
.pf-v5-c-menu-toggle.pf-m-split-button > :disabled {
  --pf-v5-c-menu-toggle--m-split-button--m-action--child--BorderLeftColor: transparent;
  color: var(--pf-v5-c-menu-toggle--m-split-button--child--disabled--Color);
  background-color: var(--pf-v5-c-menu-toggle--m-split-button--child--disabled--BackgroundColor);
}
.pf-v5-c-menu-toggle.pf-m-typeahead, .pf-v5-c-menu-toggle.pf-m-split-button {
  --pf-v5-c-menu-toggle__toggle-icon--MarginRight: 0;
}
.pf-v5-c-menu-toggle.pf-m-full-width {
  width: 100%;
}
.pf-v5-c-menu-toggle.pf-m-success {
  --pf-v5-c-menu-toggle--after--BorderBottomWidth: var(--pf-v5-c-menu-toggle--m-success--after--BorderBottomWidth);
  --pf-v5-c-menu-toggle--after--BorderBottomColor: var(--pf-v5-c-menu-toggle--m-success--after--BorderBottomColor);
  --pf-v5-c-menu-toggle__status-icon--Color: var(--pf-v5-c-menu-toggle--m-success__status-icon--Color);
}
.pf-v5-c-menu-toggle.pf-m-warning {
  --pf-v5-c-menu-toggle--after--BorderBottomWidth: var(--pf-v5-c-menu-toggle--m-warning--after--BorderBottomWidth);
  --pf-v5-c-menu-toggle--after--BorderBottomColor: var(--pf-v5-c-menu-toggle--m-warning--after--BorderBottomColor);
  --pf-v5-c-menu-toggle__status-icon--Color: var(--pf-v5-c-menu-toggle--m-warning__status-icon--Color);
}
.pf-v5-c-menu-toggle.pf-m-danger {
  --pf-v5-c-menu-toggle--after--BorderBottomWidth: var(--pf-v5-c-menu-toggle--m-danger--after--BorderBottomWidth);
  --pf-v5-c-menu-toggle--after--BorderBottomColor: var(--pf-v5-c-menu-toggle--m-danger--after--BorderBottomColor);
  --pf-v5-c-menu-toggle__status-icon--Color: var(--pf-v5-c-menu-toggle--m-danger__status-icon--Color);
}

.pf-v5-c-menu-toggle__button {
  --pf-v5-c-menu-toggle__controls--PaddingLeft: 0;
  --pf-v5-c-menu-toggle__controls--MarginRight: var(--pf-v5-c-menu-toggle__button__controls--MarginRight);
  --pf-v5-c-menu-toggle__controls--MarginLeft: var(--pf-v5-c-menu-toggle__button__controls--MarginLeft);
  align-self: var(--pf-v5-c-menu-toggle__button--AlignSelf);
  padding-inline-start: var(--pf-v5-c-menu-toggle__button--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-menu-toggle__button--PaddingRight);
  color: inherit;
  background-color: var(--pf-v5-c-menu-toggle__button--BackgroundColor);
  border: 0;
}
.pf-v5-c-menu-toggle__button.pf-m-text {
  --pf-v5-c-menu-toggle--m-split-button--last-child--PaddingLeft: var(--pf-v5-c-menu-toggle__button--m-text--PaddingInlineStart);
  display: inline-flex;
  align-items: baseline;
  padding-inline-start: var(--pf-v5-c-menu-toggle__button--m-text--PaddingInlineStart);
}

.pf-v5-c-menu-toggle__icon {
  flex-shrink: 0;
  align-self: center;
  margin-inline-end: var(--pf-v5-c-menu-toggle__icon--MarginRight);
  line-height: 1;
}

.pf-v5-c-menu-toggle__text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 0;
}

.pf-v5-c-menu-toggle__count {
  display: flex;
  flex-wrap: nowrap;
  margin-inline-start: var(--pf-v5-c-menu-toggle__count--MarginLeft);
}

.pf-v5-c-menu-toggle__controls {
  display: flex;
  padding-inline-start: var(--pf-v5-c-menu-toggle__controls--PaddingLeft);
  margin-inline-start: var(--pf-v5-c-menu-toggle__controls--MarginLeft);
  margin-inline-end: var(--pf-v5-c-menu-toggle__controls--MarginRight);
}

.pf-v5-c-menu-toggle__toggle-icon {
  margin-inline-end: var(--pf-v5-c-menu-toggle__toggle-icon--MarginRight);
  color: var(--pf-v5-c-menu-toggle__toggle-icon--Color, inherit);
}

.pf-v5-c-menu-toggle__status-icon {
  margin-inline-end: var(--pf-v5-c-menu-toggle__status-icon--MarginInlineEnd);
  color: var(--pf-v5-c-menu-toggle__status-icon--Color, inherit);
}

:where(.pf-v5-theme-dark) .pf-v5-c-menu-toggle {
  --pf-v5-c-menu-toggle--BackgroundColor: var(--pf-v5-global--BackgroundColor--400);
  --pf-v5-c-menu-toggle--hover--BackgroundColor: var(--pf-v5-global--BackgroundColor--400);
  --pf-v5-c-menu-toggle--active--BackgroundColor: var(--pf-v5-global--BackgroundColor--400);
  --pf-v5-c-menu-toggle--focus--BackgroundColor: var(--pf-v5-global--BackgroundColor--400);
  --pf-v5-c-menu-toggle--m-expanded--BackgroundColor: var(--pf-v5-global--BackgroundColor--400);
  --pf-v5-c-menu-toggle--before--BorderTopColor: transparent;
  --pf-v5-c-menu-toggle--before--BorderRightColor: transparent;
  --pf-v5-c-menu-toggle--before--BorderBottomColor: transparent;
  --pf-v5-c-menu-toggle--before--BorderLeftColor: transparent;
  --pf-v5-c-menu-toggle--after--BorderBottomColor: var(--pf-v5-global--BorderColor--400);
  --pf-v5-c-menu-toggle--m-primary--BackgroundColor: var(--pf-v5-global--primary-color--300);
  --pf-v5-c-menu-toggle--disabled--BackgroundColor: var(--pf-v5-global--disabled-color--200);
  --pf-v5-c-menu-toggle--disabled--Color: var(--pf-v5-global--disabled-color--300);
  --pf-v5-c-menu-toggle--m-split-button--m-action--m-primary--child--BackgroundColor: var(--pf-v5-global--primary-color--300);
  --pf-v5-c-menu-toggle--m-split-button--child--disabled--Color: var(--pf-v5-global--disabled-color--300);
  --pf-v5-c-menu-toggle--m-split-button--child--disabled--BackgroundColor: var(--pf-v5-global--disabled-color--200);
}
:where(.pf-v5-theme-dark) .pf-v5-c-menu-toggle.pf-m-plain, :where(.pf-v5-theme-dark) .pf-v5-c-menu-toggle.pf-m-full-height {
  background: transparent;
}