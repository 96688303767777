@charset "UTF-8";
.pf-v5-c-skeleton {
  --pf-v5-c-skeleton--BackgroundColor: var(--pf-v5-global--palette--black-150);
  --pf-v5-c-skeleton--Width: auto;
  --pf-v5-c-skeleton--Height: auto;
  --pf-v5-c-skeleton--BorderRadius: var(--pf-v5-global--BorderRadius--sm);
  --pf-v5-c-skeleton--before--PaddingBottom: 0;
  --pf-v5-c-skeleton--before--Height: auto;
  --pf-v5-c-skeleton--before--Content: " ";
  --pf-v5-c-skeleton--after--LinearGradientAngle: 90deg;
  --pf-v5-c-skeleton--after--LinearGradientColorStop1: var(--pf-v5-global--palette--black-150);
  --pf-v5-c-skeleton--after--LinearGradientColorStop2: #ededed;
  --pf-v5-c-skeleton--after--LinearGradientColorStop3: var(--pf-v5-global--palette--black-150);
  --pf-v5-c-skeleton--after--TranslateX: -100%;
  --pf-v5-c-skeleton--after--AnimationName: pf-v5-c-skeleton-loading;
  --pf-v5-c-skeleton--after--AnimationDuration: 2s;
  --pf-v5-c-skeleton--after--AnimationIterationCount: infinite;
  --pf-v5-c-skeleton--after--AnimationTimingFunction: linear;
  --pf-v5-c-skeleton--after--AnimationDelay: .5s;
  --pf-v5-c-skeleton--m-circle--BorderRadius: var(--pf-v5-global--BorderRadius--lg);
  --pf-v5-c-skeleton--m-circle--before--PaddingBottom: 100%;
  --pf-v5-c-skeleton--m-text-4xl--Height: calc(var(--pf-v5-global--FontSize--4xl) * var(--pf-v5-global--LineHeight--sm));
  --pf-v5-c-skeleton--m-text-3xl--Height: calc(var(--pf-v5-global--FontSize--3xl) * var(--pf-v5-global--LineHeight--sm));
  --pf-v5-c-skeleton--m-text-2xl--Height: calc(var(--pf-v5-global--FontSize--2xl) * var(--pf-v5-global--LineHeight--sm));
  --pf-v5-c-skeleton--m-text-xl--Height: calc(var(--pf-v5-global--FontSize--xl) * var(--pf-v5-global--LineHeight--sm));
  --pf-v5-c-skeleton--m-text-lg--Height: calc(var(--pf-v5-global--FontSize--lg) * var(--pf-v5-global--LineHeight--md));
  --pf-v5-c-skeleton--m-text-md--Height: calc(var(--pf-v5-global--FontSize--md) * var(--pf-v5-global--LineHeight--md));
  --pf-v5-c-skeleton--m-text-sm--Height: calc(var(--pf-v5-global--FontSize--sm) * var(--pf-v5-global--LineHeight--md));
  --pf-v5-c-skeleton--m-width-sm--Width: 6.25rem;
  --pf-v5-c-skeleton--m-width-md--Width: 12.5rem;
  --pf-v5-c-skeleton--m-width-lg--Width: 18.75rem;
  --pf-v5-c-skeleton--m-width-25--Width: 25%;
  --pf-v5-c-skeleton--m-width-33--Width: calc(100% / 3);
  --pf-v5-c-skeleton--m-width-50--Width: 50%;
  --pf-v5-c-skeleton--m-width-66--Width: calc(100% / 3 * 2);
  --pf-v5-c-skeleton--m-width-75--Width: 75%;
  --pf-v5-c-skeleton--m-height-sm--Height: 6.25rem;
  --pf-v5-c-skeleton--m-height-md--Height: 12.5rem;
  --pf-v5-c-skeleton--m-height-lg--Height: 18.75rem;
  --pf-v5-c-skeleton--m-height-25--Height: 25%;
  --pf-v5-c-skeleton--m-height-33--Height: calc(100% / 3);
  --pf-v5-c-skeleton--m-height-50--Height: 50%;
  --pf-v5-c-skeleton--m-height-66--Height: calc(100% / 3 * 2);
  --pf-v5-c-skeleton--m-height-75--Height: 75%;
  --pf-v5-c-skeleton--m-height-100--Height: 100%;
  position: relative;
  width: var(--pf-v5-c-skeleton--Width);
  height: var(--pf-v5-c-skeleton--Height);
  overflow: hidden;
  background-color: var(--pf-v5-c-skeleton--BackgroundColor);
  border-radius: var(--pf-v5-c-skeleton--BorderRadius);
  transform: translate(0);
}
.pf-v5-c-skeleton::before {
  display: block;
  height: var(--pf-v5-c-skeleton--before--Height);
  padding-block-end: var(--pf-v5-c-skeleton--before--PaddingBottom);
  content: var(--pf-v5-c-skeleton--before--Content);
}
.pf-v5-c-skeleton::after {
  position: absolute;
  inset: 0;
  display: block;
  content: "";
  background: linear-gradient(var(--pf-v5-c-skeleton--after--LinearGradientAngle), var(--pf-v5-c-skeleton--after--LinearGradientColorStop1), var(--pf-v5-c-skeleton--after--LinearGradientColorStop2), var(--pf-v5-c-skeleton--after--LinearGradientColorStop3));
  transform: translate3d(var(--pf-v5-c-skeleton--after--TranslateX), 0, 0);
  animation: var(--pf-v5-c-skeleton--after--AnimationName) var(--pf-v5-c-skeleton--after--AnimationDuration) var(--pf-v5-c-skeleton--after--AnimationTimingFunction) var(--pf-v5-c-skeleton--after--AnimationDelay) var(--pf-v5-c-skeleton--after--AnimationIterationCount);
}
:where(.pf-v5-m-dir-rtl, [dir=rtl]) .pf-v5-c-skeleton::after {
  scale: -1 1;
}

.pf-v5-c-skeleton.pf-m-circle {
  --pf-v5-c-skeleton--BorderRadius: var(--pf-v5-c-skeleton--m-circle--BorderRadius);
}
.pf-v5-c-skeleton.pf-m-square, .pf-v5-c-skeleton.pf-m-circle {
  --pf-v5-c-skeleton--before--Height: 0;
  --pf-v5-c-skeleton--before--PaddingBottom: var(--pf-v5-c-skeleton--m-circle--before--PaddingBottom);
}
.pf-v5-c-skeleton.pf-m-width-sm {
  --pf-v5-c-skeleton--Width: var(--pf-v5-c-skeleton--m-width-sm--Width);
}
.pf-v5-c-skeleton.pf-m-width-md {
  --pf-v5-c-skeleton--Width: var(--pf-v5-c-skeleton--m-width-md--Width);
}
.pf-v5-c-skeleton.pf-m-width-lg {
  --pf-v5-c-skeleton--Width: var(--pf-v5-c-skeleton--m-width-lg--Width);
}
.pf-v5-c-skeleton.pf-m-width-25 {
  --pf-v5-c-skeleton--Width: var(--pf-v5-c-skeleton--m-width-25--Width);
}
.pf-v5-c-skeleton.pf-m-width-33 {
  --pf-v5-c-skeleton--Width: var(--pf-v5-c-skeleton--m-width-33--Width);
}
.pf-v5-c-skeleton.pf-m-width-50 {
  --pf-v5-c-skeleton--Width: var(--pf-v5-c-skeleton--m-width-50--Width);
}
.pf-v5-c-skeleton.pf-m-width-66 {
  --pf-v5-c-skeleton--Width: var(--pf-v5-c-skeleton--m-width-66--Width);
}
.pf-v5-c-skeleton.pf-m-width-75 {
  --pf-v5-c-skeleton--Width: var(--pf-v5-c-skeleton--m-width-75--Width);
}
.pf-v5-c-skeleton.pf-m-height-sm {
  --pf-v5-c-skeleton--Height: var(--pf-v5-c-skeleton--m-height-sm--Height);
}
.pf-v5-c-skeleton.pf-m-height-md {
  --pf-v5-c-skeleton--Height: var(--pf-v5-c-skeleton--m-height-md--Height);
}
.pf-v5-c-skeleton.pf-m-height-lg {
  --pf-v5-c-skeleton--Height: var(--pf-v5-c-skeleton--m-height-lg--Height);
}
.pf-v5-c-skeleton.pf-m-height-25 {
  --pf-v5-c-skeleton--Height: var(--pf-v5-c-skeleton--m-height-25--Height);
}
.pf-v5-c-skeleton.pf-m-height-33 {
  --pf-v5-c-skeleton--Height: var(--pf-v5-c-skeleton--m-height-33--Height);
}
.pf-v5-c-skeleton.pf-m-height-50 {
  --pf-v5-c-skeleton--Height: var(--pf-v5-c-skeleton--m-height-50--Height);
}
.pf-v5-c-skeleton.pf-m-height-66 {
  --pf-v5-c-skeleton--Height: var(--pf-v5-c-skeleton--m-height-66--Height);
}
.pf-v5-c-skeleton.pf-m-height-75 {
  --pf-v5-c-skeleton--Height: var(--pf-v5-c-skeleton--m-height-75--Height);
}
.pf-v5-c-skeleton.pf-m-height-100 {
  --pf-v5-c-skeleton--Height: var(--pf-v5-c-skeleton--m-height-100--Height);
}
.pf-v5-c-skeleton.pf-m-text-4xl {
  --pf-v5-c-skeleton--Height: var(--pf-v5-c-skeleton--m-text-4xl--Height);
}
.pf-v5-c-skeleton.pf-m-text-3xl {
  --pf-v5-c-skeleton--Height: var(--pf-v5-c-skeleton--m-text-3xl--Height);
}
.pf-v5-c-skeleton.pf-m-text-2xl {
  --pf-v5-c-skeleton--Height: var(--pf-v5-c-skeleton--m-text-2xl--Height);
}
.pf-v5-c-skeleton.pf-m-text-xl {
  --pf-v5-c-skeleton--Height: var(--pf-v5-c-skeleton--m-text-xl--Height);
}
.pf-v5-c-skeleton.pf-m-text-lg {
  --pf-v5-c-skeleton--Height: var(--pf-v5-c-skeleton--m-text-lg--Height);
}
.pf-v5-c-skeleton.pf-m-text-md {
  --pf-v5-c-skeleton--Height: var(--pf-v5-c-skeleton--m-text-md--Height);
}
.pf-v5-c-skeleton.pf-m-text-sm {
  --pf-v5-c-skeleton--Height: var(--pf-v5-c-skeleton--m-text-sm--Height);
}

@keyframes pf-v5-c-skeleton-loading {
  0% {
    transform: translateX(-100%);
  }
  60% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(100%);
  }
}
:where(.pf-v5-theme-dark) .pf-v5-c-skeleton {
  --pf-v5-c-skeleton--BackgroundColor: var(--pf-v5-global--palette--black-600);
  --pf-v5-c-skeleton--after--LinearGradientColorStop1: var(--pf-v5-global--palette--black-600);
  --pf-v5-c-skeleton--after--LinearGradientColorStop2: var(--pf-v5-global--palette--black-500);
  --pf-v5-c-skeleton--after--LinearGradientColorStop3: var(--pf-v5-global--palette--black-600);
}