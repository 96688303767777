.pf-v5-c-inline-edit {
  --pf-v5-c-inline-edit__group--item--MarginRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-inline-edit__action--c-button--m-valid--m-plain--Color: var(--pf-v5-global--link--Color);
  --pf-v5-c-inline-edit__action--c-button--m-valid--m-plain--hover--Color: var(--pf-v5-global--link--Color--hover);
  --pf-v5-c-inline-edit__action--m-icon-group--item--MarginRight: 0;
  --pf-v5-c-inline-edit__group--m-footer--MarginTop: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-inline-edit__label--m-bold--FontWeight: var(--pf-v5-global--FontWeight--bold);
}

.pf-v5-c-inline-edit__group {
  display: flex;
  align-items: baseline;
}
.pf-v5-c-inline-edit__group > * {
  margin-inline-end: var(--pf-v5-c-inline-edit__group--item--MarginRight);
}
.pf-v5-c-inline-edit__group.pf-m-icon-group {
  --pf-v5-c-inline-edit__group--item--MarginRight: var(--pf-v5-c-inline-edit__action--m-icon-group--item--MarginRight);
}
.pf-v5-c-inline-edit__group.pf-m-footer {
  margin-block-start: var(--pf-v5-c-inline-edit__group--m-footer--MarginTop);
}
.pf-v5-c-inline-edit__group.pf-m-column {
  --pf-v5-c-inline-edit__group--item--MarginRight: 0;
  flex-direction: column;
}
.pf-v5-c-inline-edit__group > :last-child {
  --pf-v5-c-inline-edit__group--item--MarginRight: 0;
}

.pf-v5-c-inline-edit__input {
  flex: 1;
}

.pf-v5-c-inline-edit__action.pf-m-valid .pf-v5-c-button.pf-m-plain {
  --pf-v5-c-button--m-plain--Color: var(--pf-v5-c-inline-edit__action--c-button--m-valid--m-plain--Color);
}
.pf-v5-c-inline-edit__action.pf-m-valid .pf-v5-c-button.pf-m-plain:hover {
  --pf-v5-c-button--m-plain--Color: var(--pf-v5-c-inline-edit__action--c-button--m-valid--m-plain--hover--Color);
}

.pf-v5-c-inline-edit__input,
.pf-v5-c-inline-edit__action,
.pf-v5-c-inline-edit__group.pf-m-action-group {
  display: none;
}

.pf-v5-c-inline-edit__action.pf-m-enable-editable {
  display: inline-block;
}

.pf-v5-c-inline-edit.pf-m-inline-editable .pf-v5-c-inline-edit__input,
.pf-v5-c-inline-edit .pf-m-inline-editable .pf-v5-c-inline-edit__input {
  display: block;
}
.pf-v5-c-inline-edit.pf-m-inline-editable .pf-v5-c-inline-edit__action,
.pf-v5-c-inline-edit .pf-m-inline-editable .pf-v5-c-inline-edit__action {
  display: inline-block;
}
.pf-v5-c-inline-edit.pf-m-inline-editable .pf-v5-c-inline-edit__group.pf-m-action-group,
.pf-v5-c-inline-edit .pf-m-inline-editable .pf-v5-c-inline-edit__group.pf-m-action-group {
  display: inline-flex;
}
.pf-v5-c-inline-edit.pf-m-inline-editable .pf-v5-c-inline-edit__value,
.pf-v5-c-inline-edit.pf-m-inline-editable .pf-v5-c-inline-edit__action.pf-m-enable-editable,
.pf-v5-c-inline-edit .pf-m-inline-editable .pf-v5-c-inline-edit__value,
.pf-v5-c-inline-edit .pf-m-inline-editable .pf-v5-c-inline-edit__action.pf-m-enable-editable {
  display: none;
}

.pf-v5-c-inline-edit__label + .pf-v5-c-inline-edit__action.pf-m-enable > .pf-v5-c-button {
  margin-block-start: calc(var(--pf-v5-c-button--PaddingTop) * -1);
  margin-block-end: calc(var(--pf-v5-c-button--PaddingBottom) * -1);
}

.pf-v5-c-inline-edit__label.pf-m-bold {
  font-weight: var(--pf-v5-c-inline-edit__label--m-bold--FontWeight);
}

.pf-v5-c-inline-edit__editable-text br {
  display: none;
}