.pf-v5-c-tab-content {
  --pf-v5-c-tab-content__body--PaddingTop: 0;
  --pf-v5-c-tab-content__body--PaddingRight: 0;
  --pf-v5-c-tab-content__body--PaddingBottom: 0;
  --pf-v5-c-tab-content__body--PaddingLeft: 0;
  --pf-v5-c-tab-content__body--m-padding--PaddingTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-tab-content__body--m-padding--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-tab-content__body--m-padding--PaddingBottom: var(--pf-v5-global--spacer--md);
  --pf-v5-c-tab-content__body--m-padding--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-tab-content__body--m-padding--xl--PaddingTop: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-tab-content__body--m-padding--xl--PaddingRight: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-tab-content__body--m-padding--xl--PaddingBottom: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-tab-content__body--m-padding--xl--PaddingLeft: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-tab-content--m-light-300: var(--pf-v5-global--BackgroundColor--light-300);
}
@media screen and (min-width: 1200px) {
  .pf-v5-c-tab-content {
    --pf-v5-c-tab-content__body--m-padding--PaddingTop: var(--pf-v5-c-tab-content__body--m-padding--xl--PaddingTop);
    --pf-v5-c-tab-content__body--m-padding--PaddingRight: var(--pf-v5-c-tab-content__body--m-padding--xl--PaddingRight);
    --pf-v5-c-tab-content__body--m-padding--PaddingBottom: var(--pf-v5-c-tab-content__body--m-padding--xl--PaddingBottom);
    --pf-v5-c-tab-content__body--m-padding--PaddingLeft: var(--pf-v5-c-tab-content__body--m-padding--xl--PaddingLeft);
  }
}
.pf-v5-c-tab-content.pf-m-light-300 {
  background-color: var(--pf-v5-c-tab-content--m-light-300);
}

.pf-v5-c-tab-content__body {
  padding-block-start: var(--pf-v5-c-tab-content__body--PaddingTop);
  padding-block-end: var(--pf-v5-c-tab-content__body--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-tab-content__body--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-tab-content__body--PaddingRight);
}
.pf-v5-c-tab-content__body.pf-m-padding {
  --pf-v5-c-tab-content__body--PaddingTop: var(--pf-v5-c-tab-content__body--m-padding--PaddingTop);
  --pf-v5-c-tab-content__body--PaddingRight: var(--pf-v5-c-tab-content__body--m-padding--PaddingRight);
  --pf-v5-c-tab-content__body--PaddingBottom: var(--pf-v5-c-tab-content__body--m-padding--PaddingBottom);
  --pf-v5-c-tab-content__body--PaddingLeft: var(--pf-v5-c-tab-content__body--m-padding--PaddingLeft);
}