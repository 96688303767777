.pf-v5-c-table {
  --pf-v5-c-table__tree-view-main--indent--base: calc(var(--pf-v5-global--spacer--md) * 2 + var(--pf-v5-c-table__tree-view-icon--MinWidth));
  --pf-v5-c-table__tree-view-main--nested-indent--base: calc(var(--pf-v5-c-table__tree-view-main--indent--base) - var(--pf-v5-global--spacer--md));
  --pf-v5-c-table__tree-view-main--PaddingLeft: var(--pf-v5-c-table__tree-view-main--indent--base);
  --pf-v5-c-table__tree-view-main--MarginLeft: calc(var(--pf-v5-c-table--cell--PaddingLeft) * -1);
  --pf-v5-c-table__tree-view-main--c-table__check--PaddingRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-table__tree-view-main--c-table__check--PaddingLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-table__tree-view-main--c-table__check--MarginRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-table__tree-view-icon--MinWidth: var(--pf-v5-global--FontSize--md);
  --pf-v5-c-table__tree-view-icon--MarginRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-table--m-tree-view__toggle--Position: absolute;
  --pf-v5-c-table--m-tree-view__toggle--Left: var(--pf-v5-c-table__tree-view-main--PaddingLeft);
  --pf-v5-c-table--m-tree-view__toggle--TranslateX: -100%;
  --pf-v5-c-table--m-tree-view__toggle__toggle-icon--MinWidth: var(--pf-v5-global--FontSize--md);
  --pf-v5-c-table--m-no-inset__tree-view-main--PaddingLeft: 0;
  --pf-v5-c-table--m-no-inset__tree-view-main--MarginLeft: 0;
}
.pf-v5-c-table.pf-m-tree-view > tbody > tr {
  --pf-v5-c-table--m-tree-view__toggle--Left: var(--pf-v5-c-table__tree-view-main--PaddingLeft);
}
.pf-v5-c-table.pf-m-tree-view > tbody > tr.pf-m-no-inset {
  --pf-v5-c-table__tree-view-main--PaddingLeft: var(--pf-v5-c-table--m-no-inset__tree-view-main--PaddingLeft);
  --pf-v5-c-table__tree-view-main--MarginLeft: var(--pf-v5-c-table--m-no-inset__tree-view-main--MarginLeft);
}
.pf-v5-c-table.pf-m-tree-view.pf-m-no-inset {
  --pf-v5-c-table__tree-view-main--PaddingLeft: var(--pf-v5-c-table--m-no-inset__tree-view-main--PaddingLeft);
  --pf-v5-c-table__tree-view-main--MarginLeft: var(--pf-v5-c-table--m-no-inset__tree-view-main--MarginLeft);
}
.pf-v5-c-table.pf-m-tree-view tr:where(.pf-v5-c-table__tr)[aria-level="2"] {
  --pf-v5-c-table__tree-view-main--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 1 + var(--pf-v5-c-table__tree-view-main--indent--base));
}
.pf-v5-c-table.pf-m-tree-view tr:where(.pf-v5-c-table__tr)[aria-level="3"] {
  --pf-v5-c-table__tree-view-main--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 2 + var(--pf-v5-c-table__tree-view-main--indent--base));
}
.pf-v5-c-table.pf-m-tree-view tr:where(.pf-v5-c-table__tr)[aria-level="4"] {
  --pf-v5-c-table__tree-view-main--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 3 + var(--pf-v5-c-table__tree-view-main--indent--base));
}
.pf-v5-c-table.pf-m-tree-view tr:where(.pf-v5-c-table__tr)[aria-level="5"] {
  --pf-v5-c-table__tree-view-main--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 4 + var(--pf-v5-c-table__tree-view-main--indent--base));
}
.pf-v5-c-table.pf-m-tree-view tr:where(.pf-v5-c-table__tr)[aria-level="6"] {
  --pf-v5-c-table__tree-view-main--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 5 + var(--pf-v5-c-table__tree-view-main--indent--base));
}
.pf-v5-c-table.pf-m-tree-view tr:where(.pf-v5-c-table__tr)[aria-level="7"] {
  --pf-v5-c-table__tree-view-main--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 6 + var(--pf-v5-c-table__tree-view-main--indent--base));
}
.pf-v5-c-table.pf-m-tree-view tr:where(.pf-v5-c-table__tr)[aria-level="8"] {
  --pf-v5-c-table__tree-view-main--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 7 + var(--pf-v5-c-table__tree-view-main--indent--base));
}
.pf-v5-c-table.pf-m-tree-view tr:where(.pf-v5-c-table__tr)[aria-level="9"] {
  --pf-v5-c-table__tree-view-main--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 8 + var(--pf-v5-c-table__tree-view-main--indent--base));
}
.pf-v5-c-table.pf-m-tree-view tr:where(.pf-v5-c-table__tr)[aria-level="10"] {
  --pf-v5-c-table__tree-view-main--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 9 + var(--pf-v5-c-table__tree-view-main--indent--base));
}

.pf-v5-c-table__tree-view-main {
  position: relative;
  display: flex;
  align-items: baseline;
  min-width: 0;
  padding-inline-start: var(--pf-v5-c-table__tree-view-main--PaddingLeft);
  margin-inline-start: var(--pf-v5-c-table__tree-view-main--MarginLeft);
  text-align: start;
  cursor: pointer;
}
.pf-v5-c-table__tree-view-main > .pf-v5-c-table__toggle {
  transform: translateX(var(--pf-v5-c-table--m-tree-view__toggle--TranslateX));
  position: var(--pf-v5-c-table--m-tree-view__toggle--Position);
  inset-inline-start: var(--pf-v5-c-table--m-tree-view__toggle--Left);
}
:where(.pf-v5-m-dir-rtl, [dir=rtl]) .pf-v5-c-table__tree-view-main > .pf-v5-c-table__toggle {
  transform: translateX(calc(var(--pf-v5-c-table--m-tree-view__toggle--TranslateX) * var(--pf-v5-global--inverse--multiplier)));
}

.pf-v5-c-table__tree-view-main > .pf-v5-c-table__toggle .pf-v5-c-table__toggle-icon {
  min-width: var(--pf-v5-c-table--m-tree-view__toggle__toggle-icon--MinWidth);
}
.pf-v5-c-table__tree-view-main > .pf-v5-c-table__check {
  margin-inline-end: var(--pf-v5-c-table__tree-view-main--c-table__check--MarginRight);
}
.pf-v5-c-table__tree-view-main > .pf-v5-c-table__check label {
  padding-inline-start: var(--pf-v5-c-table__tree-view-main--c-table__check--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-table__tree-view-main--c-table__check--PaddingRight);
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: calc(var(--pf-v5-c-table__tree-view-main--c-table__check--MarginRight) * -1);
}

.pf-v5-c-table__tree-view-text {
  display: flex;
}

.pf-v5-c-table__tree-view-icon {
  min-width: var(--pf-v5-c-table__tree-view-icon--MinWidth);
  margin-inline-end: var(--pf-v5-c-table__tree-view-icon--MarginRight);
}

.pf-v5-c-table__tree-view-details-toggle {
  display: none;
}

.pf-m-tree-view-grid.pf-v5-c-table {
  --pf-v5-c-table--m-tree-view-grid--tr--OutlineOffset: calc(-1 * var(--pf-v5-global--spacer--xs));
  --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingBottom: var(--pf-v5-global--spacer--md);
  --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: var(--pf-v5-c-table__tree-view-main--indent--base);
  --pf-v5-c-table--m-tree-view-grid__tbody--cell--GridColumnGap: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-table--m-tree-view-grid--c-table__action--PaddingTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-table--m-tree-view-grid--c-table__action--PaddingBottom: var(--pf-v5-global--spacer--md);
  --pf-v5-c-table--m-tree-view-grid--c-table__action--PaddingLeft: 0;
  --pf-v5-c-table--m-tree-view-grid__tr--expanded__tree-view-title-cell--action--PaddingTop: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-table--m-tree-view-grid--m-tree-view-details-expanded--PaddingBottom: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-table--m-tree-view-grid__tr--expanded__tree-view-title-cell--PaddingTop: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-table--m-tree-view-grid__td--data-label--GridTemplateColumns: repeat(auto-fit, minmax(150px, 1fr));
  --pf-v5-c-table--m-tree-view-grid__td--not--c-table__tree-view-title-cell--PaddingTop: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-table--m-tree-view-grid__td--not--c-table__tree-view-title-cell--PaddingBottom: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-table--m-tree-view-mobile__tree-view-main--c-table__check--MarginRight: 0;
  --pf-v5-c-table--m-tree-view-mobile__tree-view-main--c-table__check--Order: 4;
  --pf-v5-c-table__tree-view-text--PaddingRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-table__tbody--cell--PaddingTop: var(--pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingTop);
  --pf-v5-c-table__tbody--cell--PaddingBottom: var(--pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingBottom);
  --pf-v5-c-table__tree-view-details-toggle--MarginTop: calc(0.375rem * -1);
  --pf-v5-c-table__tree-view-details-toggle--MarginBottom: calc(0.375rem * -1);
  --pf-v5-c-table--m-tree-view-grid--c-dropdown--MarginTop: calc(0.375rem * -1);
  --pf-v5-c-table--m-tree-view-grid--c-dropdown--MarginBottom: calc(0.375rem * -1);
}
.pf-m-tree-view-grid.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody) tr:where(.pf-v5-c-table__tr) {
  position: relative;
  border: none;
  outline-offset: var(--pf-v5-c-table--m-tree-view-grid--tr--OutlineOffset);
}
.pf-m-tree-view-grid.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody) tr:where(.pf-v5-c-table__tr)::before {
  position: absolute;
  inset-block-start: 0;
  inset-block-end: 0;
  inset-inline-start: var(--pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft);
  inset-inline-end: 0;
  content: "";
  border-block-end: var(--pf-v5-c-table--border-width--base) solid var(--pf-v5-c-table--BorderColor);
}
.pf-m-tree-view-grid.pf-v5-c-table tr:where(.pf-v5-c-table__tr):not([hidden]) {
  display: grid;
  grid-template-columns: 1fr max-content;
}
.pf-m-tree-view-grid.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-expanded] .pf-v5-c-table__tree-view-title-cell {
  --pf-v5-c-table--cell--PaddingTop: var(--pf-v5-c-table--m-tree-view-grid__tr--expanded__tree-view-title-cell--PaddingTop);
}
.pf-m-tree-view-grid.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-expanded] .pf-v5-c-table__tree-view-title-cell ~ .pf-v5-c-table__action {
  --pf-v5-c-table--cell--PaddingTop: var(--pf-v5-c-table--m-tree-view-grid__tr--expanded__tree-view-title-cell--action--PaddingTop);
}
.pf-m-tree-view-grid.pf-v5-c-table td:where(.pf-v5-c-table__td):not(.pf-v5-c-table__tree-view-title-cell) {
  --pf-v5-c-table--cell--PaddingTop: var(--pf-v5-c-table--m-tree-view-grid__td--not--c-table__tree-view-title-cell--PaddingTop);
  --pf-v5-c-table--cell--PaddingBottom: var(--pf-v5-c-table--m-tree-view-grid__td--not--c-table__tree-view-title-cell--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft);
}
.pf-m-tree-view-grid.pf-v5-c-table .pf-v5-c-table__tree-view-text {
  padding-inline-end: var(--pf-v5-c-table__tree-view-text--PaddingRight);
}
.pf-m-tree-view-grid.pf-v5-c-table thead:where(.pf-v5-c-table__thead) th:where(.pf-v5-c-table__th) {
  display: none;
}
.pf-m-tree-view-grid.pf-v5-c-table td:where(.pf-v5-c-table__td) {
  display: none;
}
.pf-m-tree-view-grid.pf-v5-c-table td:where(.pf-v5-c-table__td)[data-label] {
  grid-template-columns: var(--pf-v5-c-table--m-tree-view-grid__td--data-label--GridTemplateColumns);
  grid-column: 1;
  grid-column-gap: var(--pf-v5-c-table--m-tree-view-grid__tbody--cell--GridColumnGap);
  align-items: start;
}
.pf-m-tree-view-grid.pf-v5-c-table td:where(.pf-v5-c-table__td)[data-label] > * {
  grid-column: 2;
}
.pf-m-tree-view-grid.pf-v5-c-table td:where(.pf-v5-c-table__td)[data-label]::before {
  font-weight: var(--pf-v5-global--FontWeight--bold);
  text-align: start;
  content: attr(data-label);
}
.pf-m-tree-view-grid.pf-v5-c-table tr:where(.pf-v5-c-table__tr).pf-m-tree-view-details-expanded {
  padding-block-end: var(--pf-v5-c-table--m-tree-view-grid--m-tree-view-details-expanded--PaddingBottom);
}
.pf-m-tree-view-grid.pf-v5-c-table tr:where(.pf-v5-c-table__tr).pf-m-tree-view-details-expanded td:where(.pf-v5-c-table__td)[data-label] {
  display: grid;
}
.pf-m-tree-view-grid.pf-v5-c-table .pf-v5-c-table__tree-view-title-header-cell {
  display: block;
}
.pf-m-tree-view-grid.pf-v5-c-table .pf-v5-c-table__tree-view-details-toggle,
.pf-m-tree-view-grid.pf-v5-c-table .pf-v5-c-table__action {
  display: inline-block;
}
.pf-m-tree-view-grid.pf-v5-c-table .pf-v5-c-table__action {
  --pf-v5-c-table--cell--Width: auto;
  --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: var(--pf-v5-c-table--m-tree-view-grid--c-table__action--PaddingLeft);
  --pf-v5-c-table--m-tree-view-grid__td--not--c-table__tree-view-title-cell--PaddingTop: var(--pf-v5-c-table--m-tree-view-grid--c-table__action--PaddingTop);
  --pf-v5-c-table--m-tree-view-grid__td--not--c-table__tree-view-title-cell--PaddingBottom: var(--pf-v5-c-table--m-tree-view-grid--c-table__action--PaddingBottom);
  grid-row: 1;
  grid-column: 2;
}
.pf-m-tree-view-grid.pf-v5-c-table .pf-v5-c-table__action > .pf-v5-c-dropdown {
  margin-block-start: var(--pf-v5-c-table--m-tree-view-grid--c-dropdown--MarginTop);
  margin-block-end: var(--pf-v5-c-table--m-tree-view-grid--c-dropdown--MarginBottom);
}
.pf-m-tree-view-grid.pf-v5-c-table .pf-v5-c-table__tree-view-main > .pf-v5-c-table__check {
  order: var(--pf-v5-c-table--m-tree-view-mobile__tree-view-main--c-table__check--Order);
  margin-inline-start: auto;
  margin-inline-end: var(--pf-v5-c-table--m-tree-view-mobile__tree-view-main--c-table__check--MarginRight);
}
.pf-m-tree-view-grid.pf-v5-c-table .pf-v5-c-table__tree-view-details-toggle {
  margin-block-start: var(--pf-v5-c-table__tree-view-details-toggle--MarginTop);
  margin-block-end: var(--pf-v5-c-table__tree-view-details-toggle--MarginBottom);
}
.pf-m-tree-view-grid.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="2"] {
  --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 1 + var(--pf-v5-c-table__tree-view-main--indent--base));
}
.pf-m-tree-view-grid.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="3"] {
  --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 2 + var(--pf-v5-c-table__tree-view-main--indent--base));
}
.pf-m-tree-view-grid.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="4"] {
  --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 3 + var(--pf-v5-c-table__tree-view-main--indent--base));
}
.pf-m-tree-view-grid.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="5"] {
  --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 4 + var(--pf-v5-c-table__tree-view-main--indent--base));
}
.pf-m-tree-view-grid.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="6"] {
  --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 5 + var(--pf-v5-c-table__tree-view-main--indent--base));
}
.pf-m-tree-view-grid.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="7"] {
  --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 6 + var(--pf-v5-c-table__tree-view-main--indent--base));
}
.pf-m-tree-view-grid.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="8"] {
  --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 7 + var(--pf-v5-c-table__tree-view-main--indent--base));
}
.pf-m-tree-view-grid.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="9"] {
  --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 8 + var(--pf-v5-c-table__tree-view-main--indent--base));
}
.pf-m-tree-view-grid.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="10"] {
  --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 9 + var(--pf-v5-c-table__tree-view-main--indent--base));
}

@media screen and (max-width: 768px) {
  .pf-m-tree-view-grid-md.pf-v5-c-table {
    --pf-v5-c-table--m-tree-view-grid--tr--OutlineOffset: calc(-1 * var(--pf-v5-global--spacer--xs));
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingTop: var(--pf-v5-global--spacer--md);
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingBottom: var(--pf-v5-global--spacer--md);
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: var(--pf-v5-c-table__tree-view-main--indent--base);
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--GridColumnGap: var(--pf-v5-global--spacer--sm);
    --pf-v5-c-table--m-tree-view-grid--c-table__action--PaddingTop: var(--pf-v5-global--spacer--md);
    --pf-v5-c-table--m-tree-view-grid--c-table__action--PaddingBottom: var(--pf-v5-global--spacer--md);
    --pf-v5-c-table--m-tree-view-grid--c-table__action--PaddingLeft: 0;
    --pf-v5-c-table--m-tree-view-grid__tr--expanded__tree-view-title-cell--action--PaddingTop: var(--pf-v5-global--spacer--xl);
    --pf-v5-c-table--m-tree-view-grid--m-tree-view-details-expanded--PaddingBottom: var(--pf-v5-global--spacer--xl);
    --pf-v5-c-table--m-tree-view-grid__tr--expanded__tree-view-title-cell--PaddingTop: var(--pf-v5-global--spacer--xl);
    --pf-v5-c-table--m-tree-view-grid__td--data-label--GridTemplateColumns: repeat(auto-fit, minmax(150px, 1fr));
    --pf-v5-c-table--m-tree-view-grid__td--not--c-table__tree-view-title-cell--PaddingTop: var(--pf-v5-global--spacer--xs);
    --pf-v5-c-table--m-tree-view-grid__td--not--c-table__tree-view-title-cell--PaddingBottom: var(--pf-v5-global--spacer--xs);
    --pf-v5-c-table--m-tree-view-mobile__tree-view-main--c-table__check--MarginRight: 0;
    --pf-v5-c-table--m-tree-view-mobile__tree-view-main--c-table__check--Order: 4;
    --pf-v5-c-table__tree-view-text--PaddingRight: var(--pf-v5-global--spacer--sm);
    --pf-v5-c-table__tbody--cell--PaddingTop: var(--pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingTop);
    --pf-v5-c-table__tbody--cell--PaddingBottom: var(--pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingBottom);
    --pf-v5-c-table__tree-view-details-toggle--MarginTop: calc(0.375rem * -1);
    --pf-v5-c-table__tree-view-details-toggle--MarginBottom: calc(0.375rem * -1);
    --pf-v5-c-table--m-tree-view-grid--c-dropdown--MarginTop: calc(0.375rem * -1);
    --pf-v5-c-table--m-tree-view-grid--c-dropdown--MarginBottom: calc(0.375rem * -1);
  }
  .pf-m-tree-view-grid-md.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody) tr:where(.pf-v5-c-table__tr) {
    position: relative;
    border: none;
    outline-offset: var(--pf-v5-c-table--m-tree-view-grid--tr--OutlineOffset);
  }
  .pf-m-tree-view-grid-md.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody) tr:where(.pf-v5-c-table__tr)::before {
    position: absolute;
    inset-block-start: 0;
    inset-block-end: 0;
    inset-inline-start: var(--pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft);
    inset-inline-end: 0;
    content: "";
    border-block-end: var(--pf-v5-c-table--border-width--base) solid var(--pf-v5-c-table--BorderColor);
  }
  .pf-m-tree-view-grid-md.pf-v5-c-table tr:where(.pf-v5-c-table__tr):not([hidden]) {
    display: grid;
    grid-template-columns: 1fr max-content;
  }
  .pf-m-tree-view-grid-md.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-expanded] .pf-v5-c-table__tree-view-title-cell {
    --pf-v5-c-table--cell--PaddingTop: var(--pf-v5-c-table--m-tree-view-grid__tr--expanded__tree-view-title-cell--PaddingTop);
  }
  .pf-m-tree-view-grid-md.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-expanded] .pf-v5-c-table__tree-view-title-cell ~ .pf-v5-c-table__action {
    --pf-v5-c-table--cell--PaddingTop: var(--pf-v5-c-table--m-tree-view-grid__tr--expanded__tree-view-title-cell--action--PaddingTop);
  }
  .pf-m-tree-view-grid-md.pf-v5-c-table td:where(.pf-v5-c-table__td):not(.pf-v5-c-table__tree-view-title-cell) {
    --pf-v5-c-table--cell--PaddingTop: var(--pf-v5-c-table--m-tree-view-grid__td--not--c-table__tree-view-title-cell--PaddingTop);
    --pf-v5-c-table--cell--PaddingBottom: var(--pf-v5-c-table--m-tree-view-grid__td--not--c-table__tree-view-title-cell--PaddingBottom);
    padding-inline-start: var(--pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft);
  }
  .pf-m-tree-view-grid-md.pf-v5-c-table .pf-v5-c-table__tree-view-text {
    padding-inline-end: var(--pf-v5-c-table__tree-view-text--PaddingRight);
  }
  .pf-m-tree-view-grid-md.pf-v5-c-table thead:where(.pf-v5-c-table__thead) th:where(.pf-v5-c-table__th) {
    display: none;
  }
  .pf-m-tree-view-grid-md.pf-v5-c-table td:where(.pf-v5-c-table__td) {
    display: none;
  }
  .pf-m-tree-view-grid-md.pf-v5-c-table td:where(.pf-v5-c-table__td)[data-label] {
    grid-template-columns: var(--pf-v5-c-table--m-tree-view-grid__td--data-label--GridTemplateColumns);
    grid-column: 1;
    grid-column-gap: var(--pf-v5-c-table--m-tree-view-grid__tbody--cell--GridColumnGap);
    align-items: start;
  }
  .pf-m-tree-view-grid-md.pf-v5-c-table td:where(.pf-v5-c-table__td)[data-label] > * {
    grid-column: 2;
  }
  .pf-m-tree-view-grid-md.pf-v5-c-table td:where(.pf-v5-c-table__td)[data-label]::before {
    font-weight: var(--pf-v5-global--FontWeight--bold);
    text-align: start;
    content: attr(data-label);
  }
  .pf-m-tree-view-grid-md.pf-v5-c-table tr:where(.pf-v5-c-table__tr).pf-m-tree-view-details-expanded {
    padding-block-end: var(--pf-v5-c-table--m-tree-view-grid--m-tree-view-details-expanded--PaddingBottom);
  }
  .pf-m-tree-view-grid-md.pf-v5-c-table tr:where(.pf-v5-c-table__tr).pf-m-tree-view-details-expanded td:where(.pf-v5-c-table__td)[data-label] {
    display: grid;
  }
  .pf-m-tree-view-grid-md.pf-v5-c-table .pf-v5-c-table__tree-view-title-header-cell {
    display: block;
  }
  .pf-m-tree-view-grid-md.pf-v5-c-table .pf-v5-c-table__tree-view-details-toggle,
  .pf-m-tree-view-grid-md.pf-v5-c-table .pf-v5-c-table__action {
    display: inline-block;
  }
  .pf-m-tree-view-grid-md.pf-v5-c-table .pf-v5-c-table__action {
    --pf-v5-c-table--cell--Width: auto;
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: var(--pf-v5-c-table--m-tree-view-grid--c-table__action--PaddingLeft);
    --pf-v5-c-table--m-tree-view-grid__td--not--c-table__tree-view-title-cell--PaddingTop: var(--pf-v5-c-table--m-tree-view-grid--c-table__action--PaddingTop);
    --pf-v5-c-table--m-tree-view-grid__td--not--c-table__tree-view-title-cell--PaddingBottom: var(--pf-v5-c-table--m-tree-view-grid--c-table__action--PaddingBottom);
    grid-row: 1;
    grid-column: 2;
  }
  .pf-m-tree-view-grid-md.pf-v5-c-table .pf-v5-c-table__action > .pf-v5-c-dropdown {
    margin-block-start: var(--pf-v5-c-table--m-tree-view-grid--c-dropdown--MarginTop);
    margin-block-end: var(--pf-v5-c-table--m-tree-view-grid--c-dropdown--MarginBottom);
  }
  .pf-m-tree-view-grid-md.pf-v5-c-table .pf-v5-c-table__tree-view-main > .pf-v5-c-table__check {
    order: var(--pf-v5-c-table--m-tree-view-mobile__tree-view-main--c-table__check--Order);
    margin-inline-start: auto;
    margin-inline-end: var(--pf-v5-c-table--m-tree-view-mobile__tree-view-main--c-table__check--MarginRight);
  }
  .pf-m-tree-view-grid-md.pf-v5-c-table .pf-v5-c-table__tree-view-details-toggle {
    margin-block-start: var(--pf-v5-c-table__tree-view-details-toggle--MarginTop);
    margin-block-end: var(--pf-v5-c-table__tree-view-details-toggle--MarginBottom);
  }
  .pf-m-tree-view-grid-md.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="2"] {
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 1 + var(--pf-v5-c-table__tree-view-main--indent--base));
  }
  .pf-m-tree-view-grid-md.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="3"] {
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 2 + var(--pf-v5-c-table__tree-view-main--indent--base));
  }
  .pf-m-tree-view-grid-md.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="4"] {
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 3 + var(--pf-v5-c-table__tree-view-main--indent--base));
  }
  .pf-m-tree-view-grid-md.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="5"] {
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 4 + var(--pf-v5-c-table__tree-view-main--indent--base));
  }
  .pf-m-tree-view-grid-md.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="6"] {
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 5 + var(--pf-v5-c-table__tree-view-main--indent--base));
  }
  .pf-m-tree-view-grid-md.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="7"] {
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 6 + var(--pf-v5-c-table__tree-view-main--indent--base));
  }
  .pf-m-tree-view-grid-md.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="8"] {
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 7 + var(--pf-v5-c-table__tree-view-main--indent--base));
  }
  .pf-m-tree-view-grid-md.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="9"] {
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 8 + var(--pf-v5-c-table__tree-view-main--indent--base));
  }
  .pf-m-tree-view-grid-md.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="10"] {
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 9 + var(--pf-v5-c-table__tree-view-main--indent--base));
  }
}

@media screen and (max-width: 992px) {
  .pf-m-tree-view-grid-lg.pf-v5-c-table {
    --pf-v5-c-table--m-tree-view-grid--tr--OutlineOffset: calc(-1 * var(--pf-v5-global--spacer--xs));
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingTop: var(--pf-v5-global--spacer--md);
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingBottom: var(--pf-v5-global--spacer--md);
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: var(--pf-v5-c-table__tree-view-main--indent--base);
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--GridColumnGap: var(--pf-v5-global--spacer--sm);
    --pf-v5-c-table--m-tree-view-grid--c-table__action--PaddingTop: var(--pf-v5-global--spacer--md);
    --pf-v5-c-table--m-tree-view-grid--c-table__action--PaddingBottom: var(--pf-v5-global--spacer--md);
    --pf-v5-c-table--m-tree-view-grid--c-table__action--PaddingLeft: 0;
    --pf-v5-c-table--m-tree-view-grid__tr--expanded__tree-view-title-cell--action--PaddingTop: var(--pf-v5-global--spacer--xl);
    --pf-v5-c-table--m-tree-view-grid--m-tree-view-details-expanded--PaddingBottom: var(--pf-v5-global--spacer--xl);
    --pf-v5-c-table--m-tree-view-grid__tr--expanded__tree-view-title-cell--PaddingTop: var(--pf-v5-global--spacer--xl);
    --pf-v5-c-table--m-tree-view-grid__td--data-label--GridTemplateColumns: repeat(auto-fit, minmax(150px, 1fr));
    --pf-v5-c-table--m-tree-view-grid__td--not--c-table__tree-view-title-cell--PaddingTop: var(--pf-v5-global--spacer--xs);
    --pf-v5-c-table--m-tree-view-grid__td--not--c-table__tree-view-title-cell--PaddingBottom: var(--pf-v5-global--spacer--xs);
    --pf-v5-c-table--m-tree-view-mobile__tree-view-main--c-table__check--MarginRight: 0;
    --pf-v5-c-table--m-tree-view-mobile__tree-view-main--c-table__check--Order: 4;
    --pf-v5-c-table__tree-view-text--PaddingRight: var(--pf-v5-global--spacer--sm);
    --pf-v5-c-table__tbody--cell--PaddingTop: var(--pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingTop);
    --pf-v5-c-table__tbody--cell--PaddingBottom: var(--pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingBottom);
    --pf-v5-c-table__tree-view-details-toggle--MarginTop: calc(0.375rem * -1);
    --pf-v5-c-table__tree-view-details-toggle--MarginBottom: calc(0.375rem * -1);
    --pf-v5-c-table--m-tree-view-grid--c-dropdown--MarginTop: calc(0.375rem * -1);
    --pf-v5-c-table--m-tree-view-grid--c-dropdown--MarginBottom: calc(0.375rem * -1);
  }
  .pf-m-tree-view-grid-lg.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody) tr:where(.pf-v5-c-table__tr) {
    position: relative;
    border: none;
    outline-offset: var(--pf-v5-c-table--m-tree-view-grid--tr--OutlineOffset);
  }
  .pf-m-tree-view-grid-lg.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody) tr:where(.pf-v5-c-table__tr)::before {
    position: absolute;
    inset-block-start: 0;
    inset-block-end: 0;
    inset-inline-start: var(--pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft);
    inset-inline-end: 0;
    content: "";
    border-block-end: var(--pf-v5-c-table--border-width--base) solid var(--pf-v5-c-table--BorderColor);
  }
  .pf-m-tree-view-grid-lg.pf-v5-c-table tr:where(.pf-v5-c-table__tr):not([hidden]) {
    display: grid;
    grid-template-columns: 1fr max-content;
  }
  .pf-m-tree-view-grid-lg.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-expanded] .pf-v5-c-table__tree-view-title-cell {
    --pf-v5-c-table--cell--PaddingTop: var(--pf-v5-c-table--m-tree-view-grid__tr--expanded__tree-view-title-cell--PaddingTop);
  }
  .pf-m-tree-view-grid-lg.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-expanded] .pf-v5-c-table__tree-view-title-cell ~ .pf-v5-c-table__action {
    --pf-v5-c-table--cell--PaddingTop: var(--pf-v5-c-table--m-tree-view-grid__tr--expanded__tree-view-title-cell--action--PaddingTop);
  }
  .pf-m-tree-view-grid-lg.pf-v5-c-table td:where(.pf-v5-c-table__td):not(.pf-v5-c-table__tree-view-title-cell) {
    --pf-v5-c-table--cell--PaddingTop: var(--pf-v5-c-table--m-tree-view-grid__td--not--c-table__tree-view-title-cell--PaddingTop);
    --pf-v5-c-table--cell--PaddingBottom: var(--pf-v5-c-table--m-tree-view-grid__td--not--c-table__tree-view-title-cell--PaddingBottom);
    padding-inline-start: var(--pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft);
  }
  .pf-m-tree-view-grid-lg.pf-v5-c-table .pf-v5-c-table__tree-view-text {
    padding-inline-end: var(--pf-v5-c-table__tree-view-text--PaddingRight);
  }
  .pf-m-tree-view-grid-lg.pf-v5-c-table thead:where(.pf-v5-c-table__thead) th:where(.pf-v5-c-table__th) {
    display: none;
  }
  .pf-m-tree-view-grid-lg.pf-v5-c-table td:where(.pf-v5-c-table__td) {
    display: none;
  }
  .pf-m-tree-view-grid-lg.pf-v5-c-table td:where(.pf-v5-c-table__td)[data-label] {
    grid-template-columns: var(--pf-v5-c-table--m-tree-view-grid__td--data-label--GridTemplateColumns);
    grid-column: 1;
    grid-column-gap: var(--pf-v5-c-table--m-tree-view-grid__tbody--cell--GridColumnGap);
    align-items: start;
  }
  .pf-m-tree-view-grid-lg.pf-v5-c-table td:where(.pf-v5-c-table__td)[data-label] > * {
    grid-column: 2;
  }
  .pf-m-tree-view-grid-lg.pf-v5-c-table td:where(.pf-v5-c-table__td)[data-label]::before {
    font-weight: var(--pf-v5-global--FontWeight--bold);
    text-align: start;
    content: attr(data-label);
  }
  .pf-m-tree-view-grid-lg.pf-v5-c-table tr:where(.pf-v5-c-table__tr).pf-m-tree-view-details-expanded {
    padding-block-end: var(--pf-v5-c-table--m-tree-view-grid--m-tree-view-details-expanded--PaddingBottom);
  }
  .pf-m-tree-view-grid-lg.pf-v5-c-table tr:where(.pf-v5-c-table__tr).pf-m-tree-view-details-expanded td:where(.pf-v5-c-table__td)[data-label] {
    display: grid;
  }
  .pf-m-tree-view-grid-lg.pf-v5-c-table .pf-v5-c-table__tree-view-title-header-cell {
    display: block;
  }
  .pf-m-tree-view-grid-lg.pf-v5-c-table .pf-v5-c-table__tree-view-details-toggle,
  .pf-m-tree-view-grid-lg.pf-v5-c-table .pf-v5-c-table__action {
    display: inline-block;
  }
  .pf-m-tree-view-grid-lg.pf-v5-c-table .pf-v5-c-table__action {
    --pf-v5-c-table--cell--Width: auto;
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: var(--pf-v5-c-table--m-tree-view-grid--c-table__action--PaddingLeft);
    --pf-v5-c-table--m-tree-view-grid__td--not--c-table__tree-view-title-cell--PaddingTop: var(--pf-v5-c-table--m-tree-view-grid--c-table__action--PaddingTop);
    --pf-v5-c-table--m-tree-view-grid__td--not--c-table__tree-view-title-cell--PaddingBottom: var(--pf-v5-c-table--m-tree-view-grid--c-table__action--PaddingBottom);
    grid-row: 1;
    grid-column: 2;
  }
  .pf-m-tree-view-grid-lg.pf-v5-c-table .pf-v5-c-table__action > .pf-v5-c-dropdown {
    margin-block-start: var(--pf-v5-c-table--m-tree-view-grid--c-dropdown--MarginTop);
    margin-block-end: var(--pf-v5-c-table--m-tree-view-grid--c-dropdown--MarginBottom);
  }
  .pf-m-tree-view-grid-lg.pf-v5-c-table .pf-v5-c-table__tree-view-main > .pf-v5-c-table__check {
    order: var(--pf-v5-c-table--m-tree-view-mobile__tree-view-main--c-table__check--Order);
    margin-inline-start: auto;
    margin-inline-end: var(--pf-v5-c-table--m-tree-view-mobile__tree-view-main--c-table__check--MarginRight);
  }
  .pf-m-tree-view-grid-lg.pf-v5-c-table .pf-v5-c-table__tree-view-details-toggle {
    margin-block-start: var(--pf-v5-c-table__tree-view-details-toggle--MarginTop);
    margin-block-end: var(--pf-v5-c-table__tree-view-details-toggle--MarginBottom);
  }
  .pf-m-tree-view-grid-lg.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="2"] {
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 1 + var(--pf-v5-c-table__tree-view-main--indent--base));
  }
  .pf-m-tree-view-grid-lg.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="3"] {
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 2 + var(--pf-v5-c-table__tree-view-main--indent--base));
  }
  .pf-m-tree-view-grid-lg.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="4"] {
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 3 + var(--pf-v5-c-table__tree-view-main--indent--base));
  }
  .pf-m-tree-view-grid-lg.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="5"] {
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 4 + var(--pf-v5-c-table__tree-view-main--indent--base));
  }
  .pf-m-tree-view-grid-lg.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="6"] {
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 5 + var(--pf-v5-c-table__tree-view-main--indent--base));
  }
  .pf-m-tree-view-grid-lg.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="7"] {
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 6 + var(--pf-v5-c-table__tree-view-main--indent--base));
  }
  .pf-m-tree-view-grid-lg.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="8"] {
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 7 + var(--pf-v5-c-table__tree-view-main--indent--base));
  }
  .pf-m-tree-view-grid-lg.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="9"] {
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 8 + var(--pf-v5-c-table__tree-view-main--indent--base));
  }
  .pf-m-tree-view-grid-lg.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="10"] {
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 9 + var(--pf-v5-c-table__tree-view-main--indent--base));
  }
}

@media screen and (max-width: 1200px) {
  .pf-m-tree-view-grid-xl.pf-v5-c-table {
    --pf-v5-c-table--m-tree-view-grid--tr--OutlineOffset: calc(-1 * var(--pf-v5-global--spacer--xs));
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingTop: var(--pf-v5-global--spacer--md);
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingBottom: var(--pf-v5-global--spacer--md);
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: var(--pf-v5-c-table__tree-view-main--indent--base);
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--GridColumnGap: var(--pf-v5-global--spacer--sm);
    --pf-v5-c-table--m-tree-view-grid--c-table__action--PaddingTop: var(--pf-v5-global--spacer--md);
    --pf-v5-c-table--m-tree-view-grid--c-table__action--PaddingBottom: var(--pf-v5-global--spacer--md);
    --pf-v5-c-table--m-tree-view-grid--c-table__action--PaddingLeft: 0;
    --pf-v5-c-table--m-tree-view-grid__tr--expanded__tree-view-title-cell--action--PaddingTop: var(--pf-v5-global--spacer--xl);
    --pf-v5-c-table--m-tree-view-grid--m-tree-view-details-expanded--PaddingBottom: var(--pf-v5-global--spacer--xl);
    --pf-v5-c-table--m-tree-view-grid__tr--expanded__tree-view-title-cell--PaddingTop: var(--pf-v5-global--spacer--xl);
    --pf-v5-c-table--m-tree-view-grid__td--data-label--GridTemplateColumns: repeat(auto-fit, minmax(150px, 1fr));
    --pf-v5-c-table--m-tree-view-grid__td--not--c-table__tree-view-title-cell--PaddingTop: var(--pf-v5-global--spacer--xs);
    --pf-v5-c-table--m-tree-view-grid__td--not--c-table__tree-view-title-cell--PaddingBottom: var(--pf-v5-global--spacer--xs);
    --pf-v5-c-table--m-tree-view-mobile__tree-view-main--c-table__check--MarginRight: 0;
    --pf-v5-c-table--m-tree-view-mobile__tree-view-main--c-table__check--Order: 4;
    --pf-v5-c-table__tree-view-text--PaddingRight: var(--pf-v5-global--spacer--sm);
    --pf-v5-c-table__tbody--cell--PaddingTop: var(--pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingTop);
    --pf-v5-c-table__tbody--cell--PaddingBottom: var(--pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingBottom);
    --pf-v5-c-table__tree-view-details-toggle--MarginTop: calc(0.375rem * -1);
    --pf-v5-c-table__tree-view-details-toggle--MarginBottom: calc(0.375rem * -1);
    --pf-v5-c-table--m-tree-view-grid--c-dropdown--MarginTop: calc(0.375rem * -1);
    --pf-v5-c-table--m-tree-view-grid--c-dropdown--MarginBottom: calc(0.375rem * -1);
  }
  .pf-m-tree-view-grid-xl.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody) tr:where(.pf-v5-c-table__tr) {
    position: relative;
    border: none;
    outline-offset: var(--pf-v5-c-table--m-tree-view-grid--tr--OutlineOffset);
  }
  .pf-m-tree-view-grid-xl.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody) tr:where(.pf-v5-c-table__tr)::before {
    position: absolute;
    inset-block-start: 0;
    inset-block-end: 0;
    inset-inline-start: var(--pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft);
    inset-inline-end: 0;
    content: "";
    border-block-end: var(--pf-v5-c-table--border-width--base) solid var(--pf-v5-c-table--BorderColor);
  }
  .pf-m-tree-view-grid-xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr):not([hidden]) {
    display: grid;
    grid-template-columns: 1fr max-content;
  }
  .pf-m-tree-view-grid-xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-expanded] .pf-v5-c-table__tree-view-title-cell {
    --pf-v5-c-table--cell--PaddingTop: var(--pf-v5-c-table--m-tree-view-grid__tr--expanded__tree-view-title-cell--PaddingTop);
  }
  .pf-m-tree-view-grid-xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-expanded] .pf-v5-c-table__tree-view-title-cell ~ .pf-v5-c-table__action {
    --pf-v5-c-table--cell--PaddingTop: var(--pf-v5-c-table--m-tree-view-grid__tr--expanded__tree-view-title-cell--action--PaddingTop);
  }
  .pf-m-tree-view-grid-xl.pf-v5-c-table td:where(.pf-v5-c-table__td):not(.pf-v5-c-table__tree-view-title-cell) {
    --pf-v5-c-table--cell--PaddingTop: var(--pf-v5-c-table--m-tree-view-grid__td--not--c-table__tree-view-title-cell--PaddingTop);
    --pf-v5-c-table--cell--PaddingBottom: var(--pf-v5-c-table--m-tree-view-grid__td--not--c-table__tree-view-title-cell--PaddingBottom);
    padding-inline-start: var(--pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft);
  }
  .pf-m-tree-view-grid-xl.pf-v5-c-table .pf-v5-c-table__tree-view-text {
    padding-inline-end: var(--pf-v5-c-table__tree-view-text--PaddingRight);
  }
  .pf-m-tree-view-grid-xl.pf-v5-c-table thead:where(.pf-v5-c-table__thead) th:where(.pf-v5-c-table__th) {
    display: none;
  }
  .pf-m-tree-view-grid-xl.pf-v5-c-table td:where(.pf-v5-c-table__td) {
    display: none;
  }
  .pf-m-tree-view-grid-xl.pf-v5-c-table td:where(.pf-v5-c-table__td)[data-label] {
    grid-template-columns: var(--pf-v5-c-table--m-tree-view-grid__td--data-label--GridTemplateColumns);
    grid-column: 1;
    grid-column-gap: var(--pf-v5-c-table--m-tree-view-grid__tbody--cell--GridColumnGap);
    align-items: start;
  }
  .pf-m-tree-view-grid-xl.pf-v5-c-table td:where(.pf-v5-c-table__td)[data-label] > * {
    grid-column: 2;
  }
  .pf-m-tree-view-grid-xl.pf-v5-c-table td:where(.pf-v5-c-table__td)[data-label]::before {
    font-weight: var(--pf-v5-global--FontWeight--bold);
    text-align: start;
    content: attr(data-label);
  }
  .pf-m-tree-view-grid-xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr).pf-m-tree-view-details-expanded {
    padding-block-end: var(--pf-v5-c-table--m-tree-view-grid--m-tree-view-details-expanded--PaddingBottom);
  }
  .pf-m-tree-view-grid-xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr).pf-m-tree-view-details-expanded td:where(.pf-v5-c-table__td)[data-label] {
    display: grid;
  }
  .pf-m-tree-view-grid-xl.pf-v5-c-table .pf-v5-c-table__tree-view-title-header-cell {
    display: block;
  }
  .pf-m-tree-view-grid-xl.pf-v5-c-table .pf-v5-c-table__tree-view-details-toggle,
  .pf-m-tree-view-grid-xl.pf-v5-c-table .pf-v5-c-table__action {
    display: inline-block;
  }
  .pf-m-tree-view-grid-xl.pf-v5-c-table .pf-v5-c-table__action {
    --pf-v5-c-table--cell--Width: auto;
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: var(--pf-v5-c-table--m-tree-view-grid--c-table__action--PaddingLeft);
    --pf-v5-c-table--m-tree-view-grid__td--not--c-table__tree-view-title-cell--PaddingTop: var(--pf-v5-c-table--m-tree-view-grid--c-table__action--PaddingTop);
    --pf-v5-c-table--m-tree-view-grid__td--not--c-table__tree-view-title-cell--PaddingBottom: var(--pf-v5-c-table--m-tree-view-grid--c-table__action--PaddingBottom);
    grid-row: 1;
    grid-column: 2;
  }
  .pf-m-tree-view-grid-xl.pf-v5-c-table .pf-v5-c-table__action > .pf-v5-c-dropdown {
    margin-block-start: var(--pf-v5-c-table--m-tree-view-grid--c-dropdown--MarginTop);
    margin-block-end: var(--pf-v5-c-table--m-tree-view-grid--c-dropdown--MarginBottom);
  }
  .pf-m-tree-view-grid-xl.pf-v5-c-table .pf-v5-c-table__tree-view-main > .pf-v5-c-table__check {
    order: var(--pf-v5-c-table--m-tree-view-mobile__tree-view-main--c-table__check--Order);
    margin-inline-start: auto;
    margin-inline-end: var(--pf-v5-c-table--m-tree-view-mobile__tree-view-main--c-table__check--MarginRight);
  }
  .pf-m-tree-view-grid-xl.pf-v5-c-table .pf-v5-c-table__tree-view-details-toggle {
    margin-block-start: var(--pf-v5-c-table__tree-view-details-toggle--MarginTop);
    margin-block-end: var(--pf-v5-c-table__tree-view-details-toggle--MarginBottom);
  }
  .pf-m-tree-view-grid-xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="2"] {
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 1 + var(--pf-v5-c-table__tree-view-main--indent--base));
  }
  .pf-m-tree-view-grid-xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="3"] {
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 2 + var(--pf-v5-c-table__tree-view-main--indent--base));
  }
  .pf-m-tree-view-grid-xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="4"] {
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 3 + var(--pf-v5-c-table__tree-view-main--indent--base));
  }
  .pf-m-tree-view-grid-xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="5"] {
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 4 + var(--pf-v5-c-table__tree-view-main--indent--base));
  }
  .pf-m-tree-view-grid-xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="6"] {
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 5 + var(--pf-v5-c-table__tree-view-main--indent--base));
  }
  .pf-m-tree-view-grid-xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="7"] {
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 6 + var(--pf-v5-c-table__tree-view-main--indent--base));
  }
  .pf-m-tree-view-grid-xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="8"] {
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 7 + var(--pf-v5-c-table__tree-view-main--indent--base));
  }
  .pf-m-tree-view-grid-xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="9"] {
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 8 + var(--pf-v5-c-table__tree-view-main--indent--base));
  }
  .pf-m-tree-view-grid-xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="10"] {
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 9 + var(--pf-v5-c-table__tree-view-main--indent--base));
  }
}

@media screen and (max-width: 1450px) {
  .pf-m-tree-view-grid-2xl.pf-v5-c-table {
    --pf-v5-c-table--m-tree-view-grid--tr--OutlineOffset: calc(-1 * var(--pf-v5-global--spacer--xs));
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingTop: var(--pf-v5-global--spacer--md);
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingBottom: var(--pf-v5-global--spacer--md);
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: var(--pf-v5-c-table__tree-view-main--indent--base);
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--GridColumnGap: var(--pf-v5-global--spacer--sm);
    --pf-v5-c-table--m-tree-view-grid--c-table__action--PaddingTop: var(--pf-v5-global--spacer--md);
    --pf-v5-c-table--m-tree-view-grid--c-table__action--PaddingBottom: var(--pf-v5-global--spacer--md);
    --pf-v5-c-table--m-tree-view-grid--c-table__action--PaddingLeft: 0;
    --pf-v5-c-table--m-tree-view-grid__tr--expanded__tree-view-title-cell--action--PaddingTop: var(--pf-v5-global--spacer--xl);
    --pf-v5-c-table--m-tree-view-grid--m-tree-view-details-expanded--PaddingBottom: var(--pf-v5-global--spacer--xl);
    --pf-v5-c-table--m-tree-view-grid__tr--expanded__tree-view-title-cell--PaddingTop: var(--pf-v5-global--spacer--xl);
    --pf-v5-c-table--m-tree-view-grid__td--data-label--GridTemplateColumns: repeat(auto-fit, minmax(150px, 1fr));
    --pf-v5-c-table--m-tree-view-grid__td--not--c-table__tree-view-title-cell--PaddingTop: var(--pf-v5-global--spacer--xs);
    --pf-v5-c-table--m-tree-view-grid__td--not--c-table__tree-view-title-cell--PaddingBottom: var(--pf-v5-global--spacer--xs);
    --pf-v5-c-table--m-tree-view-mobile__tree-view-main--c-table__check--MarginRight: 0;
    --pf-v5-c-table--m-tree-view-mobile__tree-view-main--c-table__check--Order: 4;
    --pf-v5-c-table__tree-view-text--PaddingRight: var(--pf-v5-global--spacer--sm);
    --pf-v5-c-table__tbody--cell--PaddingTop: var(--pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingTop);
    --pf-v5-c-table__tbody--cell--PaddingBottom: var(--pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingBottom);
    --pf-v5-c-table__tree-view-details-toggle--MarginTop: calc(0.375rem * -1);
    --pf-v5-c-table__tree-view-details-toggle--MarginBottom: calc(0.375rem * -1);
    --pf-v5-c-table--m-tree-view-grid--c-dropdown--MarginTop: calc(0.375rem * -1);
    --pf-v5-c-table--m-tree-view-grid--c-dropdown--MarginBottom: calc(0.375rem * -1);
  }
  .pf-m-tree-view-grid-2xl.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody) tr:where(.pf-v5-c-table__tr) {
    position: relative;
    border: none;
    outline-offset: var(--pf-v5-c-table--m-tree-view-grid--tr--OutlineOffset);
  }
  .pf-m-tree-view-grid-2xl.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody) tr:where(.pf-v5-c-table__tr)::before {
    position: absolute;
    inset-block-start: 0;
    inset-block-end: 0;
    inset-inline-start: var(--pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft);
    inset-inline-end: 0;
    content: "";
    border-block-end: var(--pf-v5-c-table--border-width--base) solid var(--pf-v5-c-table--BorderColor);
  }
  .pf-m-tree-view-grid-2xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr):not([hidden]) {
    display: grid;
    grid-template-columns: 1fr max-content;
  }
  .pf-m-tree-view-grid-2xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-expanded] .pf-v5-c-table__tree-view-title-cell {
    --pf-v5-c-table--cell--PaddingTop: var(--pf-v5-c-table--m-tree-view-grid__tr--expanded__tree-view-title-cell--PaddingTop);
  }
  .pf-m-tree-view-grid-2xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-expanded] .pf-v5-c-table__tree-view-title-cell ~ .pf-v5-c-table__action {
    --pf-v5-c-table--cell--PaddingTop: var(--pf-v5-c-table--m-tree-view-grid__tr--expanded__tree-view-title-cell--action--PaddingTop);
  }
  .pf-m-tree-view-grid-2xl.pf-v5-c-table td:where(.pf-v5-c-table__td):not(.pf-v5-c-table__tree-view-title-cell) {
    --pf-v5-c-table--cell--PaddingTop: var(--pf-v5-c-table--m-tree-view-grid__td--not--c-table__tree-view-title-cell--PaddingTop);
    --pf-v5-c-table--cell--PaddingBottom: var(--pf-v5-c-table--m-tree-view-grid__td--not--c-table__tree-view-title-cell--PaddingBottom);
    padding-inline-start: var(--pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft);
  }
  .pf-m-tree-view-grid-2xl.pf-v5-c-table .pf-v5-c-table__tree-view-text {
    padding-inline-end: var(--pf-v5-c-table__tree-view-text--PaddingRight);
  }
  .pf-m-tree-view-grid-2xl.pf-v5-c-table thead:where(.pf-v5-c-table__thead) th:where(.pf-v5-c-table__th) {
    display: none;
  }
  .pf-m-tree-view-grid-2xl.pf-v5-c-table td:where(.pf-v5-c-table__td) {
    display: none;
  }
  .pf-m-tree-view-grid-2xl.pf-v5-c-table td:where(.pf-v5-c-table__td)[data-label] {
    grid-template-columns: var(--pf-v5-c-table--m-tree-view-grid__td--data-label--GridTemplateColumns);
    grid-column: 1;
    grid-column-gap: var(--pf-v5-c-table--m-tree-view-grid__tbody--cell--GridColumnGap);
    align-items: start;
  }
  .pf-m-tree-view-grid-2xl.pf-v5-c-table td:where(.pf-v5-c-table__td)[data-label] > * {
    grid-column: 2;
  }
  .pf-m-tree-view-grid-2xl.pf-v5-c-table td:where(.pf-v5-c-table__td)[data-label]::before {
    font-weight: var(--pf-v5-global--FontWeight--bold);
    text-align: start;
    content: attr(data-label);
  }
  .pf-m-tree-view-grid-2xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr).pf-m-tree-view-details-expanded {
    padding-block-end: var(--pf-v5-c-table--m-tree-view-grid--m-tree-view-details-expanded--PaddingBottom);
  }
  .pf-m-tree-view-grid-2xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr).pf-m-tree-view-details-expanded td:where(.pf-v5-c-table__td)[data-label] {
    display: grid;
  }
  .pf-m-tree-view-grid-2xl.pf-v5-c-table .pf-v5-c-table__tree-view-title-header-cell {
    display: block;
  }
  .pf-m-tree-view-grid-2xl.pf-v5-c-table .pf-v5-c-table__tree-view-details-toggle,
  .pf-m-tree-view-grid-2xl.pf-v5-c-table .pf-v5-c-table__action {
    display: inline-block;
  }
  .pf-m-tree-view-grid-2xl.pf-v5-c-table .pf-v5-c-table__action {
    --pf-v5-c-table--cell--Width: auto;
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: var(--pf-v5-c-table--m-tree-view-grid--c-table__action--PaddingLeft);
    --pf-v5-c-table--m-tree-view-grid__td--not--c-table__tree-view-title-cell--PaddingTop: var(--pf-v5-c-table--m-tree-view-grid--c-table__action--PaddingTop);
    --pf-v5-c-table--m-tree-view-grid__td--not--c-table__tree-view-title-cell--PaddingBottom: var(--pf-v5-c-table--m-tree-view-grid--c-table__action--PaddingBottom);
    grid-row: 1;
    grid-column: 2;
  }
  .pf-m-tree-view-grid-2xl.pf-v5-c-table .pf-v5-c-table__action > .pf-v5-c-dropdown {
    margin-block-start: var(--pf-v5-c-table--m-tree-view-grid--c-dropdown--MarginTop);
    margin-block-end: var(--pf-v5-c-table--m-tree-view-grid--c-dropdown--MarginBottom);
  }
  .pf-m-tree-view-grid-2xl.pf-v5-c-table .pf-v5-c-table__tree-view-main > .pf-v5-c-table__check {
    order: var(--pf-v5-c-table--m-tree-view-mobile__tree-view-main--c-table__check--Order);
    margin-inline-start: auto;
    margin-inline-end: var(--pf-v5-c-table--m-tree-view-mobile__tree-view-main--c-table__check--MarginRight);
  }
  .pf-m-tree-view-grid-2xl.pf-v5-c-table .pf-v5-c-table__tree-view-details-toggle {
    margin-block-start: var(--pf-v5-c-table__tree-view-details-toggle--MarginTop);
    margin-block-end: var(--pf-v5-c-table__tree-view-details-toggle--MarginBottom);
  }
  .pf-m-tree-view-grid-2xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="2"] {
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 1 + var(--pf-v5-c-table__tree-view-main--indent--base));
  }
  .pf-m-tree-view-grid-2xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="3"] {
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 2 + var(--pf-v5-c-table__tree-view-main--indent--base));
  }
  .pf-m-tree-view-grid-2xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="4"] {
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 3 + var(--pf-v5-c-table__tree-view-main--indent--base));
  }
  .pf-m-tree-view-grid-2xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="5"] {
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 4 + var(--pf-v5-c-table__tree-view-main--indent--base));
  }
  .pf-m-tree-view-grid-2xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="6"] {
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 5 + var(--pf-v5-c-table__tree-view-main--indent--base));
  }
  .pf-m-tree-view-grid-2xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="7"] {
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 6 + var(--pf-v5-c-table__tree-view-main--indent--base));
  }
  .pf-m-tree-view-grid-2xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="8"] {
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 7 + var(--pf-v5-c-table__tree-view-main--indent--base));
  }
  .pf-m-tree-view-grid-2xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="9"] {
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 8 + var(--pf-v5-c-table__tree-view-main--indent--base));
  }
  .pf-m-tree-view-grid-2xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="10"] {
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 9 + var(--pf-v5-c-table__tree-view-main--indent--base));
  }
}