.pf-v5-l-split {
  --pf-v5-l-stack--m-gutter--Gap: var(--pf-v5-global--gutter);
  display: flex;
  padding: 0;
  margin: 0;
}
.pf-v5-l-split.pf-m-wrap {
  flex-wrap: wrap;
}

.pf-v5-l-split__item.pf-m-fill {
  flex-grow: 1;
}

.pf-v5-l-split.pf-m-gutter {
  gap: var(--pf-v5-l-stack--m-gutter--Gap);
}